import { WHISPER_CONFIG, API_ENDPOINTS } from './config';
import type { TranscriptionResult } from './types';

export class TranscriptionService {
  constructor(private apiKey: string) {}

  async transcribeAudio(audioBlob: Blob): Promise<TranscriptionResult> {
    try {
      const formData = new FormData();
      formData.append('file', audioBlob, 'audio.webm');
      formData.append('model', WHISPER_CONFIG.model);
      formData.append('language', WHISPER_CONFIG.language);

      const response = await fetch(API_ENDPOINTS.transcription, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.apiKey}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error('Failed to transcribe audio');
      }

      const data = await response.json();
      return {
        text: data.text,
        language: data.language
      };
    } catch (error) {
      console.error('Transcription error:', error);
      throw error;
    }
  }
}