import React from 'react';
import { X } from 'lucide-react';
import Button from '../Button';
import type { MotivationalVideo } from '../../types/base';

interface VideoPlayerProps {
  video: MotivationalVideo;
  onClose: () => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video, onClose }) => {
  return (
    <div className="relative">
      <Button
        variant="secondary"
        onClick={onClose}
        className="absolute top-4 right-4 z-10"
      >
        <X size={24} />
      </Button>

      <div className="aspect-video rounded-lg overflow-hidden bg-black">
        {video.embedCode ? (
          <div dangerouslySetInnerHTML={{ __html: video.embedCode }} />
        ) : (
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${getVideoId(video.url)}?autoplay=1&cc_load_policy=1&rel=0&modestbranding=1&playsinline=1&enablejsapi=1`}
            className="w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>

      <div className="mt-4 space-y-2 text-white">
        <h2 className="text-xl font-semibold">{video.title}</h2>
        {video.description && (
          <p className="text-white/80">{video.description}</p>
        )}
      </div>
    </div>
  );
};

const getVideoId = (url: string): string => {
  try {
    const urlObj = new URL(url);
    if (urlObj.pathname.startsWith('/shorts/')) {
      return urlObj.pathname.split('/shorts/')[1]?.split('?')[0] || '';
    }
    if (urlObj.hostname === 'youtu.be') {
      return urlObj.pathname.slice(1);
    }
    return urlObj.searchParams.get('v') || '';
  } catch {
    return '';
  }
};

export default VideoPlayer;