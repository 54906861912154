import React, { useState } from 'react';
import { X, Calendar } from 'lucide-react';
import { format } from 'date-fns';
import Button from '../Button';
import type { MotivationalVideo, VideoSchedule } from '../../types/base';

interface VideoSchedulerProps {
  video: MotivationalVideo;
  onClose: () => void;
}

const VideoScheduler: React.FC<VideoSchedulerProps> = ({ video, onClose }) => {
  const [schedule, setSchedule] = useState<Partial<VideoSchedule>>({
    videoId: video.id,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    repeat: 'daily'
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Dispatch action to update video schedule
    // This would be handled by your state management system
    onClose();
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Calendar size={24} className="text-primary" />
          <h2 className="text-lg font-semibold text-text">Schedule Video</h2>
        </div>
        <button onClick={onClose} className="text-text-muted hover:text-text">
          <X size={24} />
        </button>
      </div>

      <div className="card p-4">
        <h3 className="font-medium text-text">{video.title}</h3>
        <p className="text-sm text-text-muted">{video.description}</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="form-label">Start Date</label>
          <input
            type="date"
            value={schedule.startDate}
            onChange={(e) => setSchedule(prev => ({ ...prev, startDate: e.target.value }))}
            className="input-field"
            required
          />
        </div>

        <div>
          <label className="form-label">Repeat</label>
          <select
            value={schedule.repeat}
            onChange={(e) => setSchedule(prev => ({ ...prev, repeat: e.target.value as VideoSchedule['repeat'] }))}
            className="input-field"
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>

        {schedule.repeat === 'weekly' && (
          <div>
            <label className="form-label">Days of Week</label>
            <div className="grid grid-cols-7 gap-2">
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                <label
                  key={day}
                  className="flex flex-col items-center"
                >
                  <input
                    type="checkbox"
                    checked={schedule.daysOfWeek?.includes(index)}
                    onChange={(e) => {
                      const days = schedule.daysOfWeek || [];
                      setSchedule(prev => ({
                        ...prev,
                        daysOfWeek: e.target.checked
                          ? [...days, index]
                          : days.filter(d => d !== index)
                      }));
                    }}
                    className="sr-only"
                  />
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center cursor-pointer transition-colors ${
                    schedule.daysOfWeek?.includes(index)
                      ? 'bg-primary text-white'
                      : 'bg-secondary text-text-muted hover:bg-primary/10'
                  }`}>
                    {day[0]}
                  </div>
                </label>
              ))}
            </div>
          </div>
        )}

        <div>
          <label className="form-label">End Date (Optional)</label>
          <input
            type="date"
            value={schedule.endDate || ''}
            onChange={(e) => setSchedule(prev => ({ ...prev, endDate: e.target.value }))}
            className="input-field"
            min={schedule.startDate}
          />
        </div>

        <div className="flex justify-end gap-2 pt-4">
          <Button
            variant="secondary"
            onClick={onClose}
            type="button"
          >
            Cancel
          </Button>
          <Button
            variant="accent"
            type="submit"
          >
            Schedule Video
          </Button>
        </div>
      </form>
    </div>
  );
};

export default VideoScheduler;