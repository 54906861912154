import type { EmailConfig } from '../../types/email';

export class MailgunClient {
  private readonly MAILGUN_API_BASE = 'https://api.mailgun.net/v3';
  private config: EmailConfig;

  constructor(config: EmailConfig) {
    this.config = config;
  }

  private getAuthHeader(): string {
    try {
      const auth = btoa(`api:${this.config.apiKey}`);
      return `Basic ${auth}`;
    } catch (error) {
      throw new Error('Invalid API key format');
    }
  }

  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      let errorMessage = 'Request failed';
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorData.error || errorMessage;
      } catch {
        errorMessage = response.statusText;
      }
      throw new Error(errorMessage);
    }
    return response.json();
  }

  async testConnection(): Promise<boolean> {
    try {
      // First verify API key format
      if (!this.config.apiKey.startsWith('key-')) {
        throw new Error('Invalid API key format - must start with "key-"');
      }

      // Then verify domain
      const response = await fetch(`${this.MAILGUN_API_BASE}/domains/${this.config.domain}/verify`, {
        method: 'PUT',
        headers: {
          'Authorization': this.getAuthHeader(),
          'Content-Type': 'application/json'
        }
      });

      const result = await this.handleResponse(response);
      if (!result.domain || !result.domain.state === 'active') {
        throw new Error('Domain not verified or inactive');
      }

      return true;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Domain verification failed: ${error.message}`);
      }
      throw new Error('Domain verification failed');
    }
  }

  async sendEmail(to: string, subject: string, html: string): Promise<string> {
    try {
      const formData = new FormData();
      formData.append('from', `${this.config.fromName} <${this.config.fromEmail}>`);
      formData.append('to', to);
      formData.append('subject', subject);
      formData.append('html', html);

      const response = await fetch(`${this.MAILGUN_API_BASE}/${this.config.domain}/messages`, {
        method: 'POST',
        headers: {
          'Authorization': this.getAuthHeader()
        },
        body: formData
      });

      const result = await this.handleResponse(response);
      if (!result.id) {
        throw new Error('No message ID returned');
      }

      return result.id;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to send email: ${error.message}`);
      }
      throw new Error('Failed to send email');
    }
  }

  async getMessageStatus(messageId: string): Promise<{
    status: string;
    deliveredAt?: string;
  }> {
    try {
      const response = await fetch(
        `${this.MAILGUN_API_BASE}/${this.config.domain}/events/${messageId}`,
        {
          headers: {
            'Authorization': this.getAuthHeader(),
            'Content-Type': 'application/json'
          }
        }
      );

      const result = await this.handleResponse(response);
      return {
        status: result.event || 'pending',
        deliveredAt: result.timestamp ? new Date(result.timestamp * 1000).toISOString() : undefined
      };
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to get message status: ${error.message}`);
      }
      throw new Error('Failed to get message status');
    }
  }
}