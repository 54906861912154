import React from 'react';
import { CheckCircle, XCircle, Clock } from 'lucide-react';

interface StatusIndicatorProps {
  status: 'pending' | 'sent' | 'delivered' | 'failed';
  size?: number;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, size = 16 }) => {
  switch (status) {
    case 'delivered':
    case 'sent':
      return <CheckCircle size={size} className="text-green-500" />;
    case 'failed':
      return <XCircle size={size} className="text-red-500" />;
    default:
      return <Clock size={size} className="text-yellow-500" />;
  }
};

export default StatusIndicator;