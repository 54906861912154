import React, { useState } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import Button from '../Button';
import { formatBytes } from '../../utils/formatters';

interface FileUploaderProps {
  onUpload: (file: File) => void;
  maxSize?: number;
  accept?: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onUpload,
  maxSize = 10 * 1024 * 1024, // 10MB default
  accept = '.pdf,.doc,.docx,.txt'
}) => {
  const [error, setError] = useState<string>('');
  const [isDragging, setIsDragging] = useState(false);

  const validateFile = (file: File): boolean => {
    setError('');

    if (file.size > maxSize) {
      setError(`File size must be less than ${formatBytes(maxSize)}`);
      return false;
    }

    const ext = file.name.split('.').pop()?.toLowerCase();
    const validExts = accept.split(',').map(x => x.replace('.', ''));
    if (!ext || !validExts.includes(ext)) {
      setError('Invalid file type');
      return false;
    }

    return true;
  };

  const handleFile = (file: File) => {
    if (validateFile(file)) {
      onUpload(file);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) handleFile(file);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  return (
    <div className="space-y-4">
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        className={`border-2 border-dashed rounded-lg p-6 text-center transition-colors ${
          isDragging ? 'border-primary bg-primary/5' : 'border-border'
        }`}
      >
        <Upload className="mx-auto h-12 w-12 text-text-muted" />
        <p className="mt-2 text-sm text-text-muted">
          Drag and drop a file, or click to browse
        </p>
        <input
          type="file"
          onChange={(e) => e.target.files?.[0] && handleFile(e.target.files[0])}
          accept={accept}
          className="hidden"
          id="file-upload"
        />
        <Button
          variant="secondary"
          onClick={() => document.getElementById('file-upload')?.click()}
          className="mt-4"
        >
          Select File
        </Button>
      </div>

      {error && (
        <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
          <AlertCircle size={16} />
          <p className="text-sm">{error}</p>
        </div>
      )}

      <p className="text-sm text-text-muted">
        Maximum file size: {formatBytes(maxSize)}
      </p>
    </div>
  );
};

export default FileUploader;