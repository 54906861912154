import { secureStorage } from '../utils/secureStorage';
import { templates } from './emailService/templates';
import type { EmailTemplate } from '../types/email';

const EMAIL_SETTINGS_KEY = 'email_settings';
const EMAIL_LOGS_KEY = 'email_logs';

interface EmailSettings {
  apiKey: string;
  domain: string;
  fromEmail: string;
  fromName: string;
}

interface EmailLog {
  id: string;
  to: string;
  subject: string;
  template?: EmailTemplate;
  status: 'pending' | 'sent' | 'failed';
  error?: string;
  timestamp: string;
}

export const emailService = {
  getSettings: (): EmailSettings | null => {
    try {
      const settings = secureStorage.getItem(EMAIL_SETTINGS_KEY);
      return settings ? JSON.parse(settings) : null;
    } catch (error) {
      console.error('Failed to get email settings:', error);
      return null;
    }
  },

  saveSettings: async (settings: EmailSettings): Promise<void> => {
    try {
      // Validate settings
      if (!settings.apiKey || !settings.domain || !settings.fromEmail || !settings.fromName) {
        throw new Error('All fields are required');
      }

      if (!settings.apiKey.startsWith('key-')) {
        throw new Error('Invalid API key format. Must start with "key-"');
      }

      const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
      if (!domainRegex.test(settings.domain)) {
        throw new Error('Invalid domain format');
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(settings.fromEmail)) {
        throw new Error('Invalid email format');
      }

      // Save settings
      secureStorage.setItem(EMAIL_SETTINGS_KEY, JSON.stringify(settings));
    } catch (error) {
      console.error('Failed to save email settings:', error);
      throw error;
    }
  },

  sendTestEmail: async (toEmail: string, template: EmailTemplate = 'welcomeEmail'): Promise<void> => {
    try {
      const settings = emailService.getSettings();
      if (!settings) {
        throw new Error('Email settings not configured');
      }

      // Get template content
      const mockUser = {
        id: 'test',
        name: 'Test User',
        email: toEmail,
        role: 'mlo',
        status: 'active',
        createdAt: new Date().toISOString()
      };

      const emailContent = templates[template](mockUser);

      // Log the attempt
      const log: EmailLog = {
        id: Date.now().toString(),
        to: toEmail,
        subject: emailContent.subject,
        template,
        status: 'pending',
        timestamp: new Date().toISOString()
      };

      try {
        // In development, simulate email sending
        if (process.env.NODE_ENV === 'development') {
          await new Promise(resolve => setTimeout(resolve, 1000));
          console.log('Test email sent (development mode):', {
            to: toEmail,
            from: `${settings.fromName} <${settings.fromEmail}>`,
            subject: emailContent.subject,
            html: emailContent.body
          });
          log.status = 'sent';
        } else {
          // In production, implement actual email sending logic here
          throw new Error('Email sending not implemented in production');
        }
      } catch (error) {
        log.status = 'failed';
        log.error = error instanceof Error ? error.message : 'Failed to send email';
        throw error;
      } finally {
        // Save the log
        const logs = JSON.parse(secureStorage.getItem(EMAIL_LOGS_KEY) || '[]');
        logs.unshift(log);
        secureStorage.setItem(EMAIL_LOGS_KEY, JSON.stringify(logs.slice(0, 100)));
      }
    } catch (error) {
      console.error('Failed to send test email:', error);
      throw error;
    }
  },

  getLogs: (): EmailLog[] => {
    try {
      return JSON.parse(secureStorage.getItem(EMAIL_LOGS_KEY) || '[]');
    } catch (error) {
      console.error('Failed to get email logs:', error);
      return [];
    }
  },

  clearLogs: (): void => {
    secureStorage.removeItem(EMAIL_LOGS_KEY);
  }
};