import React from 'react';
import { BarChart, Activity, Users, Mail } from 'lucide-react';
import { formatNumber } from '../../utils/formatters';
import SectionHeader from './components/SectionHeader';
import ImportantNotes from './components/ImportantNotes';

interface StatCardProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
  bgColor?: string;
  iconColor?: string;
}

const StatCard: React.FC<StatCardProps> = ({
  icon,
  label,
  value,
  bgColor = 'bg-primary/10',
  iconColor = 'text-primary'
}) => (
  <div className="card p-4">
    <div className="flex items-center gap-3">
      <div className={`p-2 ${bgColor} rounded-lg`}>
        <div className={`w-6 h-6 ${iconColor}`}>{icon}</div>
      </div>
      <div>
        <p className="text-sm text-text-muted">{label}</p>
        <p className="text-xl font-semibold text-text">{value}</p>
      </div>
    </div>
  </div>
);

const EmailAnalytics: React.FC = () => {
  const stats = {
    sent: 15234,
    delivered: 15100,
    opened: 7890,
    clicked: 3456,
    bounced: 134,
    complaints: 12
  };

  const deliveryRate = ((stats.delivered / stats.sent) * 100).toFixed(1);
  const openRate = ((stats.opened / stats.delivered) * 100).toFixed(1);
  const clickRate = ((stats.clicked / stats.opened) * 100).toFixed(1);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard
          icon={<Mail />}
          label="Total Sent"
          value={formatNumber(stats.sent)}
        />
        <StatCard
          icon={<Activity />}
          label="Delivery Rate"
          value={`${deliveryRate}%`}
          bgColor="bg-green-100"
          iconColor="text-green-600"
        />
        <StatCard
          icon={<Users />}
          label="Open Rate"
          value={`${openRate}%`}
          bgColor="bg-blue-100"
          iconColor="text-blue-600"
        />
        <StatCard
          icon={<BarChart />}
          label="Click Rate"
          value={`${clickRate}%`}
          bgColor="bg-purple-100"
          iconColor="text-purple-600"
        />
      </div>

      <div className="card">
        <SectionHeader
          icon={BarChart}
          title="Detailed Statistics"
        />
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-text-muted">Total Sent</p>
            <p className="text-xl font-semibold text-text">{formatNumber(stats.sent)}</p>
          </div>
          <div>
            <p className="text-sm text-text-muted">Delivered</p>
            <p className="text-xl font-semibold text-text">{formatNumber(stats.delivered)}</p>
          </div>
          <div>
            <p className="text-sm text-text-muted">Opened</p>
            <p className="text-xl font-semibold text-text">{formatNumber(stats.opened)}</p>
          </div>
          <div>
            <p className="text-sm text-text-muted">Clicked</p>
            <p className="text-xl font-semibold text-text">{formatNumber(stats.clicked)}</p>
          </div>
          <div>
            <p className="text-sm text-text-muted">Bounced</p>
            <p className="text-xl font-semibold text-text">{formatNumber(stats.bounced)}</p>
          </div>
          <div>
            <p className="text-sm text-text-muted">Complaints</p>
            <p className="text-xl font-semibold text-text">{formatNumber(stats.complaints)}</p>
          </div>
        </div>
      </div>

      <ImportantNotes
        notes={[
          'Statistics are updated in real-time',
          'Delivery rates include both soft and hard bounces',
          'Open rates may be affected by privacy settings',
          'Click tracking requires properly configured links'
        ]}
      />
    </div>
  );
};

export default EmailAnalytics;