import { Task, UserRole } from '../types/base';

export const BUSINESS_TASKS: Task[] = [
  {
    id: 16,
    name: 'Dials',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Prospecting',
    type: 'business'
  },
  {
    id: 26,
    name: 'Leads',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Prospecting',
    type: 'business'
  },
  {
    id: 25,
    name: 'Conversations',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Prospecting',
    type: 'business'
  },
  {
    id: 17,
    name: 'Ask for Referral',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Prospecting',
    type: 'business'
  },
  {
    id: 8,
    name: 'Appointments Set',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Appointments',
    type: 'business'
  },
  {
    id: 7,
    name: 'Appointments Held',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Appointments',
    type: 'business'
  },
  {
    id: 6,
    name: 'Agreements/Contracts',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Transactions',
    type: 'business'
  },
  {
    id: 11,
    name: 'Closings',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Transactions',
    type: 'business'
  },
  {
    id: 1,
    name: '9AM Call',
    points: 1,
    max: 1,
    role: ['mlo', 'dual'],
    category: 'Daily Routine',
    type: 'business',
    timeOfDay: '9:00'
  },
  {
    id: 2,
    name: 'Team Repost/Social Media Post',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Marketing',
    type: 'business'
  },
  {
    id: 3,
    name: 'Past Client Touch',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Client Relations',
    type: 'business'
  },
  {
    id: 4,
    name: 'Open House',
    points: 1,
    max: Infinity,
    role: ['realtor', 'dual'],
    category: 'Showings',
    type: 'business'
  },
  {
    id: 5,
    name: 'Mega Open House',
    points: 1,
    max: Infinity,
    role: ['realtor', 'dual'],
    category: 'Showings',
    type: 'business'
  },
  {
    id: 12,
    name: 'Pop By',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Client Relations',
    type: 'business'
  },
  {
    id: 13,
    name: 'Video to Client',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Client Relations',
    type: 'business'
  },
  {
    id: 14,
    name: 'Get google/FB/Yelp Review',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Marketing',
    type: 'business'
  },
  {
    id: 15,
    name: 'Pipeline or Team Leader Check-In',
    points: 1,
    max: 1,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Team',
    type: 'business'
  },
  {
    id: 18,
    name: '15 Min Role Play w/ Partner',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Development',
    type: 'business'
  },
  {
    id: 19,
    name: 'Role Play Extra',
    points: 1,
    max: Infinity,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Development',
    type: 'business'
  },
  {
    id: 20,
    name: 'Categorize Finances',
    points: 1,
    max: 1,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Admin',
    type: 'business'
  },
  {
    id: 21,
    name: 'Zero Emails in Inbox',
    points: 1,
    max: 1,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Admin',
    type: 'business'
  }
];

export const WELLNESS_TASKS: Task[] = [
  {
    id: 22,
    name: 'Read (30 min minimum)',
    points: 1,
    max: 1,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Wellness',
    type: 'wellness'
  },
  {
    id: 23,
    name: 'Exercise (30 min minimum)',
    points: 1,
    max: 1,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Wellness',
    type: 'wellness'
  },
  {
    id: 24,
    name: 'Meditate (10 min minimum)',
    points: 1,
    max: 1,
    role: ['mlo', 'realtor', 'dual'],
    category: 'Wellness',
    type: 'wellness'
  }
];

export const ALL_TASKS = [...BUSINESS_TASKS, ...WELLNESS_TASKS];

export const PRIMARY_KPI_IDS = [16, 26, 25, 8, 7, 6, 11];

export const WORK_TASKS = {
  prospecting: {
    name: "Prospecting",
    tasks: BUSINESS_TASKS.filter(task => task.category === 'Prospecting')
  },
  appointments: {
    name: "Appointments",
    tasks: BUSINESS_TASKS.filter(task => task.category === 'Appointments')
  },
  transactions: {
    name: "Transactions",
    tasks: BUSINESS_TASKS.filter(task => task.category === 'Transactions')
  }
};