import React, { useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import ColorPalette from './ColorPalette';
import CustomPalette from './CustomPalette';
import LayoutSelector from './LayoutSelector';
import Button from './Button';

const ThemeEditor: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'palettes' | 'custom' | 'layout'>('palettes');

  return (
    <div className="space-y-6">
      <div className="flex gap-2">
        <Button
          variant={activeTab === 'palettes' ? 'accent' : 'secondary'}
          onClick={() => setActiveTab('palettes')}
        >
          Palettes
        </Button>
        <Button
          variant={activeTab === 'custom' ? 'accent' : 'secondary'}
          onClick={() => setActiveTab('custom')}
        >
          Custom
        </Button>
        <Button
          variant={activeTab === 'layout' ? 'accent' : 'secondary'}
          onClick={() => setActiveTab('layout')}
        >
          Layout
        </Button>
      </div>

      {activeTab === 'palettes' && <ColorPalette />}
      {activeTab === 'custom' && <CustomPalette />}
      {activeTab === 'layout' && <LayoutSelector />}
    </div>
  );
};

export default ThemeEditor;