import React from 'react';

interface ImportantNotesProps {
  notes: string[];
}

const ImportantNotes: React.FC<ImportantNotesProps> = ({ notes }) => {
  return (
    <div className="card bg-secondary/50 p-4">
      <h3 className="font-medium text-text mb-2">Important Notes:</h3>
      <ul className="list-disc list-inside space-y-1 text-sm text-text-muted">
        {notes.map((note, index) => (
          <li key={index}>{note}</li>
        ))}
      </ul>
    </div>
  );
};

export default ImportantNotes;