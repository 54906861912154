import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Calendar } from 'lucide-react';
import { useApp } from '../context/AppContext';
import { format } from 'date-fns';
import Button from '../components/Button';

const JournalEntries: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useApp();
  const entries = state.profile?.journalEntries || [];

  return (
    <div className="min-h-screen bg-background pb-24">
      <header className="page-header">
        <div className="flex items-center gap-2">
          <Calendar size={24} className="text-text" />
          <div>
            <h1 className="text-xl font-bold text-text">Journal Entries</h1>
            <p className="text-sm text-text-muted">
              {entries.length} {entries.length === 1 ? 'entry' : 'entries'}
            </p>
          </div>
        </div>
        <Button
          variant="accent"
          onClick={() => navigate('/journal')}
        >
          <Plus size={18} />
          New Entry
        </Button>
      </header>

      <div className="container-content space-y-4">
        {entries.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-text-muted">No journal entries yet.</p>
            <p className="text-text-muted">Start your journaling journey today!</p>
          </div>
        ) : (
          entries.map((entry) => (
            <div key={entry.id} className="card space-y-4">
              <div className="flex justify-between items-start">
                <div className="flex items-center gap-3">
                  <div className="text-2xl">{entry.mood}</div>
                  <div>
                    <h3 className="font-medium text-text">
                      {format(new Date(entry.date), 'EEEE, MMMM d')}
                    </h3>
                    <p className="text-sm text-text-muted">
                      {format(new Date(entry.date), 'h:mm a')}
                    </p>
                  </div>
                </div>
              </div>

              {entry.gratitude && (
                <div className="space-y-1">
                  <h4 className="text-sm font-medium text-text">Gratitude</h4>
                  <p className="text-text-muted bg-secondary/50 dark:bg-background p-3 rounded-lg">
                    {entry.gratitude}
                  </p>
                </div>
              )}

              {entry.wins && (
                <div className="space-y-1">
                  <h4 className="text-sm font-medium text-text">Wins</h4>
                  <p className="text-text-muted bg-secondary/50 dark:bg-background p-3 rounded-lg">
                    {entry.wins}
                  </p>
                </div>
              )}

              {entry.focus && (
                <div className="space-y-1">
                  <h4 className="text-sm font-medium text-text">Focus</h4>
                  <p className="text-text-muted bg-secondary/50 dark:bg-background p-3 rounded-lg">
                    {entry.focus}
                  </p>
                </div>
              )}

              {entry.affirmations && (
                <div className="space-y-1">
                  <h4 className="text-sm font-medium text-text">Affirmations</h4>
                  <p className="text-text-muted bg-secondary/50 dark:bg-background p-3 rounded-lg">
                    {entry.affirmations}
                  </p>
                </div>
              )}

              {entry.analysis && (
                <div className="mt-4 p-4 bg-primary/5 rounded-lg">
                  <h4 className="font-medium text-text mb-2">AI Insights</h4>
                  {entry.analysis.patterns && entry.analysis.patterns.length > 0 && (
                    <div className="mb-2">
                      <p className="text-sm font-medium text-text">Patterns</p>
                      <ul className="list-disc list-inside text-sm text-text-muted">
                        {entry.analysis.patterns.map((pattern, index) => (
                          <li key={index}>{pattern}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {entry.analysis.suggestions && entry.analysis.suggestions.length > 0 && (
                    <div>
                      <p className="text-sm font-medium text-text">Suggestions</p>
                      <ul className="list-disc list-inside text-sm text-text-muted">
                        {entry.analysis.suggestions.map((suggestion, index) => (
                          <li key={index}>{suggestion}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        )}
      </div>

      <div className="fixed bottom-20 right-4">
        <Button
          variant="accent"
          size="lg"
          onClick={() => navigate('/journal')}
          className="shadow-lg"
        >
          <Plus size={24} />
          New Entry
        </Button>
      </div>
    </div>
  );
};

export default JournalEntries;