import { AppState } from '../context/AppContext';
import { secureStorage } from './secureStorage';

const STATE_KEY = 'topProducerAppState';
const STATE_VERSION = '1.0.0';

interface PersistedState {
  version: string;
  timestamp: string;
  state: Partial<AppState>;
}

export const saveState = (state: AppState) => {
  try {
    // Only persist necessary parts of the state
    const stateToSave: PersistedState = {
      version: STATE_VERSION,
      timestamp: new Date().toISOString(),
      state: {
        profile: state.profile,
        stats: state.stats,
        auth: state.auth,
        tasks: state.tasks,
        goals: state.goals,
        events: state.events,
        activityLogs: state.activityLogs
      }
    };

    secureStorage.setItem(STATE_KEY, JSON.stringify(stateToSave));
  } catch (err) {
    console.error('Error saving state:', err);
  }
};

export const loadState = (): Partial<AppState> | undefined => {
  try {
    const savedState = secureStorage.getItem(STATE_KEY);
    if (!savedState) return undefined;

    const { state, version } = JSON.parse(savedState) as PersistedState;

    // Version check for future migrations
    if (version !== STATE_VERSION) {
      console.warn('State version mismatch, might need migration');
    }

    return state;
  } catch (err) {
    console.error('Error loading state:', err);
    return undefined;
  }
};

export const clearState = () => {
  try {
    secureStorage.removeItem(STATE_KEY);
  } catch (err) {
    console.error('Error clearing state:', err);
  }
};