import { User } from '../../types/base';
import { EmailTemplate } from '../../types/email';

interface TemplateResult {
  subject: string;
  body: string;
}

type TemplateFunction = (user: User) => TemplateResult;

const templates: Record<EmailTemplate, TemplateFunction> = {
  welcomeEmail: (user) => ({
    subject: 'Welcome to Top Producer App',
    body: `
      <h2>Welcome to Top Producer App!</h2>
      <p>Hi ${user.name},</p>
      <p>We're excited to have you on board. Here are some quick links to get started:</p>
      <ul>
        <li><a href="${window.location.origin}/profile">Complete Your Profile</a></li>
        <li><a href="${window.location.origin}/goals">Set Your Goals</a></li>
        <li><a href="${window.location.origin}/coach">Meet Your AI Coach</a></li>
      </ul>
    `
  }),

  morningMotivation: (user) => ({
    subject: 'Your Morning Motivation',
    body: `
      <h2>Good Morning ${user.name}!</h2>
      <p>Let's start the day strong. Here are your goals for today:</p>
      <ul>
        <li>Review your daily targets</li>
        <li>Plan your calls and appointments</li>
        <li>Check market updates</li>
      </ul>
    `
  }),

  afternoonProgress: (user) => ({
    subject: 'Afternoon Progress Check',
    body: `
      <h2>Afternoon Check-in</h2>
      <p>Hi ${user.name},</p>
      <p>Here's your progress so far today:</p>
      <ul>
        <li>Tasks completed: 5</li>
        <li>Points earned: 25</li>
        <li>Remaining goals: 3</li>
      </ul>
    `
  }),

  eveningRecap: (user) => ({
    subject: 'Your Daily Recap',
    body: `
      <h2>Daily Achievement Summary</h2>
      <p>Great work today, ${user.name}!</p>
      <p>Here's what you accomplished:</p>
      <ul>
        <li>Total tasks completed: 12</li>
        <li>Total points earned: 45</li>
        <li>Goals achieved: 8/10</li>
      </ul>
    `
  }),

  loginReminder: (user) => ({
    subject: 'Don\'t Forget to Log Your Activities',
    body: `
      <h2>Activity Logging Reminder</h2>
      <p>Hi ${user.name},</p>
      <p>Don't forget to log your activities for today to keep track of your progress!</p>
      <p><a href="${window.location.origin}/tasks">Log Activities Now</a></p>
    `
  })
};

export { templates };