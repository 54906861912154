import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, BarChart3, FileText, Code, List, ArrowLeft } from 'lucide-react';
import Button from '../Button';
import EmailTemplates from './EmailTemplates';
import EmailSetup from './EmailSetup';
import EmailLogs from './EmailLogs';
import EmailAnalytics from './EmailAnalytics';
import ApiDocs from './ApiDocs';
import ErrorBoundary from '../ErrorBoundary';

const EmailDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'analytics' | 'templates' | 'setup' | 'logs' | 'api'>('templates');

  const tabs = [
    { id: 'templates', label: 'Templates', icon: FileText },
    { id: 'analytics', label: 'Analytics', icon: BarChart3 },
    { id: 'setup', label: 'Setup', icon: Mail },
    { id: 'logs', label: 'Logs', icon: List },
    { id: 'api', label: 'API', icon: Code }
  ] as const;

  return (
    <div className="min-h-screen bg-background">
      <header className="page-header">
        <button onClick={() => navigate('/admin')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="text-xl font-bold text-text">Email Service</h1>
      </header>

      <div className="container-content py-4">
        <div className="flex gap-2 mb-6 overflow-x-auto">
          {tabs.map(tab => (
            <Button
              key={tab.id}
              variant={activeTab === tab.id ? 'accent' : 'secondary'}
              onClick={() => setActiveTab(tab.id)}
              className="whitespace-nowrap"
            >
              <tab.icon size={18} />
              {tab.label}
            </Button>
          ))}
        </div>

        <ErrorBoundary>
          {activeTab === 'analytics' && <EmailAnalytics />}
          {activeTab === 'templates' && <EmailTemplates />}
          {activeTab === 'setup' && <EmailSetup />}
          {activeTab === 'logs' && <EmailLogs />}
          {activeTab === 'api' && <ApiDocs />}
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default EmailDashboard;