import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../context/AppContext';
import LoginForm from '../components/LoginForm';

const Login = () => {
  const navigate = useNavigate();
  const { state } = useApp();

  return (
    <div className="min-h-screen flex items-center justify-center bg-background dark:bg-background">
      <div className="w-full max-w-md p-8 space-y-6">
        <div className="flex flex-col items-center space-y-4">
          {state.profile?.appLogo ? (
            <img 
              src={state.profile.appLogo} 
              alt="App Logo" 
              className="h-32 object-contain"
            />
          ) : (
            <div className="w-32 h-32 bg-secondary dark:bg-background rounded-lg flex items-center justify-center">
              <span className="text-2xl font-bold text-text dark:text-text">Logo</span>
            </div>
          )}
          <h2 className="text-2xl font-bold text-center text-text dark:text-text">
            Welcome Back
          </h2>
        </div>

        <LoginForm onSuccess={() => navigate('/role-selection')} />
      </div>
    </div>
  );
};

export default Login;