import React from 'react';
import { useAuth } from './context/AuthContext';
import { useApp } from './context/AppContext';
import AppRoutes from './routes/AppRoutes';
import Navigation from './components/Navigation';
import LoadingScreen from './components/LoadingScreen';

const App = () => {
  const { isLoading } = useAuth();
  const { state } = useApp();
  const layout = state.profile?.layout || 'default';

  if (isLoading) {
    return <LoadingScreen />;
  }

  const getLayoutClasses = () => {
    switch (layout) {
      case 'sidebar':
        return 'pl-64';
      case 'compact':
        return 'pl-16';
      default:
        return '';
    }
  };

  return (
    <div className={`min-h-screen bg-background ${getLayoutClasses()}`}>
      <AppRoutes />
      <Navigation />
    </div>
  );
};

export default App;