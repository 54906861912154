import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import EmailDashboard from '../components/EmailDashboard';

const EmailDashboardPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-background">
      <header className="page-header">
        <button onClick={() => navigate('/admin')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="text-xl font-bold text-text">Email Service</h1>
      </header>

      <EmailDashboard />
    </div>
  );
};

export default EmailDashboardPage;