import React, { useState } from 'react';
import { X, Mail, Phone, Key, Shield, Building2, Globe } from 'lucide-react';
import Button from './Button';
import ProfilePicture from './ProfilePicture';
import type { User } from '../types/base';

interface TeamMemberDetailsProps {
  member: User;
  onClose: () => void;
  onUpdate: (userId: string, updates: Partial<User>) => void;
  onResetPassword: (userId: string, newPassword: string) => void;
}

const TeamMemberDetails: React.FC<TeamMemberDetailsProps> = ({
  member,
  onClose,
  onUpdate,
  onResetPassword
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [info, setInfo] = useState({
    name: member.name || '',
    email: member.email || '',
    phone: member.phone || '',
    title: member.title || '',
    role: member.role,
    status: member.status,
    nmlsId: member.nmlsId || '',
    realtorLicense: member.realtorLicense || '',
    profilePicture: member.profilePicture || '',
    company: {
      name: member.company?.name || '',
      address: member.company?.address || '',
      website: member.company?.website || ''
    }
  });

  const handleSave = () => {
    onUpdate(member.id, info);
    setIsEditing(false);
  };

  const handleProfilePictureChange = (value: string) => {
    const updates = { ...info, profilePicture: value };
    setInfo(updates);
    onUpdate(member.id, { profilePicture: value });
  };

  const handlePasswordReset = () => {
    if (newPassword.length >= 8) {
      onResetPassword(member.id, newPassword);
      setNewPassword('');
      setShowPasswordReset(false);
    }
  };

  const getRoleDisplay = (role: User['role']) => {
    switch (role) {
      case 'mlo':
        return 'MLO';
      case 'realtor':
        return 'Realtor';
      case 'dual_agent':
        return 'Dual Licensed';
      default:
        return role.replace('_', ' ');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-secondary rounded-lg w-full max-w-2xl overflow-hidden">
        <div className="p-4 border-b border-border flex justify-between items-center">
          <h2 className="text-lg font-semibold text-text">Team Member Details</h2>
          <button onClick={onClose} className="text-text-muted hover:text-text">
            <X size={24} />
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* Profile Header */}
          <div className="flex items-center gap-4">
            <ProfilePicture
              value={info.profilePicture}
              onChange={handleProfilePictureChange}
              editable={true}
              size="lg"
            />
            <div>
              <h3 className="text-xl font-bold text-text">{info.name}</h3>
              <p className="text-text-muted">{getRoleDisplay(info.role)}</p>
            </div>
            <Button
              variant="text"
              onClick={() => setIsEditing(!isEditing)}
              className="ml-auto"
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>
          </div>

          {isEditing ? (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="form-label">Full Name</label>
                  <input
                    type="text"
                    value={info.name}
                    onChange={(e) => setInfo(prev => ({ ...prev, name: e.target.value }))}
                    className="input-field"
                  />
                </div>
                <div>
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    value={info.email}
                    onChange={(e) => setInfo(prev => ({ ...prev, email: e.target.value }))}
                    className="input-field"
                  />
                </div>
                <div>
                  <label className="form-label">Phone</label>
                  <input
                    type="tel"
                    value={info.phone}
                    onChange={(e) => setInfo(prev => ({ ...prev, phone: e.target.value }))}
                    className="input-field"
                  />
                </div>
                <div>
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    value={info.title}
                    onChange={(e) => setInfo(prev => ({ ...prev, title: e.target.value }))}
                    className="input-field"
                  />
                </div>
              </div>

              <div>
                <label className="form-label">Company Name</label>
                <input
                  type="text"
                  value={info.company.name}
                  onChange={(e) => setInfo(prev => ({
                    ...prev,
                    company: { ...prev.company, name: e.target.value }
                  }))}
                  className="input-field"
                />
              </div>

              <div>
                <label className="form-label">Company Address</label>
                <input
                  type="text"
                  value={info.company.address}
                  onChange={(e) => setInfo(prev => ({
                    ...prev,
                    company: { ...prev.company, address: e.target.value }
                  }))}
                  className="input-field"
                />
              </div>

              <div>
                <label className="form-label">Company Website</label>
                <input
                  type="url"
                  value={info.company.website}
                  onChange={(e) => setInfo(prev => ({
                    ...prev,
                    company: { ...prev.company, website: e.target.value }
                  }))}
                  className="input-field"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="form-label">NMLS ID</label>
                  <input
                    type="text"
                    value={info.nmlsId}
                    onChange={(e) => setInfo(prev => ({ ...prev, nmlsId: e.target.value }))}
                    className="input-field"
                  />
                </div>
                <div>
                  <label className="form-label">Realtor License</label>
                  <input
                    type="text"
                    value={info.realtorLicense}
                    onChange={(e) => setInfo(prev => ({ ...prev, realtorLicense: e.target.value }))}
                    className="input-field"
                  />
                </div>
              </div>

              <div>
                <label className="form-label">Status</label>
                <select
                  value={info.status}
                  onChange={(e) => setInfo(prev => ({ ...prev, status: e.target.value as User['status'] }))}
                  className="input-field"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div className="flex justify-end gap-2">
                <Button
                  variant="secondary"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="accent"
                  onClick={handleSave}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-text-muted">Email</p>
                  <div className="flex items-center gap-2">
                    <Mail size={16} className="text-text-muted" />
                    <p className="text-text">{info.email}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-text-muted">Phone</p>
                  <div className="flex items-center gap-2">
                    <Phone size={16} className="text-text-muted" />
                    <p className="text-text">{info.phone}</p>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm text-text-muted">Company</p>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Building2 size={16} className="text-text-muted" />
                    <p className="text-text">{info.company.name}</p>
                  </div>
                  {info.company.address && (
                    <p className="text-text-muted pl-6">{info.company.address}</p>
                  )}
                  {info.company.website && (
                    <div className="flex items-center gap-2 pl-6">
                      <Globe size={16} className="text-text-muted" />
                      <a
                        href={info.company.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary hover:underline"
                      >
                        {info.company.website}
                      </a>
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-text-muted">NMLS ID</p>
                  <div className="flex items-center gap-2">
                    <Shield size={16} className="text-text-muted" />
                    <p className="text-text">{info.nmlsId || 'Not specified'}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-text-muted">Realtor License</p>
                  <div className="flex items-center gap-2">
                    <Shield size={16} className="text-text-muted" />
                    <p className="text-text">{info.realtorLicense || 'Not specified'}</p>
                  </div>
                </div>
              </div>

              <div>
                <Button
                  variant="secondary"
                  onClick={() => setShowPasswordReset(!showPasswordReset)}
                >
                  <Key size={16} />
                  Reset Password
                </Button>

                {showPasswordReset && (
                  <div className="mt-4 space-y-4">
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      placeholder="Enter new password"
                      className="input-field"
                      minLength={8}
                    />
                    <div className="flex justify-end gap-2">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShowPasswordReset(false);
                          setNewPassword('');
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="accent"
                        onClick={handlePasswordReset}
                        disabled={newPassword.length < 8}
                      >
                        Set New Password
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMemberDetails;