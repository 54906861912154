import OpenAI from 'openai';
import { secureStorage } from '../utils/secureStorage';
import { DEFAULT_CONFIG } from './openai/config';
import { validateApiKey } from './openai/validation';
import { ChatService } from './openai/chat';
import { TranscriptionService } from './openai/transcription';
import type { ChatContext, TranscriptionResult, AIResponse } from './openai/types';

class OpenAIService {
  private openai: OpenAI | null = null;
  private chatService: ChatService | null = null;
  private transcriptionService: TranscriptionService | null = null;

  constructor() {
    this.initializeServices();
  }

  private initializeServices() {
    const apiKey = this.checkApiKey();
    if (apiKey) {
      this.openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true
      });
      this.chatService = new ChatService(this.openai);
      this.transcriptionService = new TranscriptionService(apiKey);
    }
  }

  checkApiKey(): string | null {
    try {
      // First check environment variable
      const envKey = import.meta.env.VITE_OPENAI_API_KEY;
      if (envKey && envKey !== 'your_api_key_here') {
        validateApiKey(envKey);
        return envKey;
      }

      // Then check secure storage
      const storedKey = secureStorage.getItem('openai_api_key');
      if (storedKey) {
        validateApiKey(storedKey);
        return storedKey;
      }

      return null;
    } catch (error) {
      console.error('Error checking API key:', error);
      return null;
    }
  }

  saveApiKey(apiKey: string): void {
    try {
      validateApiKey(apiKey);
      secureStorage.setItem('openai_api_key', apiKey);
      this.initializeServices();
    } catch (error) {
      console.error('Error saving API key:', error);
      throw error;
    }
  }

  removeApiKey(): void {
    secureStorage.removeItem('openai_api_key');
    this.openai = null;
    this.chatService = null;
    this.transcriptionService = null;
  }

  async generateResponse(message: string, context: ChatContext): Promise<string> {
    if (!this.chatService) {
      throw new Error('OpenAI API not initialized. Please add your API key.');
    }

    const response = await this.chatService.generateResponse(message, context);
    return response.text;
  }

  async transcribeAudio(audioBlob: Blob): Promise<string> {
    if (!this.transcriptionService) {
      throw new Error('OpenAI API not initialized. Please add your API key.');
    }

    const result = await this.transcriptionService.transcribeAudio(audioBlob);
    return result.text;
  }

  async generateJournalPrompt(context: { 
    role?: string; 
    previousEntries?: any[] 
  }): Promise<string> {
    if (!this.openai) {
      throw new Error('OpenAI API not initialized. Please add your API key.');
    }

    try {
      const response = await this.openai.chat.completions.create({
        model: DEFAULT_CONFIG.model,
        messages: [
          {
            role: 'system',
            content: 'Generate a thoughtful journal prompt for a real estate/mortgage professional.'
          },
          {
            role: 'user',
            content: JSON.stringify(context)
          }
        ],
        temperature: DEFAULT_CONFIG.temperature,
        max_tokens: DEFAULT_CONFIG.maxTokens
      });

      return response.choices[0].message?.content || 'What are your thoughts today?';
    } catch (error) {
      console.error('Failed to generate prompt:', error);
      throw error;
    }
  }

  async analyzeJournalEntry(entry: any): Promise<any> {
    if (!this.openai) {
      throw new Error('OpenAI API not initialized. Please add your API key.');
    }

    try {
      const response = await this.openai.chat.completions.create({
        model: DEFAULT_CONFIG.model,
        messages: [
          {
            role: 'system',
            content: 'Analyze this journal entry and provide insights, patterns, and suggestions.'
          },
          {
            role: 'user',
            content: JSON.stringify(entry)
          }
        ],
        temperature: DEFAULT_CONFIG.temperature,
        max_tokens: DEFAULT_CONFIG.maxTokens
      });

      const analysis = response.choices[0].message?.content;
      if (!analysis) throw new Error('No analysis generated');

      try {
        return JSON.parse(analysis);
      } catch {
        return {
          patterns: [analysis],
          suggestions: ['Continue your daily reflection practice'],
          progress: ['Journal entry recorded']
        };
      }
    } catch (error) {
      console.error('Journal analysis error:', error);
      throw error;
    }
  }
}

export const openAIService = new OpenAIService();
export const { 
  checkApiKey, 
  saveApiKey, 
  removeApiKey, 
  generateResponse, 
  transcribeAudio,
  generateJournalPrompt,
  analyzeJournalEntry 
} = openAIService;