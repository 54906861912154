import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Search, Filter, Play, Star, Clock } from 'lucide-react';
import { useApp } from '../context/AppContext';
import Button from '../components/Button';
import VideoEmbed from '../components/Journal/VideoEmbed';
import type { MotivationalVideo } from '../types/base';

const MotivationalVideos: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useApp();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [selectedVideo, setSelectedVideo] = useState<MotivationalVideo | null>(null);

  const categories = [
    { id: 'all', label: 'All Videos' },
    { id: 'motivation', label: 'Motivation' },
    { id: 'success', label: 'Success Stories' },
    { id: 'mindset', label: 'Mindset' },
    { id: 'leadership', label: 'Leadership' }
  ];

  const filteredVideos = state.videos.filter(video => {
    const matchesSearch = video.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      video.description?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || video.tags?.includes(selectedCategory);
    return matchesSearch && matchesCategory;
  });

  return (
    <div className="min-h-screen bg-background">
      <header className="page-header">
        <button onClick={() => navigate('/')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="text-xl font-bold text-text">Motivational Videos</h1>
      </header>

      <div className="container-content py-4 space-y-6">
        {/* Search and Filter */}
        <div className="space-y-4">
          <div className="relative">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search videos..."
              className="input-field pl-10"
            />
            <Search size={18} className="absolute left-3 top-1/2 -translate-y-1/2 text-text-muted" />
          </div>

          <div className="flex gap-2 overflow-x-auto pb-2">
            {categories.map(category => (
              <Button
                key={category.id}
                variant={selectedCategory === category.id ? 'accent' : 'secondary'}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.label}
              </Button>
            ))}
          </div>
        </div>

        {/* Video Grid */}
        {selectedVideo ? (
          <div className="card">
            <VideoEmbed
              video={selectedVideo}
              onComplete={() => setSelectedVideo(null)}
            />
            <div className="mt-4 space-y-2">
              <h2 className="text-lg font-semibold text-text">{selectedVideo.title}</h2>
              <p className="text-text-muted">{selectedVideo.description}</p>
              <div className="flex items-center gap-4 text-sm text-text-muted">
                <div className="flex items-center gap-1">
                  <Clock size={16} />
                  <span>{new Date(selectedVideo.addedAt).toLocaleDateString()}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Star size={16} />
                  <span>Featured</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredVideos.map(video => (
              <button
                key={video.id}
                onClick={() => setSelectedVideo(video)}
                className="card hover:border-primary transition-colors"
              >
                <div className="relative aspect-video rounded-lg overflow-hidden bg-secondary dark:bg-background">
                  {video.thumbnailUrl ? (
                    <img
                      src={video.thumbnailUrl}
                      alt={video.title}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Play size={40} className="text-text-muted" />
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="font-medium text-text line-clamp-2">{video.title}</h3>
                  {video.description && (
                    <p className="text-sm text-text-muted mt-1 line-clamp-2">
                      {video.description}
                    </p>
                  )}
                  <div className="flex items-center gap-2 mt-2 text-sm text-text-muted">
                    <Clock size={14} />
                    <span>{new Date(video.addedAt).toLocaleDateString()}</span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}

        {filteredVideos.length === 0 && (
          <div className="text-center py-8 text-text-muted">
            No videos found. Try adjusting your search or filters.
          </div>
        )}
      </div>
    </div>
  );
};

export default MotivationalVideos;