import React, { useState, useCallback } from 'react';
import { Send } from 'lucide-react';
import { useResizeObserver } from '../hooks/useResizeObserver';

interface ChatInputProps {
  input: string;
  setInput: (value: string) => void;
  handleSend: () => void;
  isLoading: boolean;
  disabled?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ 
  input, 
  setInput, 
  handleSend, 
  isLoading,
  disabled = false
}) => {
  const [height, setHeight] = useState<number>(44);

  const handleResize = useCallback((entry: ResizeObserverEntry) => {
    const textArea = entry.target as HTMLTextAreaElement;
    const newHeight = Math.max(44, Math.min(128, textArea.scrollHeight));
    
    if (newHeight !== height) {
      setHeight(newHeight);
      textArea.style.height = `${newHeight}px`;
    }
  }, [height]);

  const textareaRef = useResizeObserver<HTMLTextAreaElement>(handleResize);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="sticky bottom-20 left-0 right-0 p-4 border-t border-border bg-white dark:bg-secondary shadow-lg">
      <div className="max-w-3xl mx-auto flex gap-2 items-end">
        <textarea
          ref={textareaRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={disabled ? "Add API key in profile settings to start chatting" : "Type your message..."}
          className="flex-1 p-3 rounded-xl border border-border focus:border-primary focus:ring-2 focus:ring-primary/20 outline-none bg-white dark:bg-background text-text dark:text-text resize-none shadow-sm"
          style={{ height: `${height}px`, minHeight: '44px', maxHeight: '128px' }}
          disabled={disabled}
        />
        <button
          onClick={handleSend}
          disabled={!input.trim() || isLoading || disabled}
          className="h-11 w-11 flex items-center justify-center rounded-full bg-accent hover:bg-accent/90 text-text disabled:opacity-50 disabled:cursor-not-allowed shadow-sm transition-colors"
        >
          <Send size={20} />
        </button>
      </div>
      <p className="text-xs text-text-muted dark:text-text-muted mt-2 text-center">
        Press Enter to send, Shift + Enter for new line
      </p>
    </div>
  );
};

export default ChatInput;