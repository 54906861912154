import React, { useState } from 'react';
import { UserPlus, X } from 'lucide-react';
import Button from './Button';
import type { User } from '../types/base';

interface AddTeamMemberProps {
  onAdd: (user: Partial<User>) => void;
  onClose: () => void;
}

const AddTeamMember: React.FC<AddTeamMemberProps> = ({ onAdd, onClose }) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    role: 'mlo' as User['role'],
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd(form);
    setForm({ name: '', email: '', role: 'mlo' });
  };

  return (
    <div className="card">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-text">Add Team Member</h3>
        <button onClick={onClose} className="text-text-muted hover:text-text">
          <X size={20} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="form-label">Name</label>
          <input
            type="text"
            value={form.name}
            onChange={(e) => setForm(prev => ({ ...prev, name: e.target.value }))}
            className="input-field"
            placeholder="Enter member's name"
            required
          />
        </div>

        <div>
          <label className="form-label">Email</label>
          <input
            type="email"
            value={form.email}
            onChange={(e) => setForm(prev => ({ ...prev, email: e.target.value }))}
            className="input-field"
            placeholder="Enter member's email"
            required
          />
        </div>

        <div>
          <label className="form-label">Role</label>
          <select
            value={form.role}
            onChange={(e) => setForm(prev => ({ ...prev, role: e.target.value as User['role'] }))}
            className="input-field"
            required
          >
            <option value="mlo">MLO</option>
            <option value="realtor">Realtor</option>
            <option value="dual_agent">Dual Licensed Agent</option>
          </select>
        </div>

        <div className="flex justify-end gap-2 pt-4">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="accent" type="submit">
            Add Member
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTeamMember;