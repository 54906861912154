import React from 'react';
import { ArrowUp, ArrowDown } from 'lucide-react';
import { format } from 'date-fns';
import type { ActivityLogEntry } from '../types/base';

interface ActivityTableProps {
  logs: ActivityLogEntry[];
  sortConfig: {
    key: string;
    direction: 'asc' | 'desc';
  };
  onSort: (key: string) => void;
}

const ActivityTable: React.FC<ActivityTableProps> = ({
  logs,
  sortConfig,
  onSort
}) => {
  const SortHeader = ({ label, sortKey }: { label: string; sortKey: string }) => (
    <th
      className="px-4 py-2 text-left cursor-pointer hover:bg-secondary/50"
      onClick={() => onSort(sortKey)}
    >
      <div className="flex items-center gap-1">
        {label}
        {sortConfig.key === sortKey && (
          sortConfig.direction === 'asc' ? (
            <ArrowUp size={14} className="text-primary" />
          ) : (
            <ArrowDown size={14} className="text-primary" />
          )
        )}
      </div>
    </th>
  );

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead className="bg-secondary dark:bg-background">
          <tr>
            <SortHeader label="Time" sortKey="timestamp" />
            <SortHeader label="Activity" sortKey="activity" />
            <SortHeader label="Count" sortKey="count" />
          </tr>
        </thead>
        <tbody>
          {logs.map(log => (
            <tr key={log.id} className="border-t border-border hover:bg-secondary/20">
              <td className="px-4 py-3">
                {format(new Date(log.timestamp), 'MMM d, yyyy h:mm a')}
              </td>
              <td className="px-4 py-3">{log.activity}</td>
              <td className="px-4 py-3">{log.count}</td>
            </tr>
          ))}
          {logs.length === 0 && (
            <tr>
              <td colSpan={3} className="px-4 py-8 text-center text-text-muted">
                No activities found for the selected filters
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActivityTable;