import { EmailConfigManager } from './config';
import { MailgunClient } from './mailgun';
import { EmailLogger } from './logger';
import { templates } from './templates';
import type { EmailConfig, EmailTemplate, SendEmailResult, EmailStatus } from '../../types/email';

export class EmailService {
  private configManager = new EmailConfigManager();
  private logger = new EmailLogger();
  private mailgunClient: MailgunClient | null = null;

  async saveConfig(config: EmailConfig): Promise<void> {
    try {
      // First validate the configuration format
      this.configManager.validateConfig(config);
      
      // Create a new client and test the connection
      this.mailgunClient = new MailgunClient(config);
      const isValid = await this.mailgunClient.testConnection();
      
      if (!isValid) {
        throw new Error('Failed to verify Mailgun connection');
      }

      // If validation and connection test pass, save the config
      await this.configManager.saveConfig(config);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to save configuration';
      console.error('Failed to save email configuration:', errorMessage);
      throw new Error(errorMessage);
    }
  }

  async sendEmail(to: string, subject: string, html: string): Promise<SendEmailResult> {
    const config = this.configManager.getConfig();
    if (!config) {
      return {
        success: false,
        error: {
          code: 'not_configured',
          message: 'Email service not configured'
        }
      };
    }

    try {
      this.mailgunClient = new MailgunClient(config);
      const messageId = await this.mailgunClient.sendEmail(to, subject, html);

      // Log the successful send
      this.logger.logEmail({
        id: messageId,
        to,
        from: `${config.fromName} <${config.fromEmail}>`,
        subject,
        status: 'sent',
        sentAt: new Date().toISOString()
      });

      return {
        success: true,
        messageId
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to send email';
      
      // Log the failed attempt
      this.logger.logEmail({
        id: Date.now().toString(),
        to,
        from: `${config.fromName} <${config.fromEmail}>`,
        subject,
        status: 'failed',
        error: errorMessage,
        sentAt: new Date().toISOString()
      });

      return {
        success: false,
        error: {
          code: 'send_failed',
          message: errorMessage
        }
      };
    }
  }

  async sendTestEmail(to: string, template: EmailTemplate = 'welcomeEmail'): Promise<SendEmailResult> {
    const config = this.configManager.getConfig();
    if (!config) {
      return {
        success: false,
        error: {
          code: 'not_configured',
          message: 'Email service not configured'
        }
      };
    }

    try {
      const testUser = {
        id: 'test',
        name: 'Test User',
        email: to,
        role: 'mlo',
        status: 'active',
        createdAt: new Date().toISOString()
      };

      const emailContent = templates[template](testUser);
      return await this.sendEmail(to, emailContent.subject, emailContent.body);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to send test email';
      return {
        success: false,
        error: {
          code: 'send_failed',
          message: errorMessage
        }
      };
    }
  }

  async getEmailStatus(messageId: string): Promise<EmailStatus> {
    const config = this.configManager.getConfig();
    if (!config) {
      throw new Error('Email service not configured');
    }

    try {
      this.mailgunClient = new MailgunClient(config);
      const { status, deliveredAt } = await this.mailgunClient.getMessageStatus(messageId);
      
      // Update the log with the latest status
      this.logger.updateStatus(messageId, status, deliveredAt);

      return { status, deliveredAt };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to get status';
      return {
        status: 'failed',
        error: errorMessage
      };
    }
  }

  getLogs() {
    return this.logger.getAllLogs();
  }

  clearLogs() {
    this.logger.clearLogs();
  }

  getConfig(): EmailConfig | null {
    return this.configManager.getConfig();
  }

  clearConfig() {
    this.configManager.clearConfig();
  }
}

export const emailService = new EmailService();