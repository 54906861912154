export class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ValidationError';
  }
}

export const validateApiKey = (apiKey: string): void => {
  if (!apiKey) {
    throw new ValidationError('API key is required');
  }

  // For environment variables, we'll accept any non-empty string
  if (apiKey === import.meta.env.VITE_OPENAI_API_KEY) {
    return;
  }

  // For user-provided keys, enforce stricter validation
  if (!apiKey.startsWith('sk-')) {
    throw new ValidationError('Invalid API key format. Must start with "sk-"');
  }
  
  if (apiKey.length < 40) {
    throw new ValidationError('Invalid API key length. Must be at least 40 characters');
  }
};