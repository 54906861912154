import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../context/AppContext';
import { List, Bell, Phone, FileText, CheckCircle, DollarSign, ChevronDown, ChevronUp, UserPlus } from 'lucide-react';
import ActivitySummary from '../components/ActivitySummary';
import ConversionRateDashboard from '../components/ConversionRateDashboard';
import { PRIMARY_KPI_IDS } from '../data/tasks';

const Home = () => {
  const navigate = useNavigate();
  const { state } = useApp();
  const [showActivities, setShowActivities] = useState(false);
  const [showConversionRates, setShowConversionRates] = useState(false);

  const totalGciGoal = (state.profile?.mloGciGoal || 0) + (state.profile?.realtorGciGoal || 0);
  const totalYtdProduction = state.stats.mloYtdAmount + state.stats.realtorYtdAmount;

  // Get task counts from task history
  const getTaskCount = (taskId: number) => {
    const history = state.stats.taskHistory[taskId] || {
      daily: 0,
      weekly: 0,
      monthly: 0,
      yearly: 0
    };
    return history.yearly; // Use yearly total for cumulative stats
  };

  const stats = [
    { label: 'Dials', value: getTaskCount(16), icon: Phone }, // ID 16 for Dials
    { label: 'Leads', value: getTaskCount(26), icon: UserPlus }, // ID 26 for Leads
    { label: 'Conversations', value: getTaskCount(25), icon: FileText }, // ID 25 for Conversations
    { label: 'Appointments Set', value: getTaskCount(8), icon: CheckCircle }, // ID 8 for Buyer Appointment
    { label: 'Appointments Held', value: getTaskCount(7), icon: CheckCircle }, // ID 7 for Listing Appointment
    { label: 'Agreements', value: getTaskCount(6), icon: FileText }, // ID 6 for Offer Written
    { label: 'Closings', value: getTaskCount(11), icon: DollarSign }, // ID 11 for Closing
  ];

  return (
    <div className="min-h-screen bg-background">
      <header className="page-header">
        <div className="text-text flex size-12 shrink-0 items-center">
          <List size={24} />
        </div>
        <h2 className="page-title">Dashboard</h2>
        <div className="flex w-12 items-center justify-end">
          <button className="flex items-center justify-center rounded-full h-12 bg-transparent text-text">
            <Bell size={24} />
          </button>
        </div>
      </header>

      <div className="container-content">
        <section className="section">
          <div className="flex w-full flex-col gap-4 items-center">
            <div className="flex gap-4 flex-col items-center">
              <div className="bg-center bg-no-repeat aspect-square bg-cover rounded-full min-h-32 w-32">
                {state.profile?.profilePicture ? (
                  <img 
                    src={state.profile.profilePicture} 
                    alt="Profile" 
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <div className="w-full h-full bg-secondary dark:bg-background rounded-full flex items-center justify-center">
                    <span className="text-text text-2xl">
                      {state.profile?.name?.[0] || 'U'}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center justify-center">
                <p className="text-title text-center">
                  {state.profile?.name || 'User'}
                </p>
                <p className="text-text-muted text-base">
                  {state.profile?.title || 'MLO & Realtor'}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <h3 className="section-title mb-4">Total GCI Goals</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="card">
              <p className="text-text-muted">Combined Goal</p>
              <p className="text-2xl font-bold text-text">${totalGciGoal.toLocaleString()}</p>
            </div>
            <div className="card">
              <p className="text-text-muted">YTD Production</p>
              <p className="text-2xl font-bold text-text">${totalYtdProduction.toLocaleString()}</p>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="grid grid-cols-2 gap-4">
            {stats.map((stat, index) => (
              <div key={index} className="card">
                <div className="flex items-center gap-2">
                  <stat.icon size={20} className="text-text" />
                  <p className="text-text">{stat.label}</p>
                </div>
                <p className="text-2xl font-bold text-text">{stat.value}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="section">
          <button
            onClick={() => setShowConversionRates(!showConversionRates)}
            className="w-full flex items-center justify-between p-4 bg-white dark:bg-secondary rounded-lg shadow-sm"
          >
            <span className="text-lg font-semibold text-text">Conversion Rates</span>
            {showConversionRates ? (
              <ChevronUp size={24} className="text-text-muted" />
            ) : (
              <ChevronDown size={24} className="text-text-muted" />
            )}
          </button>

          {showConversionRates && (
            <div className="mt-4">
              <ConversionRateDashboard />
            </div>
          )}
        </section>

        <section className="section">
          <button
            onClick={() => setShowActivities(!showActivities)}
            className="w-full flex items-center justify-between p-4 bg-white dark:bg-secondary rounded-lg shadow-sm"
          >
            <span className="text-lg font-semibold text-text">Activities Summary</span>
            {showActivities ? (
              <ChevronUp size={24} className="text-text-muted" />
            ) : (
              <ChevronDown size={24} className="text-text-muted" />
            )}
          </button>

          {showActivities && (
            <div className="mt-4 space-y-4">
              <ActivitySummary />
            </div>
          )}
        </section>
      </div>

      <div className="h-20" />
    </div>
  );
};

export default Home;