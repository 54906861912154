import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ArrowLeft } from 'lucide-react';
import type { User } from '../types/base';

interface AccountForm {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  role: User['role'];
  branchName?: string;
  branchAddress?: string;
  branchPhone?: string;
}

const CreateAccount = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const [form, setForm] = useState<AccountForm>({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    role: 'mlo'
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  // Only admin can create other admins and branch managers
  const canCreateRole = (role: User['role']) => {
    if (!currentUser) return true; // Allow all roles for initial signup
    if (currentUser.role === 'admin') return true; // Admin can create any role
    if (currentUser.role === 'branch_manager' && role === 'mlo') return true;
    return false;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (form.password !== form.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // In a real app, this would be an API call
      const userId = `user_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      const branchId = form.role === 'branch_manager' 
        ? `branch_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
        : undefined;

      const newUser: User = {
        id: userId,
        email: form.email,
        name: form.name,
        role: form.role,
        branchId: currentUser?.role === 'branch_manager' ? currentUser.branchId : branchId,
        createdBy: currentUser?.id,
        createdAt: new Date().toISOString(),
        status: 'active'
      };

      // Store user data
      const users = JSON.parse(localStorage.getItem('users') || '[]');
      const passwords = JSON.parse(localStorage.getItem('passwords') || '{}');
      
      // Check if email already exists
      if (users.some((u: User) => u.email === form.email)) {
        setError('Email already exists');
        return;
      }

      users.push(newUser);
      passwords[userId] = form.password;
      
      localStorage.setItem('users', JSON.stringify(users));
      localStorage.setItem('passwords', JSON.stringify(passwords));

      // If creating a branch manager, also create their branch
      if (form.role === 'branch_manager' && branchId) {
        const branch = {
          id: branchId,
          name: form.branchName || `${form.name}'s Branch`,
          managerId: userId,
          address: form.branchAddress,
          phone: form.branchPhone,
          createdAt: new Date().toISOString()
        };

        const branches = JSON.parse(localStorage.getItem('branches') || '[]');
        branches.push(branch);
        localStorage.setItem('branches', JSON.stringify(branches));
      }

      setSuccess(true);
      setTimeout(() => {
        if (currentUser) {
          navigate('/admin'); // Return to admin dashboard if creating account for someone else
        } else {
          navigate('/login'); // Go to login if self-registering
        }
      }, 2000);
    } catch (err) {
      setError('Failed to create account');
    }
  };

  return (
    <div className="min-h-screen pb-20">
      <header className="p-4 flex items-center gap-4 border-b border-[#F5F2E8]">
        <button onClick={() => navigate(-1)} className="p-2">
          <ArrowLeft size={24} className="text-[#1C1A0D]" />
        </button>
        <h1 className="text-xl font-bold text-[#1C1A0D]">
          {currentUser ? 'Create User Account' : 'Sign Up'}
        </h1>
      </header>

      <div className="p-4">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
              Full Name
            </label>
            <input
              type="text"
              value={form.name}
              onChange={(e) => setForm(prev => ({ ...prev, name: e.target.value }))}
              className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
              Email
            </label>
            <input
              type="email"
              value={form.email}
              onChange={(e) => setForm(prev => ({ ...prev, email: e.target.value }))}
              className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
              Password
            </label>
            <input
              type="password"
              value={form.password}
              onChange={(e) => setForm(prev => ({ ...prev, password: e.target.value }))}
              className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
              Confirm Password
            </label>
            <input
              type="password"
              value={form.confirmPassword}
              onChange={(e) => setForm(prev => ({ ...prev, confirmPassword: e.target.value }))}
              className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
              Role
            </label>
            <select
              value={form.role}
              onChange={(e) => setForm(prev => ({ ...prev, role: e.target.value as User['role'] }))}
              className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
              required
            >
              {(!currentUser || currentUser.role === 'admin') && (
                <option value="admin">Admin</option>
              )}
              {canCreateRole('branch_manager') && (
                <option value="branch_manager">Branch Manager</option>
              )}
              {canCreateRole('mlo') && (
                <option value="mlo">MLO</option>
              )}
            </select>
          </div>

          {form.role === 'branch_manager' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
                  Branch Name
                </label>
                <input
                  type="text"
                  value={form.branchName}
                  onChange={(e) => setForm(prev => ({ ...prev, branchName: e.target.value }))}
                  className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
                  Branch Address
                </label>
                <input
                  type="text"
                  value={form.branchAddress}
                  onChange={(e) => setForm(prev => ({ ...prev, branchAddress: e.target.value }))}
                  className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#1C1A0D] mb-2">
                  Branch Phone
                </label>
                <input
                  type="tel"
                  value={form.branchPhone}
                  onChange={(e) => setForm(prev => ({ ...prev, branchPhone: e.target.value }))}
                  className="w-full p-3 rounded-lg border border-[#F5F2E8] focus:border-[#F2C70D] focus:ring-1 focus:ring-[#F2C70D]"
                />
              </div>
            </div>
          )}

          {error && (
            <div className="text-red-500 text-sm text-center">
              {error}
            </div>
          )}

          {success && (
            <div className="text-green-500 text-sm text-center">
              Account created successfully! Redirecting...
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-[#F2C70D] text-[#1C1A0D] py-3 rounded-lg font-medium"
          >
            Create Account
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateAccount;