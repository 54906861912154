import React from 'react';
import { FileText, Trash2, Eye } from 'lucide-react';
import { KnowledgeBaseItem } from '../../types/knowledge';
import { formatBytes, formatDateTime } from '../../utils/formatters';
import Button from '../Button';

interface DocumentListProps {
  documents: KnowledgeBaseItem[];
  onDelete: (id: string) => void;
  onPreview: (document: KnowledgeBaseItem) => void;
}

const DocumentList: React.FC<DocumentListProps> = ({
  documents,
  onDelete,
  onPreview
}) => {
  if (!documents.length) {
    return (
      <div className="text-center py-8 text-text-muted">
        No documents uploaded yet
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {documents.map((doc) => (
        <div
          key={doc.id}
          className="flex items-center justify-between p-4 bg-white dark:bg-secondary rounded-lg"
        >
          <div className="flex items-center gap-3">
            <FileText className="text-text" />
            <div>
              <h4 className="font-medium text-text">{doc.title}</h4>
              <div className="flex gap-2 text-sm text-text-muted">
                <span>{formatBytes(doc.size)}</span>
                <span>•</span>
                <span>{formatDateTime(doc.uploadDate)}</span>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <Button
              variant="secondary"
              onClick={() => onPreview(doc)}
            >
              <Eye size={18} />
              Preview
            </Button>
            <Button
              variant="secondary"
              onClick={() => onDelete(doc.id)}
            >
              <Trash2 size={18} className="text-red-500" />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DocumentList;