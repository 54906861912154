import React, { useMemo } from 'react';
import { BarChart2 } from 'lucide-react';
import { format, isToday } from 'date-fns';
import type { ActivityLogEntry } from '../types/base';

interface DailySummaryProps {
  logs: ActivityLogEntry[];
}

const DailySummary: React.FC<DailySummaryProps> = ({ logs }) => {
  const summary = useMemo(() => {
    const todayLogs = logs.filter(log => isToday(new Date(log.timestamp)));
    
    const activityTotals = todayLogs.reduce((acc, log) => {
      if (!acc[log.activity]) {
        acc[log.activity] = 0;
      }
      acc[log.activity] += log.count;
      return acc;
    }, {} as Record<string, number>);

    const totalActivities = Object.keys(activityTotals).length;
    const totalCount = Object.values(activityTotals).reduce((sum, count) => sum + count, 0);
    const lastActivity = todayLogs[0]?.timestamp 
      ? format(new Date(todayLogs[0].timestamp), 'h:mm a')
      : null;

    return {
      activityTotals,
      totalActivities,
      totalCount,
      lastActivity
    };
  }, [logs]);

  return (
    <div className="card space-y-4">
      <div className="flex items-center gap-2">
        <BarChart2 size={20} className="text-text" />
        <h2 className="font-semibold text-text">Today's Summary</h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="bg-secondary dark:bg-background p-4 rounded-lg">
          <p className="text-text-muted text-sm">Total Activities</p>
          <p className="text-2xl font-bold text-text">{summary.totalActivities}</p>
        </div>
        <div className="bg-secondary dark:bg-background p-4 rounded-lg">
          <p className="text-text-muted text-sm">Total Count</p>
          <p className="text-2xl font-bold text-text">{summary.totalCount}</p>
        </div>
        <div className="bg-secondary dark:bg-background p-4 rounded-lg">
          <p className="text-text-muted text-sm">Last Activity</p>
          <p className="text-2xl font-bold text-text">
            {summary.lastActivity || 'No activities'}
          </p>
        </div>
      </div>

      {Object.keys(summary.activityTotals).length > 0 && (
        <div className="space-y-2">
          <h3 className="text-sm font-medium text-text">Activity Breakdown</h3>
          <div className="space-y-2">
            {Object.entries(summary.activityTotals).map(([activity, count]) => (
              <div key={activity} className="flex items-center gap-2">
                <div className="flex-1">
                  <div className="h-8 bg-secondary dark:bg-background rounded-lg overflow-hidden">
                    <div
                      className="h-full bg-primary"
                      style={{
                        width: `${(count / summary.totalCount) * 100}%`
                      }}
                    />
                  </div>
                </div>
                <div className="w-32 text-right">
                  <p className="text-sm font-medium text-text">{activity}</p>
                  <p className="text-sm text-text-muted">{count} total</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DailySummary;