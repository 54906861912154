import React from 'react';
import { Code, Copy, CheckCircle } from 'lucide-react';
import Button from '../Button';

const ApiDocs: React.FC = () => {
  const [copiedId, setCopiedId] = React.useState<string | null>(null);

  const copyToClipboard = (text: string, id: string) => {
    navigator.clipboard.writeText(text);
    setCopiedId(id);
    setTimeout(() => setCopiedId(null), 2000);
  };

  const examples = [
    {
      id: 'auth',
      title: 'Authentication',
      code: 'Authorization: Basic {API_KEY}',
      description: 'Add this header to all API requests'
    },
    {
      id: 'send',
      title: 'Send Email',
      code: `POST /api/v1/emails

{
  "to": "recipient@example.com",
  "subject": "Email Subject",
  "html": "<p>Email content</p>",
  "template": "welcomeEmail",
  "variables": {
    "name": "John Doe"
  }
}`,
      description: 'Send an email using a template'
    },
    {
      id: 'status',
      title: 'Get Email Status',
      code: 'GET /api/v1/emails/{message_id}/status',
      description: 'Check the delivery status of a sent email'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="card">
        <div className="flex items-center gap-2 mb-6">
          <Code size={20} className="text-text" />
          <h2 className="text-lg font-semibold text-text">API Documentation</h2>
        </div>
        
        <div className="space-y-6">
          {examples.map(example => (
            <section key={example.id} className="space-y-2">
              <h3 className="text-md font-medium text-text">{example.title}</h3>
              <div className="bg-secondary dark:bg-background p-4 rounded-lg">
                <div className="flex justify-between items-start gap-4">
                  <pre className="text-sm text-text-muted overflow-x-auto">
                    <code>{example.code}</code>
                  </pre>
                  <Button
                    variant="text"
                    onClick={() => copyToClipboard(example.code, example.id)}
                    className="shrink-0"
                  >
                    {copiedId === example.id ? (
                      <CheckCircle size={16} className="text-green-500" />
                    ) : (
                      <Copy size={16} />
                    )}
                  </Button>
                </div>
                <p className="text-sm text-text-muted mt-2">{example.description}</p>
              </div>
            </section>
          ))}
        </div>
      </div>

      <div className="card bg-secondary/50 p-4">
        <h3 className="font-medium text-text mb-2">Important Notes:</h3>
        <ul className="list-disc list-inside space-y-1 text-sm text-text-muted">
          <li>All API requests must include your API key</li>
          <li>Rate limits apply to all endpoints</li>
          <li>Email templates must be configured before use</li>
          <li>Always check email status after sending</li>
        </ul>
      </div>
    </div>
  );
};

export default ApiDocs;