import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, Bell, Plus, Minus } from 'lucide-react';
import { useApp } from '../context/AppContext';
import { BUSINESS_TASKS, WELLNESS_TASKS } from '../data/tasks';
import Button from './Button';
import type { Task } from '../types/base';

const TaskList = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useApp();
  const [taskCounts, setTaskCounts] = useState<Record<number, number>>({});

  // Filter tasks based on selected role
  const getFilteredTasks = () => {
    const selectedRole = state.profile?.selectedRole;
    if (!selectedRole) return [];

    return [...BUSINESS_TASKS, ...WELLNESS_TASKS].filter(task =>
      task.role.includes(selectedRole)
    );
  };

  // Sort categories to prioritize dollar-producing activities
  const priorityOrder = [
    'Prospecting',
    'Appointments',
    'Transactions',
    'Showings',
    'Client Relations',
    'Marketing',
    'Daily Routine',
    'Development',
    'Team',
    'Admin',
    'Wellness'
  ];

  // Group tasks by category
  const groupedTasks = getFilteredTasks().reduce((groups, task) => {
    const category = task.type === 'wellness' ? 'Wellness' : task.category;
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(task);
    return groups;
  }, {} as Record<string, Task[]>);

  const sortedCategories = Object.keys(groupedTasks).sort(
    (a, b) => priorityOrder.indexOf(a) - priorityOrder.indexOf(b)
  );

  const handleIncrement = (taskId: number) => {
    const task = getFilteredTasks().find(t => t.id === taskId);
    if (!task) return;

    const currentCount = taskCounts[taskId] || 0;
    if (task.max === Infinity || currentCount < task.max) {
      setTaskCounts(prev => ({
        ...prev,
        [taskId]: currentCount + 1
      }));
    }
  };

  const handleDecrement = (taskId: number) => {
    setTaskCounts(prev => ({
      ...prev,
      [taskId]: Math.max(0, (prev[taskId] || 0) - 1)
    }));
  };

  const handleSubmit = () => {
    // Calculate total points
    const totalPoints = Object.entries(taskCounts).reduce((total, [taskId, count]) => {
      const task = getFilteredTasks().find(t => t.id === Number(taskId));
      return total + (task?.points || 0) * count;
    }, 0);

    // Create activity logs
    const activityLogs = Object.entries(taskCounts).map(([taskId, count]) => {
      const task = getFilteredTasks().find(t => t.id === Number(taskId));
      return {
        id: `${Date.now()}_${taskId}`,
        taskId: Number(taskId),
        activity: task?.name || 'Unknown Activity',
        count,
        timestamp: new Date().toISOString()
      };
    });

    // Update state with tasks and activity logs
    dispatch({
      type: 'SUBMIT_TASKS',
      payload: {
        counts: taskCounts,
        totalPoints,
        activityLogs
      }
    });

    // Reset all task counts to zero
    setTaskCounts({});
  };

  const hasActivities = Object.values(taskCounts).some(count => count > 0);

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <header className="page-header">
        <div className="text-text flex size-12 shrink-0 items-center">
          <List size={24} />
        </div>
        <h2 className="page-title">Daily Activities</h2>
        <div className="flex w-12 items-center justify-end">
          <button className="flex items-center justify-center rounded-full h-12 bg-transparent text-text">
            <Bell size={24} />
          </button>
        </div>
      </header>

      <div className="flex-1 overflow-y-auto">
        <div className="container-content py-4 pb-32">
          {state.profile?.selectedRole ? (
            sortedCategories.map(category => (
              <section key={category} className="section">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="section-title">{category}</h2>
                </div>

                <div className="space-y-3">
                  {groupedTasks[category].map(task => (
                    <div
                      key={task.id}
                      className="bg-white dark:bg-secondary rounded-lg p-4 flex items-center justify-between"
                    >
                      <div>
                        <h3 className="font-medium text-text">{task.name}</h3>
                        <p className="text-sm text-text-muted">
                          {task.points} point{task.points !== 1 ? 's' : ''} each
                        </p>
                      </div>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => handleDecrement(task.id)}
                          disabled={!taskCounts[task.id]}
                          className="p-2 rounded-lg bg-secondary dark:bg-background text-text disabled:opacity-50"
                        >
                          <Minus size={20} />
                        </button>
                        <span className="w-8 text-center font-medium text-text">
                          {taskCounts[task.id] || 0}
                        </span>
                        <button
                          onClick={() => handleIncrement(task.id)}
                          disabled={task.max !== Infinity && (taskCounts[task.id] || 0) >= task.max}
                          className="p-2 rounded-lg bg-secondary dark:bg-background text-text disabled:opacity-50"
                        >
                          <Plus size={20} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            ))
          ) : (
            <div className="text-center text-text-muted py-8">
              Select your role to view available tasks
            </div>
          )}
        </div>
      </div>

      {/* Submit button */}
      <div className="fixed bottom-20 left-0 right-0 p-4 bg-background border-t border-border">
        <div className="container-content">
          <Button
            variant="accent"
            size="lg"
            onClick={handleSubmit}
            disabled={!hasActivities || !state.profile?.selectedRole}
            fullWidth
          >
            Submit Activities
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TaskList;