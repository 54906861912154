import React, { useState } from 'react';
import { Save, Trash2 } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import Button from './Button';
import type { ThemeColors } from '../types/theme';

interface ColorInputProps {
  label: string;
  value: string;
  onChange: (color: string) => void;
}

const ColorInput: React.FC<ColorInputProps> = ({ label, value, onChange }) => {
  return (
    <div className="relative">
      <label className="block text-sm font-medium mb-2 text-text">
        {label}
      </label>
      <div className="flex gap-2">
        <input
          type="color"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-12 h-10 p-1 rounded-lg border cursor-pointer bg-white dark:bg-background"
        />
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="flex-1 h-10 px-3 rounded-lg border border-border focus:border-primary focus:ring-1 focus:ring-primary/20 outline-none bg-white dark:bg-background text-text"
          placeholder="#000000"
        />
      </div>
    </div>
  );
};

const CustomPalette: React.FC = () => {
  const { theme, updateTheme } = useTheme();
  const [paletteName, setPaletteName] = useState('');
  const [colors, setColors] = useState<ThemeColors>(theme.colors);
  const [savedPalettes, setSavedPalettes] = useState<Record<string, ThemeColors>>(() => {
    const saved = localStorage.getItem('customPalettes');
    return saved ? JSON.parse(saved) : {};
  });
  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleColorChange = (key: keyof ThemeColors) => (color: string) => {
    setColors(prev => ({
      ...prev,
      [key]: color
    }));
  };

  const handleSave = () => {
    if (!paletteName.trim()) {
      alert('Please enter a palette name');
      return;
    }

    // Save to local storage
    const newPalettes = {
      ...savedPalettes,
      [paletteName]: colors
    };
    setSavedPalettes(newPalettes);
    localStorage.setItem('customPalettes', JSON.stringify(newPalettes));

    // Apply the theme
    updateTheme({
      ...theme,
      colors: colors
    });

    setPaletteName('');
    setSaveSuccess(true);
    setTimeout(() => setSaveSuccess(false), 3000);
  };

  const handleApply = (palette: ThemeColors) => {
    setColors(palette);
    updateTheme({
      ...theme,
      colors: palette
    });
  };

  const handleDelete = (name: string) => {
    const newPalettes = { ...savedPalettes };
    delete newPalettes[name];
    setSavedPalettes(newPalettes);
    localStorage.setItem('customPalettes', JSON.stringify(newPalettes));
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-2 text-text">
            Palette Name
          </label>
          <input
            type="text"
            value={paletteName}
            onChange={(e) => setPaletteName(e.target.value)}
            className="input-field"
            placeholder="My Custom Palette"
          />
        </div>

        {Object.entries(colors).map(([key, value]) => (
          <ColorInput
            key={key}
            label={`${key.charAt(0).toUpperCase()}${key.slice(1)} Color`}
            value={value}
            onChange={handleColorChange(key as keyof ThemeColors)}
          />
        ))}

        <Button
          variant="accent"
          onClick={handleSave}
          disabled={!paletteName.trim()}
          fullWidth
        >
          <Save size={20} />
          Save & Apply Theme
        </Button>

        {saveSuccess && (
          <p className="text-green-500 text-center">Theme saved and applied successfully!</p>
        )}
      </div>

      {Object.keys(savedPalettes).length > 0 && (
        <div className="space-y-3">
          <h4 className="font-medium text-text">Saved Palettes</h4>
          {Object.entries(savedPalettes).map(([name, palette]) => (
            <div
              key={name}
              className="flex items-center gap-3 p-3 rounded-lg border border-border"
            >
              <div className="flex gap-2">
                {Object.values(palette).map((color, index) => (
                  <div
                    key={index}
                    className="w-6 h-6 rounded-full"
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
              <span className="flex-1 text-text">{name}</span>
              <div className="flex gap-2">
                <Button
                  variant="secondary"
                  onClick={() => handleApply(palette)}
                >
                  Apply
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleDelete(name)}
                >
                  <Trash2 size={18} className="text-red-500" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomPalette;