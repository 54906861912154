import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Play, Volume2, VolumeX, ArrowRight } from 'lucide-react';
import { useApp } from '../context/AppContext';
import Button from '../components/Button';

const JournalVideo: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useApp();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (!state.todayVideo) {
      navigate('/journal/entries');
    }
  }, [state.todayVideo, navigate]);

  const getEmbedUrl = () => {
    if (!state.todayVideo) return '';
    
    const url = new URL(state.todayVideo.url);
    if (state.todayVideo.platform === 'youtube') {
      const videoId = url.searchParams.get('v');
      return `https://www.youtube.com/embed/${videoId}?autoplay=${isPlaying ? 1 : 0}&mute=${isMuted ? 1 : 0}&cc_load_policy=1`;
    } else if (state.todayVideo.platform === 'vimeo') {
      const videoId = url.pathname.split('/')[1];
      return `https://player.vimeo.com/video/${videoId}?autoplay=${isPlaying ? 1 : 0}&muted=${isMuted ? 1 : 0}`;
    }
    return url.toString();
  };

  if (!state.todayVideo) {
    return null;
  }

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-4xl space-y-6">
          <div className="text-center space-y-2 mb-8">
            <h1 className="text-2xl font-bold text-text">Daily Inspiration</h1>
            <p className="text-text-muted">
              Take a moment to watch today's motivational video
            </p>
          </div>

          <div className="relative aspect-video rounded-lg overflow-hidden bg-black">
            {!isPlaying ? (
              <div className="absolute inset-0 flex items-center justify-center">
                {state.todayVideo.thumbnailUrl && (
                  <img
                    src={state.todayVideo.thumbnailUrl}
                    alt={state.todayVideo.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                )}
                <button
                  onClick={() => setIsPlaying(true)}
                  className="relative z-10 w-20 h-20 rounded-full bg-primary text-white flex items-center justify-center shadow-lg hover:bg-primary/90 transition-colors"
                >
                  <Play size={40} className="ml-1" />
                </button>
              </div>
            ) : (
              <iframe
                src={getEmbedUrl()}
                className="absolute inset-0 w-full h-full"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}

            {isPlaying && (
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => setIsMuted(!isMuted)}
                    className="text-white hover:text-primary transition-colors"
                  >
                    {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="text-center space-y-4">
            <h2 className="text-xl font-semibold text-text">
              {state.todayVideo.title}
            </h2>
            {state.todayVideo.description && (
              <p className="text-text-muted">{state.todayVideo.description}</p>
            )}
          </div>

          <div className="flex justify-center mt-8">
            <Button
              variant="accent"
              onClick={() => navigate('/journal/entries')}
              size="lg"
            >
              Continue to Journal Entries
              <ArrowRight size={20} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JournalVideo;