import React from 'react';
import { Home, ListTodo, Target, Bot, Book, LayoutDashboard, LogOut, UserX, BarChart2, Film } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useApp } from '../context/AppContext';

const Navigation = () => {
  const location = useLocation();
  const { user, logout, isImpersonating, endImpersonation } = useAuth();
  const { state } = useApp();
  
  const isActive = (path: string) => location.pathname.startsWith(path);
  
  // Hide navigation on auth screens
  if (location.pathname === '/login' || location.pathname === '/create-account') {
    return null;
  }

  const showDashboardLink = user?.role === 'admin' || user?.role === 'branch_manager';
  const layout = state.profile?.layout || 'default';

  const handleLogout = () => {
    if (isImpersonating) {
      endImpersonation();
    } else {
      logout();
    }
  };

  const navItems = [
    { to: '/', icon: <Home strokeWidth={1.5} />, label: 'Home' },
    { to: '/tasks', icon: <ListTodo strokeWidth={1.5} />, label: 'Tasks' },
    { to: '/goals', icon: <Target strokeWidth={1.5} />, label: 'Goals' },
    { to: '/journal', icon: <Book strokeWidth={1.5} />, label: 'Journal' },
    { to: '/coach', icon: <Bot strokeWidth={1.5} />, label: 'Coach' },
    { to: '/videos', icon: <Film strokeWidth={1.5} />, label: 'Videos' },
    { to: '/activities', icon: <BarChart2 strokeWidth={1.5} />, label: 'Activities' },
    showDashboardLink ? {
      to: user?.role === 'admin' ? '/admin' : '/team',
      icon: <LayoutDashboard strokeWidth={1.5} />,
      label: 'Dashboard'
    } : null
  ].filter(Boolean);

  return (
    <nav className="fixed bottom-0 left-0 right-0 h-20 bg-white dark:bg-secondary border-t border-border z-50">
      {isImpersonating && (
        <div className="absolute -top-8 left-0 right-0 bg-primary/10 py-1 px-4 text-sm text-primary text-center">
          Viewing as {user?.name}
        </div>
      )}
      <div className="max-w-md mx-auto h-full flex items-center justify-between px-4">
        {navItems.map(item => item && (
          <Link
            key={item.to}
            to={item.to}
            className={`flex flex-col items-center justify-center p-2 ${
              isActive(item.to)
                ? 'text-primary'
                : 'text-text-muted hover:text-text'
            }`}
          >
            <div className="w-6 h-6 flex items-center justify-center">
              {item.icon}
            </div>
            <span className="text-xs mt-1">{item.label}</span>
          </Link>
        ))}
        <button
          onClick={handleLogout}
          className="flex flex-col items-center justify-center p-2 text-text-muted hover:text-text"
        >
          {isImpersonating ? <UserX size={20} /> : <LogOut size={20} />}
          <span className="text-xs mt-1">
            {isImpersonating ? 'End Session' : 'Logout'}
          </span>
        </button>
      </div>
    </nav>
  );
};

export default Navigation;