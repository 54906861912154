import React from 'react';
import { Mail, Trash2 } from 'lucide-react';
import { emailService } from '../../services/emailService';
import { formatDateTime, formatTimeAgo } from '../../utils/formatters';
import Button from '../Button';
import StatusIndicator from './components/StatusIndicator';

const EmailLogs: React.FC = () => {
  const logs = emailService.getLogs();

  const handleClearLogs = () => {
    if (window.confirm('Are you sure you want to clear all email logs?')) {
      emailService.clearLogs();
      window.location.reload();
    }
  };

  if (logs.length === 0) {
    return (
      <div className="text-center text-text-muted py-8">
        No email logs found. Send a test email to see logs here.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Mail size={20} className="text-text" />
          <h2 className="text-lg font-semibold text-text">Email Logs</h2>
        </div>
        <Button
          variant="secondary"
          onClick={handleClearLogs}
        >
          <Trash2 size={16} />
          Clear Logs
        </Button>
      </div>

      <div className="space-y-2">
        {logs.map(log => (
          <div key={log.id} className="card hover:bg-secondary/50 transition-colors">
            <div className="flex items-center justify-between gap-4">
              <div className="min-w-0 flex-1">
                <div className="flex items-center gap-2">
                  <StatusIndicator status={log.status} />
                  <p className="font-medium text-text truncate">
                    {log.subject}
                  </p>
                </div>
                <p className="text-sm text-text-muted truncate">
                  To: {log.to}
                </p>
              </div>
              <div className="text-right shrink-0">
                <p className="text-sm text-text">
                  {formatDateTime(log.sentAt)}
                </p>
                <p className="text-xs text-text-muted">
                  {formatTimeAgo(log.sentAt)}
                </p>
              </div>
            </div>
            {log.error && (
              <p className="mt-2 text-sm text-red-500">
                Error: {log.error}
              </p>
            )}
            {log.deliveredAt && (
              <p className="mt-2 text-xs text-green-500">
                Delivered: {formatDateTime(log.deliveredAt)}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailLogs;