import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Briefcase, DollarSign, Home, Users } from 'lucide-react';
import { useApp } from '../context/AppContext';
import type { UserRole } from '../types/base';

const RoleSelection = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useApp();

  const roles = [
    {
      id: 'mlo',
      title: 'MLO Only',
      description: 'Access mortgage-specific tasks and MLO GCI calculator',
      icon: DollarSign,
      features: [
        'Loan Pipeline Management',
        'MLO-specific Task Lists',
        'MLO GCI Calculator',
        'Mortgage Team Collaboration'
      ]
    },
    {
      id: 'realtor',
      title: 'Real Estate Agent',
      description: 'Access property-related tasks and Real Estate GCI calculator',
      icon: Home,
      features: [
        'Listing Management',
        'Real Estate Task Lists',
        'Real Estate GCI Calculator',
        'Property Team Collaboration'
      ]
    },
    {
      id: 'dual',
      title: 'Dual Licensed',
      description: 'Full access to both MLO and Real Estate features',
      icon: Users,
      features: [
        'Combined Task Lists',
        'Both GCI Calculators',
        'Full Team Integration',
        'Cross-role Performance Tracking'
      ]
    }
  ] as const;

  const handleRoleSelect = (role: UserRole) => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: { 
        selectedRole: role,
        // Initialize default values for the selected role
        mloGciGoal: role === 'mlo' || role === 'dual' ? 500000 : 0,
        realtorGciGoal: role === 'realtor' || role === 'dual' ? 500000 : 0,
        commissionBps: 150, // 1.5%
        averageLoanAmount: 250000,
        conversionRate: 20,
        pullThroughRate: 75,
        preQualGoalPerPartner: 3
      }
    });
    navigate('/');
  };

  // If role is already selected, redirect to home
  if (state.profile?.selectedRole) {
    navigate('/');
    return null;
  }

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <div className="max-w-4xl w-full space-y-8">
        <div className="text-center space-y-4">
          <div className="flex items-center justify-center gap-2">
            <Briefcase className="w-8 h-8 text-primary" />
            <h1 className="text-3xl font-bold text-text">Select Your Role</h1>
          </div>
          <p className="text-text-muted max-w-md mx-auto">
            Choose your role to customize your experience. This selection will determine
            your task lists and available features.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-6">
          {roles.map((role) => {
            const Icon = role.icon;
            return (
              <button
                key={role.id}
                onClick={() => handleRoleSelect(role.id)}
                className="bg-white dark:bg-secondary rounded-xl p-6 text-left space-y-4 hover:border-primary border-2 border-transparent transition-colors"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-primary/10 rounded-lg">
                    <Icon className="w-6 h-6 text-primary" />
                  </div>
                  <h2 className="text-xl font-semibold text-text">{role.title}</h2>
                </div>
                <p className="text-text-muted text-sm">{role.description}</p>
                <ul className="space-y-2">
                  {role.features.map((feature, index) => (
                    <li key={index} className="flex items-center gap-2 text-sm text-text">
                      <div className="w-1 h-1 rounded-full bg-primary" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </button>
            );
          })}
        </div>

        <p className="text-sm text-text-muted text-center">
          Note: This selection is permanent and can only be changed by an administrator.
        </p>
      </div>
    </div>
  );
};

export default RoleSelection;