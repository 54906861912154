import React, { useState, useEffect } from 'react';
import { Calculator, Save, RefreshCw } from 'lucide-react';
import { useApp } from '../context/AppContext';
import Button from './Button';
import type { WealthCalculations } from '../types/base';

const RealtorWealthCalculator: React.FC<{ ytdAmount: number }> = ({ ytdAmount }) => {
  const { state, dispatch } = useApp();
  const [showCalculator, setShowCalculator] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [wealthInputs, setWealthInputs] = useState({
    gci: state.profile?.realtorGciGoal?.toString() || '',
    workWeeks: '52',
    avgCompensation: '12000',
    appointmentsToAgreements: '50',
    agreementsToClosings: '65'
  });
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    setIsEditing(!state.profile?.realtorWealthCalculations);
  }, [state.profile?.realtorWealthCalculations]);

  const calculateWealth = (inputs: typeof wealthInputs): WealthCalculations => {
    const gci = Number(inputs.gci);
    const closings = gci / Number(inputs.avgCompensation);
    const agreements = closings / (Number(inputs.agreementsToClosings) / 100);
    const appointments = agreements / (Number(inputs.appointmentsToAgreements) / 100);
    const appointmentsPerWeek = appointments / Number(inputs.workWeeks);

    return {
      appointmentsPerWeek,
      totalAppointments: appointments,
      agencyAgreements: agreements,
      closings,
      gci
    };
  };

  const handleSave = () => {
    const calculations = calculateWealth(wealthInputs);
    setIsEditing(false);

    dispatch({
      type: 'UPDATE_STATS',
      payload: {
        realtorGciGoal: Number(wealthInputs.gci)
      }
    });

    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {
        realtorGciGoal: Number(wealthInputs.gci),
        realtorWealthCalculations: calculations
      }
    });

    setSaveSuccess(true);
    setTimeout(() => setSaveSuccess(false), 3000);
  };

  const handleStartOver = () => {
    setIsEditing(true);
    setWealthInputs({
      gci: '',
      workWeeks: '52',
      avgCompensation: '12000',
      appointmentsToAgreements: '50',
      agreementsToClosings: '65'
    });
  };

  const showResults = Number(wealthInputs.gci) > 0;

  return (
    <div className="space-y-6">
      <Button
        variant="accent"
        size="lg"
        fullWidth
        onClick={() => setShowCalculator(!showCalculator)}
      >
        <Calculator size={20} />
        Real Estate GCI Calculator
      </Button>

      {showCalculator && (
        <div className="card space-y-6">
          {!isEditing && state.profile?.realtorWealthCalculations ? (
            <>
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-text">
                  Your Real Estate Goals
                </h2>
                <Button
                  variant="text"
                  onClick={handleStartOver}
                >
                  <RefreshCw size={18} />
                  Start Over
                </Button>
              </div>
              <div className="space-y-4">
                <div className="card">
                  <p className="text-text-muted">Annual GCI Goal</p>
                  <p className="text-2xl font-bold text-text">
                    ${Number(wealthInputs.gci).toLocaleString()}
                  </p>
                  <p className="text-text-muted">
                    YTD: ${ytdAmount.toLocaleString()}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="card">
                    <p className="text-text-muted">Weekly Appointments</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.realtorWealthCalculations.appointmentsPerWeek.toFixed(1)}
                    </p>
                  </div>
                  <div className="card">
                    <p className="text-text-muted">Total Closings</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.realtorWealthCalculations.closings.toFixed(0)}
                    </p>
                  </div>
                  <div className="card">
                    <p className="text-text-muted">Total Appointments</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.realtorWealthCalculations.totalAppointments.toFixed(0)}
                    </p>
                  </div>
                  <div className="card">
                    <p className="text-text-muted">Agency Agreements</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.realtorWealthCalculations.agencyAgreements.toFixed(0)}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="space-y-4">
                <div>
                  <label className="form-label">
                    Annual GCI Goal ($)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.gci}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, gci: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 500000"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Average Commission ($)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.avgCompensation}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, avgCompensation: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 12000"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Appointments to Agreements Rate (%)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.appointmentsToAgreements}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, appointmentsToAgreements: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 50"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Agreements to Closings Rate (%)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.agreementsToClosings}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, agreementsToClosings: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 65"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Working Weeks
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.workWeeks}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, workWeeks: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 52"
                  />
                </div>
              </div>

              {showResults && (
                <div className="pt-4 border-t border-border">
                  <Button
                    variant="accent"
                    onClick={handleSave}
                  >
                    <Save size={18} />
                    Save as Annual Goals
                  </Button>

                  {saveSuccess && (
                    <span className="ml-2 text-green-600 text-sm">
                      Goals saved successfully!
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RealtorWealthCalculator;