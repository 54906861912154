import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../context/AppContext';
import { ArrowLeft } from 'lucide-react';
import WealthCalculator from '../components/WealthCalculator';
import RealtorWealthCalculator from '../components/RealtorWealthCalculator';
import Button from '../components/Button';

const DailyGoals = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useApp();
  const [isEditingYTD, setIsEditingYTD] = useState(false);
  const [goals, setGoals] = useState({
    mloYtdClosed: state.stats.mloYtdAmount?.toString() || '0',
    realtorYtdClosed: state.stats.realtorYtdAmount?.toString() || '0',
  });

  const handleSaveYTD = () => {
    dispatch({
      type: 'UPDATE_STATS',
      payload: {
        mloYtdAmount: parseFloat(goals.mloYtdClosed) || 0,
        realtorYtdAmount: parseFloat(goals.realtorYtdClosed) || 0
      }
    });
    setIsEditingYTD(false);
  };

  const showMloCalculator = state.profile?.selectedRole === 'mlo' || state.profile?.selectedRole === 'dual';
  const showRealtorCalculator = state.profile?.selectedRole === 'realtor' || state.profile?.selectedRole === 'dual';

  return (
    <div className="min-h-screen bg-background">
      <header className="page-header">
        <button onClick={() => navigate('/')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="page-title">Goals</h1>
      </header>

      <div className="container-content pb-20">
        <section className="section">
          <div className="section-header">
            <h2 className="section-title">Current Progress</h2>
            <Button
              variant="text"
              onClick={() => isEditingYTD ? handleSaveYTD() : setIsEditingYTD(true)}
            >
              {isEditingYTD ? 'Save' : 'Edit'}
            </Button>
          </div>

          <div className="form-group">
            {showMloCalculator && (
              <div>
                <label className="form-label">
                  MLO YTD Closed Business ($)
                </label>
                <input
                  type="number"
                  value={goals.mloYtdClosed}
                  onChange={(e) => setGoals(prev => ({ ...prev, mloYtdClosed: e.target.value }))}
                  disabled={!isEditingYTD}
                  className="input-field"
                  placeholder="Enter MLO YTD closed amount"
                />
              </div>
            )}

            {showRealtorCalculator && (
              <div>
                <label className="form-label">
                  Real Estate YTD Closed Business ($)
                </label>
                <input
                  type="number"
                  value={goals.realtorYtdClosed}
                  onChange={(e) => setGoals(prev => ({ ...prev, realtorYtdClosed: e.target.value }))}
                  disabled={!isEditingYTD}
                  className="input-field"
                  placeholder="Enter Real Estate YTD closed amount"
                />
              </div>
            )}
          </div>
        </section>

        {showMloCalculator && (
          <section className="section">
            <WealthCalculator ytdAmount={parseFloat(goals.mloYtdClosed) || 0} />
          </section>
        )}

        {showRealtorCalculator && (
          <section className="section">
            <RealtorWealthCalculator ytdAmount={parseFloat(goals.realtorYtdClosed) || 0} />
          </section>
        )}
      </div>
    </div>
  );
};

export default DailyGoals;