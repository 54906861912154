import React, { useState } from 'react';
import { Upload, Trash2 } from 'lucide-react';
import { useApp } from '../context/AppContext';

const LogoManager: React.FC = () => {
  const { state, dispatch } = useApp();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setError('');
    
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please select an image file');
      return;
    }

    if (file.size > 2 * 1024 * 1024) { // 2MB limit
      setError('Image size should be less than 2MB');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const base64Logo = event.target?.result as string;
      dispatch({
        type: 'UPDATE_PROFILE',
        payload: { appLogo: base64Logo }
      });
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveLogo = () => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: { appLogo: null }
    });
  };

  return (
    <div className="card space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Upload size={20} className="text-text" />
          <h3 className="font-medium text-text">App Logo</h3>
        </div>
      </div>

      {state.profile?.appLogo ? (
        <div className="space-y-4">
          <div className="flex justify-center p-4 bg-secondary dark:bg-background rounded-lg">
            <img 
              src={state.profile.appLogo} 
              alt="App Logo" 
              className="max-h-32 object-contain"
            />
          </div>
          <button
            onClick={handleRemoveLogo}
            className="flex items-center gap-2 text-red-500 hover:text-red-600"
          >
            <Trash2 size={18} />
            Remove Logo
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          <label className="block">
            <div className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-border rounded-lg cursor-pointer hover:border-primary">
              <Upload size={24} className="text-text-muted mb-2" />
              <span className="text-text-muted">Click to upload logo</span>
              <span className="text-sm text-text-muted">(Max 2MB)</span>
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                className="hidden"
              />
            </div>
          </label>
          {error && (
            <p className="text-red-500 text-sm text-center">{error}</p>
          )}
        </div>
      )}

      {saveSuccess && (
        <p className="text-green-500 text-sm text-center">
          Logo updated successfully!
        </p>
      )}
    </div>
  );
};

export default LogoManager;