import React, { useState } from 'react';
import { X } from 'lucide-react';
import Button from './Button';
import type { User } from '../types/base';

interface UserProfileModalProps {
  user: User;
  onClose: () => void;
  onSave: (user: User) => void;
}

const UserProfileModal: React.FC<UserProfileModalProps> = ({
  user,
  onClose,
  onSave
}) => {
  const [formData, setFormData] = useState({
    name: user.name || '',
    email: user.email || '',
    phone: user.phone || '',
    title: user.title || '',
    role: user.role,
    selectedRole: user.selectedRole || '',
    company: {
      name: user.company?.name || '',
      address: user.company?.address || '',
      website: user.company?.website || ''
    },
    license: {
      nmlsId: user.license?.nmlsId || '',
      realtorLicense: user.license?.realtorLicense || ''
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({
      ...user,
      ...formData
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-secondary rounded-lg w-full max-w-2xl">
        <div className="flex justify-between items-center p-4 border-b border-border">
          <h2 className="text-lg font-semibold text-text">Edit Profile</h2>
          <button onClick={onClose} className="text-text-muted hover:text-text">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          {/* Existing form fields */}
          
          <div>
            <label className="form-label">Role</label>
            <select
              value={formData.selectedRole}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                selectedRole: e.target.value
              }))}
              className="input-field"
            >
              <option value="">Select Role</option>
              <option value="mlo">MLO Only</option>
              <option value="realtor">Realtor Only</option>
              <option value="dual">Dual-Licensed</option>
            </select>
          </div>

          <div className="flex justify-end gap-2 pt-4">
            <Button
              variant="secondary"
              onClick={onClose}
              type="button"
            >
              Cancel
            </Button>
            <Button
              variant="accent"
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfileModal;