import React from 'react';
import { Loader2 } from 'lucide-react';

interface LoadingScreenProps {
  message?: string;
  fullScreen?: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ 
  message = 'Loading...',
  fullScreen = false
}) => {
  const containerClasses = fullScreen 
    ? 'fixed inset-0 bg-background/80 backdrop-blur-sm'
    : 'min-h-[400px]';

  return (
    <div className={`${containerClasses} flex items-center justify-center`}>
      <div className="text-center space-y-4">
        <Loader2 size={40} className="animate-spin text-primary mx-auto" />
        <p className="text-text-muted">{message}</p>
      </div>
    </div>
  );
};

export default LoadingScreen;