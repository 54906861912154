import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, UserPlus, Building2, Users, X } from 'lucide-react';
import { useApp } from '../context/AppContext';
import Button from '../components/Button';
import AddTeamMember from '../components/AddTeamMember';
import TeamMemberList from '../components/TeamMemberList';
import ProfileSection from '../components/ProfileSection';
import type { User } from '../types/base';

const TeamDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useApp();
  const [view, setView] = useState<'overview' | 'members' | 'goals' | 'profile'>('overview');
  const [showAddMember, setShowAddMember] = useState(false);
  const [teamMembers, setTeamMembers] = useState<User[]>([]);

  useEffect(() => {
    loadTeamMembers();
  }, [state.auth?.id]);

  const loadTeamMembers = () => {
    const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    const teamUsers = savedUsers.filter((user: User) => 
      user.teamId === state.auth?.id || user.createdBy === state.auth?.id
    );
    setTeamMembers(teamUsers);
  };

  const handleUpdateMember = (userId: string, updates: Partial<User>) => {
    const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    const updatedUsers = savedUsers.map((user: User) =>
      user.id === userId ? { ...user, ...updates } : user
    );
    localStorage.setItem('users', JSON.stringify(updatedUsers));
    
    // Update local state
    const updatedMembers = teamMembers.map(member =>
      member.id === userId ? { ...member, ...updates } : member
    );
    setTeamMembers(updatedMembers);
  };

  const handleResetPassword = (userId: string, newPassword: string) => {
    const passwords = JSON.parse(localStorage.getItem('passwords') || '{}');
    passwords[userId] = newPassword;
    localStorage.setItem('passwords', JSON.stringify(passwords));
  };

  const handleDeleteMember = (userId: string) => {
    const updatedMembers = teamMembers.filter(m => m.id !== userId);
    setTeamMembers(updatedMembers);
    
    // Update localStorage
    const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    const updatedUsers = savedUsers.filter((user: User) => user.id !== userId);
    localStorage.setItem('users', JSON.stringify(updatedUsers));
  };

  // Calculate team stats based on all members' data
  const calculateTeamStats = () => {
    const memberCount = teamMembers.length;
    return {
      totalPoints: state.stats.totalPoints * memberCount,
      totalCalls: Math.floor(state.stats.dailyPoints * 30 * memberCount),
      totalApplications: Math.floor(state.stats.dailyPoints * 0.2 * memberCount),
      totalClosings: Math.floor(state.stats.dailyPoints * 0.1 * memberCount),
      conversionRate: '48%',
      ytdAmount: state.stats.ytdAmount * memberCount,
      goalAmount: state.stats.goalAmount * memberCount,
      pendingAmount: state.stats.pendingAmount * memberCount
    };
  };

  const teamStats = calculateTeamStats();

  const StatCard = ({ 
    label, 
    value 
  }: { 
    label: string; 
    value: number | string;
  }) => (
    <div className="card">
      <div className="text-text-muted text-sm">{label}</div>
      <div className="text-xl font-medium text-text">
        {typeof value === 'number' ? value.toLocaleString() : value}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-background pb-20">
      <header className="page-header">
        <button onClick={() => navigate('/')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="text-xl font-bold text-text">Team Dashboard</h1>
      </header>

      <div className="p-4 flex gap-2 overflow-x-auto">
        {(['overview', 'members', 'goals', 'profile'] as const).map((tab) => (
          <button
            key={tab}
            onClick={() => setView(tab)}
            className={`px-4 py-2 rounded-lg whitespace-nowrap font-medium transition-colors ${
              view === tab
                ? 'bg-primary text-white'
                : 'bg-secondary text-text'
            }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      <div className="p-4">
        {view === 'overview' && (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <StatCard label="Total Members" value={teamMembers.length} />
              <StatCard label="Total Points" value={teamStats.totalPoints} />
              <StatCard label="Total Calls" value={teamStats.totalCalls} />
              <StatCard label="Applications" value={teamStats.totalApplications} />
              <StatCard label="Closings" value={teamStats.totalClosings} />
              <StatCard label="Conversion Rate" value={teamStats.conversionRate} />
              <StatCard 
                label="YTD Amount" 
                value={`$${teamStats.ytdAmount.toLocaleString()}`} 
              />
              <StatCard 
                label="Goal Amount" 
                value={`$${teamStats.goalAmount.toLocaleString()}`} 
              />
            </div>
          </div>
        )}

        {view === 'members' && (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-text">Team Members</h2>
              <Button
                variant="accent"
                onClick={() => setShowAddMember(true)}
              >
                <UserPlus size={18} />
                Add Member
              </Button>
            </div>

            {showAddMember && (
              <AddTeamMember
                onAdd={(newMember) => {
                  const member = {
                    ...newMember,
                    id: `user_${Date.now()}`,
                    teamId: state.auth?.id,
                    createdBy: state.auth?.id,
                    createdAt: new Date().toISOString(),
                    status: 'active'
                  } as User;
                  
                  const updatedMembers = [...teamMembers, member];
                  setTeamMembers(updatedMembers);
                  localStorage.setItem('users', JSON.stringify(updatedMembers));
                  setShowAddMember(false);
                }}
                onClose={() => setShowAddMember(false)}
              />
            )}

            <TeamMemberList
              members={teamMembers}
              onDelete={handleDeleteMember}
              onUpdate={handleUpdateMember}
              onResetPassword={handleResetPassword}
            />
          </div>
        )}

        {view === 'goals' && (
          <div className="space-y-4">
            <div className="card">
              <h3 className="text-lg font-semibold text-text mb-4">Team Goals</h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-text">Annual Team Goal</span>
                  <span className="text-text-muted">
                    ${teamStats.goalAmount.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-text">YTD Progress</span>
                  <span className="text-text-muted">
                    ${teamStats.ytdAmount.toLocaleString()}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-text">Team Size</span>
                  <span className="text-text-muted">{teamMembers.length} members</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {view === 'profile' && (
          <ProfileSection />
        )}
      </div>
    </div>
  );
};

export default TeamDashboard;