import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../context/AppContext';
import { ArrowLeft } from 'lucide-react';
import Button from '../components/Button';
import ProfilePicture from '../components/ProfilePicture';
import type { UserRole } from '../types/base';

const ProfileSetup = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useApp();
  const [profile, setProfile] = useState({
    name: state.profile?.name || '',
    email: state.profile?.email || '',
    phone: state.profile?.phone || '',
    title: state.profile?.title || '',
    selectedRole: state.profile?.selectedRole || '',
    company: {
      name: state.profile?.company?.name || '',
      address: state.profile?.company?.address || '',
      website: state.profile?.company?.website || ''
    },
    license: {
      nmlsId: state.profile?.license?.nmlsId || '',
      realtorLicense: state.profile?.license?.realtorLicense || ''
    }
  });

  const handleSave = () => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: profile
    });
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-background pb-20">
      <header className="page-header">
        <button onClick={() => navigate('/')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="text-xl font-bold text-text">Profile Setup</h1>
      </header>

      <div className="container-content space-y-6">
        <div className="flex flex-col items-center gap-4">
          <ProfilePicture
            value={state.profile?.profilePicture || ''}
            onChange={(value) => dispatch({
              type: 'UPDATE_PROFILE',
              payload: { profilePicture: value }
            })}
            editable={true}
            size="lg"
          />
        </div>

        <div className="card space-y-6">
          <div>
            <h2 className="text-lg font-semibold text-text mb-4">Personal Information</h2>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="form-label">Full Name</label>
                  <input
                    type="text"
                    value={profile.name}
                    onChange={(e) => setProfile(prev => ({ ...prev, name: e.target.value }))}
                    className="input-field"
                    placeholder="Enter your full name"
                  />
                </div>
                <div>
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    value={profile.title}
                    onChange={(e) => setProfile(prev => ({ ...prev, title: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., Senior MLO & Realtor"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    value={profile.email}
                    onChange={(e) => setProfile(prev => ({ ...prev, email: e.target.value }))}
                    className="input-field"
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <label className="form-label">Phone</label>
                  <input
                    type="tel"
                    value={profile.phone}
                    onChange={(e) => setProfile(prev => ({ ...prev, phone: e.target.value }))}
                    className="input-field"
                    placeholder="Enter your phone number"
                  />
                </div>
              </div>

              <div>
                <label className="form-label">Role</label>
                <select
                  value={profile.selectedRole}
                  onChange={(e) => setProfile(prev => ({
                    ...prev,
                    selectedRole: e.target.value as UserRole
                  }))}
                  className="input-field"
                >
                  <option value="">Select Role</option>
                  <option value="mlo">MLO Only</option>
                  <option value="realtor">Realtor Only</option>
                  <option value="dual">Dual-Licensed</option>
                </select>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-text mb-4">Company Information</h2>
            <div className="space-y-4">
              <div>
                <label className="form-label">Company Name</label>
                <input
                  type="text"
                  value={profile.company.name}
                  onChange={(e) => setProfile(prev => ({
                    ...prev,
                    company: { ...prev.company, name: e.target.value }
                  }))}
                  className="input-field"
                  placeholder="Enter company name"
                />
              </div>

              <div>
                <label className="form-label">Company Address</label>
                <input
                  type="text"
                  value={profile.company.address}
                  onChange={(e) => setProfile(prev => ({
                    ...prev,
                    company: { ...prev.company, address: e.target.value }
                  }))}
                  className="input-field"
                  placeholder="Enter company address"
                />
              </div>

              <div>
                <label className="form-label">Company Website</label>
                <input
                  type="url"
                  value={profile.company.website}
                  onChange={(e) => setProfile(prev => ({
                    ...prev,
                    company: { ...prev.company, website: e.target.value }
                  }))}
                  className="input-field"
                  placeholder="Enter company website"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-text mb-4">License Information</h2>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="form-label">NMLS ID</label>
                  <input
                    type="text"
                    value={profile.license.nmlsId}
                    onChange={(e) => setProfile(prev => ({
                      ...prev,
                      license: { ...prev.license, nmlsId: e.target.value }
                    }))}
                    className="input-field"
                    placeholder="Enter NMLS ID"
                  />
                </div>
                <div>
                  <label className="form-label">Realtor License</label>
                  <input
                    type="text"
                    value={profile.license.realtorLicense}
                    onChange={(e) => setProfile(prev => ({
                      ...prev,
                      license: { ...prev.license, realtorLicense: e.target.value }
                    }))}
                    className="input-field"
                    placeholder="Enter Realtor license"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Button
            variant="accent"
            onClick={handleSave}
          >
            Save Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetup;