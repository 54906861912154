import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { shouldUseWhiteText } from '../utils/colorUtils';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'accent' | 'text';
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({ 
  children, 
  variant = 'primary', 
  size = 'md',
  fullWidth = false,
  className = '',
  ...props 
}) => {
  const { theme } = useTheme();
  
  const baseClasses = `
    ${fullWidth ? 'w-full' : ''}
    inline-flex items-center justify-center gap-2 
    rounded-lg font-medium transition-colors
    disabled:opacity-50 disabled:cursor-not-allowed
    focus:outline-none focus:ring-2 focus:ring-offset-2
  `;
  
  const sizeClasses = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2',
    lg: 'px-6 py-3 text-lg'
  }[size];

  const getVariantClasses = () => {
    const colors = theme.colors;
    switch (variant) {
      case 'primary':
        return `
          bg-primary hover:bg-primary/90 focus:ring-primary/50
          ${shouldUseWhiteText(colors.primary) ? 'text-white' : 'text-text'}
        `;
      case 'secondary':
        return `
          bg-secondary hover:bg-secondary/90 focus:ring-secondary/50
          ${shouldUseWhiteText(colors.secondary) ? 'text-white' : 'text-text'}
        `;
      case 'accent':
        return `
          bg-accent hover:bg-accent/90 focus:ring-accent/50
          ${shouldUseWhiteText(colors.accent) ? 'text-white' : 'text-text'}
        `;
      case 'text':
        return 'text-text-muted hover:text-text focus:ring-text/50';
      default:
        return '';
    }
  };

  return (
    <button 
      className={`${baseClasses} ${sizeClasses} ${getVariantClasses()} ${className}`.trim()}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;