import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Book, Save, Mic, RefreshCw } from 'lucide-react';
import { useApp } from '../context/AppContext';
import { openAIService } from '../services/openai';
import Button from '../components/Button';
import MoodTracker from '../components/Journal/MoodTracker';
import JournalPrompt from '../components/Journal/JournalPrompt';
import VoiceRecorder from '../components/Journal/VoiceRecorder';
import VideoEmbed from '../components/Journal/VideoEmbed';
import JournalInsights from '../components/JournalInsights';
import LoadingSpinner from '../components/LoadingSpinner';

interface JournalEntry {
  id: string;
  date: string;
  mood: string;
  gratitude: string;
  wins: string;
  focus: string;
  affirmations: string;
  analysis?: {
    patterns: string[];
    suggestions: string[];
    progress: string[];
  };
  completedVideo?: {
    videoId: string;
    watchedAt: string;
    savedForLater?: boolean;
    isFavorite?: boolean;
  };
}

const Journal: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useApp();
  const [entry, setEntry] = useState<Omit<JournalEntry, 'id' | 'date'>>({
    mood: '😊',
    gratitude: '',
    wins: '',
    focus: '',
    affirmations: ''
  });
  const [isRecording, setIsRecording] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    generatePrompt();
  }, [entry.mood]);

  const generatePrompt = async () => {
    try {
      const prompt = await openAIService.generateJournalPrompt({
        role: state.profile?.selectedRole,
        previousEntries: state.profile?.journalEntries?.slice(0, 5)
      });
      setCurrentPrompt(prompt);
    } catch (error) {
      console.error('Failed to generate prompt:', error);
      setCurrentPrompt('What are you grateful for today?');
    }
  };

  const analyzeEntry = async (entryData: JournalEntry) => {
    setIsAnalyzing(true);
    try {
      const analysis = await openAIService.analyzeJournalEntry(entryData);
      return analysis;
    } catch (error) {
      console.error('Failed to analyze entry:', error);
      return null;
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleSave = async () => {
    if (!entry.gratitude && !entry.wins && !entry.focus && !entry.affirmations) {
      return;
    }

    setIsSaving(true);
    try {
      const newEntry: JournalEntry = {
        id: Date.now().toString(),
        date: new Date().toISOString(),
        ...entry
      };

      // Generate AI analysis if OpenAI is configured
      if (openAIService.checkApiKey()) {
        const analysis = await analyzeEntry(newEntry);
        if (analysis) {
          newEntry.analysis = analysis;
        }
      }

      dispatch({
        type: 'ADD_JOURNAL_ENTRY',
        payload: newEntry
      });

      // Show video if available
      if (state.todayVideo) {
        setShowVideo(true);
      } else {
        navigate('/journal/entries');
      }
    } catch (error) {
      console.error('Failed to save journal entry:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleVideoComplete = () => {
    setShowVideo(false);
    navigate('/journal/entries');
  };

  return (
    <div className="min-h-screen bg-background">
      <header className="page-header">
        <div className="flex items-center gap-2">
          <Book size={24} className="text-text" />
          <h1 className="text-xl font-bold text-text">Daily Journal</h1>
        </div>
      </header>

      <div className="container-content pb-24 space-y-6">
        <MoodTracker
          value={entry.mood}
          onChange={(mood) => setEntry(prev => ({ ...prev, mood }))}
        />

        <JournalPrompt
          prompt={currentPrompt}
          onRefresh={generatePrompt}
        />

        <div className="space-y-6">
          <div>
            <label className="form-label">Gratitude</label>
            <div className="relative">
              <textarea
                value={entry.gratitude}
                onChange={(e) => setEntry(prev => ({ ...prev, gratitude: e.target.value }))}
                className="input-field min-h-[120px]"
                placeholder="What are you grateful for today?"
              />
              <Button
                variant="text"
                onClick={() => setIsRecording(true)}
                className="absolute top-2 right-2"
              >
                <Mic size={16} />
              </Button>
            </div>
          </div>

          <div>
            <label className="form-label">Wins</label>
            <textarea
              value={entry.wins}
              onChange={(e) => setEntry(prev => ({ ...prev, wins: e.target.value }))}
              className="input-field min-h-[120px]"
              placeholder="What wins did you achieve today?"
            />
          </div>

          <div>
            <label className="form-label">Focus</label>
            <textarea
              value={entry.focus}
              onChange={(e) => setEntry(prev => ({ ...prev, focus: e.target.value }))}
              className="input-field min-h-[120px]"
              placeholder="What's your main focus for tomorrow?"
            />
          </div>

          <div>
            <label className="form-label">Affirmations</label>
            <textarea
              value={entry.affirmations}
              onChange={(e) => setEntry(prev => ({ ...prev, affirmations: e.target.value }))}
              className="input-field min-h-[120px]"
              placeholder="What are your affirmations for today?"
            />
          </div>
        </div>

        {isAnalyzing && (
          <div className="card p-4">
            <div className="flex items-center justify-center gap-2 text-text">
              <LoadingSpinner size={20} />
              <span>Analyzing your entry...</span>
            </div>
          </div>
        )}

        {showVideo && state.todayVideo ? (
          <div className="card">
            <VideoEmbed
              video={state.todayVideo}
              onComplete={handleVideoComplete}
            />
          </div>
        ) : (
          <div className="fixed bottom-20 left-0 right-0 p-4 bg-background border-t border-border">
            <div className="container-content">
              <Button
                variant="accent"
                onClick={handleSave}
                disabled={isSaving || (!entry.gratitude && !entry.wins && !entry.focus && !entry.affirmations)}
                fullWidth
              >
                {isSaving ? (
                  <>
                    <LoadingSpinner size={20} />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save size={20} />
                    Save Entry
                  </>
                )}
              </Button>
            </div>
          </div>
        )}
      </div>

      {isRecording && (
        <VoiceRecorder
          onTranscription={(text) => {
            setEntry(prev => ({
              ...prev,
              gratitude: prev.gratitude + ' ' + text
            }));
            setIsRecording(false);
          }}
          onError={() => setIsRecording(false)}
        />
      )}
    </div>
  );
};

export default Journal;