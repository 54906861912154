import { v4 as uuidv4 } from 'uuid';
import type { User } from '../types/base';

interface AuthResponse {
  user: User;
  token: string;
}

class AuthService {
  private readonly STORAGE_PREFIX = 'topproducer_';
  private readonly ADMIN_EMAIL = 'admin@topproducerapp.com';
  private readonly ADMIN_PASSWORD = 'YourSecurePassword!2024';
  private readonly TOKEN_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

  constructor() {
    this.initializeTestAccounts();
  }

  private initializeTestAccounts(): void {
    const users = this.getUsers();
    
    // Always ensure test accounts exist
    const testAccounts = [
      {
        id: 'admin_default',
        email: this.ADMIN_EMAIL,
        name: 'Admin User',
        password: this.ADMIN_PASSWORD,
        role: 'admin',
        title: 'System Administrator'
      },
      {
        id: 'user_everett_1',
        email: 'Everett@HomeLoanHaus.com',
        name: 'Everett Trujillo',
        password: '@pringo9Now',
        role: 'dual_agent',
        title: 'MLO & Realtor'
      }
    ];

    // Add or update test accounts
    testAccounts.forEach(account => {
      const existingUser = users.find(u => u.id === account.id || u.email.toLowerCase() === account.email.toLowerCase());
      
      if (!existingUser) {
        // Add new user
        const user: User = {
          id: account.id,
          email: account.email,
          name: account.name,
          role: account.role,
          createdAt: new Date().toISOString(),
          status: 'active',
          title: account.title
        };
        users.push(user);
        this.savePassword(account.id, account.password);
      } else {
        // Update existing user
        Object.assign(existingUser, {
          email: account.email,
          name: account.name,
          role: account.role,
          title: account.title,
          status: 'active'
        });
        this.savePassword(existingUser.id, account.password);
      }
    });

    this.saveUsers(users);
  }

  private getUsers(): User[] {
    const data = localStorage.getItem(`${this.STORAGE_PREFIX}users`);
    return data ? JSON.parse(data) : [];
  }

  private saveUsers(users: User[]): void {
    localStorage.setItem(`${this.STORAGE_PREFIX}users`, JSON.stringify(users));
  }

  private getPassword(userId: string): string | null {
    return localStorage.getItem(`${this.STORAGE_PREFIX}pwd_${userId}`);
  }

  private savePassword(userId: string, password: string): void {
    localStorage.setItem(`${this.STORAGE_PREFIX}pwd_${userId}`, password);
  }

  async login(email: string, password: string): Promise<AuthResponse> {
    // Force initialization of test accounts
    this.initializeTestAccounts();
    
    const users = this.getUsers();
    const user = users.find(u => u.email.toLowerCase() === email.toLowerCase());
    
    if (!user) {
      throw new Error('Invalid credentials');
    }

    const storedPassword = this.getPassword(user.id);
    if (!storedPassword || storedPassword !== password) {
      throw new Error('Invalid credentials');
    }

    const token = uuidv4();
    localStorage.setItem(`${this.STORAGE_PREFIX}token_${token}`, user.id);
    
    return { user, token };
  }

  async verifyToken(token: string): Promise<User> {
    const userId = localStorage.getItem(`${this.STORAGE_PREFIX}token_${token}`);
    if (!userId) {
      throw new Error('Invalid token');
    }

    const users = this.getUsers();
    const user = users.find(u => u.id === userId);
    if (!user) {
      throw new Error('Invalid token');
    }

    return user;
  }

  async impersonateUser(userId: string): Promise<User> {
    const users = this.getUsers();
    const user = users.find(u => u.id === userId);
    if (!user) {
      throw new Error('User not found');
    }
    return user;
  }

  async updateUserCredentials(userId: string, credentials: { 
    email: string; 
    password: string;
  }): Promise<void> {
    const users = this.getUsers();
    const userIndex = users.findIndex(u => u.id === userId);
    
    if (userIndex === -1) {
      throw new Error('User not found');
    }

    // Check if email is already taken by another user
    const emailExists = users.some(u => 
      u.id !== userId && u.email.toLowerCase() === credentials.email.toLowerCase()
    );
    if (emailExists) {
      throw new Error('Email already in use');
    }

    // Update email
    users[userIndex].email = credentials.email;
    this.saveUsers(users);

    // Update password
    this.savePassword(userId, credentials.password);
  }

  logout(token: string): void {
    localStorage.removeItem(`${this.STORAGE_PREFIX}token_${token}`);
  }
}

export const auth = new AuthService();