import React from 'react';
import { RefreshCw } from 'lucide-react';
import Button from '../Button';

interface JournalPromptProps {
  prompt: string;
  onRefresh: () => void;
}

const JournalPrompt: React.FC<JournalPromptProps> = ({ prompt, onRefresh }) => {
  return (
    <div className="bg-white dark:bg-secondary rounded-lg p-4">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-sm font-medium text-text">Today's Prompt</h3>
        <Button
          variant="text"
          onClick={onRefresh}
          size="sm"
        >
          <RefreshCw size={16} />
          New Prompt
        </Button>
      </div>
      <p className="text-text-muted">{prompt}</p>
    </div>
  );
};

export default JournalPrompt;