import React from 'react';
import { LucideIcon } from 'lucide-react';

interface SectionHeaderProps {
  icon: LucideIcon;
  title: string;
  action?: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ icon: Icon, title, action }) => {
  return (
    <div className="flex items-center justify-between mb-6">
      <div className="flex items-center gap-2">
        <Icon size={20} className="text-text" />
        <h2 className="text-lg font-semibold text-text">{title}</h2>
      </div>
      {action}
    </div>
  );
};

export default SectionHeader;