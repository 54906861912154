const secureStorage = {
  setItem: (key: string, value: string): void => {
    try {
      // Use sessionStorage for better security in production
      const storage = process.env.NODE_ENV === 'production' ? sessionStorage : localStorage;
      storage.setItem(key, value);
    } catch (error) {
      console.error('Error storing secure item:', error);
    }
  },

  getItem: (key: string): string | null => {
    try {
      const storage = process.env.NODE_ENV === 'production' ? sessionStorage : localStorage;
      return storage.getItem(key);
    } catch (error) {
      console.error('Error retrieving secure item:', error);
      return null;
    }
  },

  removeItem: (key: string): void => {
    try {
      const storage = process.env.NODE_ENV === 'production' ? sessionStorage : localStorage;
      storage.removeItem(key);
    } catch (error) {
      console.error('Error removing secure item:', error);
    }
  }
};

export { secureStorage };