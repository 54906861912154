import React from 'react';
import { Check } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import type { ThemeColors } from '../types/theme';

const colorPalettes: Record<string, { colors: ThemeColors; category: 'standard' | 'luxury' }> = {
  default: {
    colors: {
      primary: '#230d91',
      secondary: '#e0ddf4',
      background: '#f1f0fa',
      text: '#0e0a1f',
      muted: '#4b38a8',
      border: '#ccc6ec',
      accent: '#F2C70D'
    },
    category: 'standard'
  },
  ocean: {
    colors: {
      primary: '#0077b6',
      secondary: '#caf0f8',
      background: '#f0f7f9',
      text: '#03045e',
      muted: '#0096c7',
      border: '#90e0ef',
      accent: '#00b4d8'
    },
    category: 'standard'
  },
  forest: {
    colors: {
      primary: '#2d6a4f',
      secondary: '#d8f3dc',
      background: '#f1f8f2',
      text: '#081c15',
      muted: '#40916c',
      border: '#b7e4c7',
      accent: '#52b788'
    },
    category: 'standard'
  },
  sunset: {
    colors: {
      primary: '#c9184a',
      secondary: '#ffd7e8',
      background: '#fff0f3',
      text: '#590d22',
      muted: '#ff4d6d',
      border: '#ffb3c1',
      accent: '#ff758f'
    },
    category: 'standard'
  },
  lavender: {
    colors: {
      primary: '#7209b7',
      secondary: '#f3d9fa',
      background: '#f9f1fc',
      text: '#3c096c',
      muted: '#9b5de5',
      border: '#e0aaff',
      accent: '#c77dff'
    },
    category: 'standard'
  },
  'royal-gold': {
    colors: {
      primary: '#A4863D',
      secondary: '#E6E6FA',
      background: '#FFFFFF',
      text: '#333333',
      muted: '#7E7053',
      border: '#D6D7D2',
      accent: '#0E300E'
    },
    category: 'luxury'
  },
  'elegant-earth': {
    colors: {
      primary: '#7E7053',
      secondary: '#A9A18C',
      background: '#D6D7D2',
      text: '#4D3E27',
      muted: '#9A9B94',
      border: '#B8B2A3',
      accent: '#8B7355'
    },
    category: 'luxury'
  },
  'vintage-wine': {
    colors: {
      primary: '#8B0000',
      secondary: '#EDE7C7',
      background: '#FFFFFF',
      text: '#200E01',
      muted: '#5B0202',
      border: '#D4C5B9',
      accent: '#3B0404'
    },
    category: 'luxury'
  },
  'midnight-plum': {
    colors: {
      primary: '#2D0E3B',
      secondary: '#554C5B',
      background: '#EBE6DE',
      text: '#1D1D1D',
      muted: '#B76E79',
      border: '#D6D7D2',
      accent: '#4A3B52'
    },
    category: 'luxury'
  },
  'emerald-gold': {
    colors: {
      primary: '#002825',
      secondary: '#FFFFFF',
      background: '#FFFFFF',
      text: '#000000',
      muted: '#94A6A4',
      border: '#E5E5E5',
      accent: '#B59233'
    },
    category: 'luxury'
  },
  'sapphire-silk': {
    colors: {
      primary: '#1B365D',
      secondary: '#F5F5F5',
      background: '#FFFFFF',
      text: '#1A1A1A',
      muted: '#7D8597',
      border: '#E0E0E0',
      accent: '#C5B358'
    },
    category: 'luxury'
  },
  'pearl-essence': {
    colors: {
      primary: '#8E7F7F',
      secondary: '#F8F4F4',
      background: '#FFFFFF',
      text: '#2C2C2C',
      muted: '#B5A8A8',
      border: '#E8E4E4',
      accent: '#D4AF37'
    },
    category: 'luxury'
  },
  'onyx-gold': {
    colors: {
      primary: '#111111',
      secondary: '#F7F7F7',
      background: '#FFFFFF',
      text: '#000000',
      muted: '#666666',
      border: '#DDDDDD',
      accent: '#D4AF37'
    },
    category: 'luxury'
  },
  'mahogany-brass': {
    colors: {
      primary: '#4A0404',
      secondary: '#F0EAE3',
      background: '#FFFFFF',
      text: '#2B1810',
      muted: '#8B4513',
      border: '#DEB887',
      accent: '#B8860B'
    },
    category: 'luxury'
  },
  'platinum-silk': {
    colors: {
      primary: '#333333',
      secondary: '#F5F5F5',
      background: '#FFFFFF',
      text: '#1A1A1A',
      muted: '#808080',
      border: '#E6E6E6',
      accent: '#C0C0C0'
    },
    category: 'luxury'
  }
};

const ColorPalette: React.FC = () => {
  const { theme, updateTheme } = useTheme();

  const handleSelectPalette = (paletteName: string) => {
    updateTheme({
      ...theme,
      colors: colorPalettes[paletteName].colors
    });
  };

  const isCurrentPalette = (palette: ThemeColors) => {
    return Object.entries(palette).every(
      ([key, value]) => theme.colors[key as keyof ThemeColors] === value
    );
  };

  const renderPaletteSection = (category: 'standard' | 'luxury') => {
    const palettes = Object.entries(colorPalettes).filter(([_, p]) => p.category === category);
    
    return (
      <div className="space-y-3">
        <h4 className="font-medium text-text capitalize">{category} Palettes</h4>
        {palettes.map(([name, { colors }]) => (
          <button
            key={name}
            onClick={() => handleSelectPalette(name)}
            className="w-full flex items-center gap-3 p-3 rounded-lg border border-border hover:border-primary transition-colors"
          >
            <div className="flex gap-2">
              {Object.values(colors).map((color, index) => (
                <div
                  key={index}
                  className="w-6 h-6 rounded-full"
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
            <span className="flex-1 text-left capitalize text-text">
              {name.replace(/-/g, ' ')}
            </span>
            {isCurrentPalette(colors) && (
              <Check size={20} className="text-primary" />
            )}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold text-text">Color Palettes</h3>
      {renderPaletteSection('standard')}
      {renderPaletteSection('luxury')}
    </div>
  );
};

export default ColorPalette;