import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, UserPlus, Building2, Users, Palette, 
  Mail, Image, Bot, ListChecks, Layout, Video 
} from 'lucide-react';
import { useApp } from '../context/AppContext';
import { useTheme } from '../context/ThemeContext';
import ThemeEditor from '../components/ThemeEditor';
import LogoManager from '../components/LogoManager';
import FaviconManager from '../components/admin/FaviconManager';
import EmailSetup from '../components/EmailDashboard/EmailSetup';
import AiSettings from '../components/AiSettings';
import UserManager from '../components/UserManager';
import BranchManager from '../components/BranchManager';
import TaskManager from '../components/TaskManager';
import VideoManager from '../components/admin/VideoManager';
import Button from '../components/Button';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { state } = useApp();
  const { theme } = useTheme();
  const [view, setView] = useState<'overview' | 'users' | 'branches' | 'theme' | 'email' | 'logo' | 'favicon' | 'ai' | 'tasks' | 'videos'>('overview');

  const handleEmailClick = () => {
    navigate('/email');
  };

  const tabs = [
    { id: 'overview', label: 'Overview', icon: Users },
    { id: 'users', label: 'Users', icon: Users },
    { id: 'branches', label: 'Branches', icon: Building2 },
    { id: 'tasks', label: 'Tasks', icon: ListChecks },
    { id: 'videos', label: 'Videos', icon: Video },
    { id: 'ai', label: 'AI Settings', icon: Bot },
    { id: 'theme', label: 'Theme', icon: Palette },
    { id: 'email', label: 'Email', icon: Mail },
    { id: 'logo', label: 'Logo', icon: Image },
    { id: 'favicon', label: 'Favicon', icon: Image }
  ];

  const renderContent = () => {
    switch (view) {
      case 'overview':
        return (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="card">
                <p className="text-text-muted">Total Teams</p>
                <p className="text-2xl font-bold text-text">
                  {state.stats.totalTeams || 0}
                </p>
              </div>
              <div className="card">
                <p className="text-text-muted">Total Users</p>
                <p className="text-2xl font-bold text-text">
                  {state.stats.totalUsers || 0}
                </p>
              </div>
              <div className="card">
                <p className="text-text-muted">Total Points</p>
                <p className="text-2xl font-bold text-text">
                  {state.stats.totalPoints || 0}
                </p>
              </div>
              <div className="card">
                <p className="text-text-muted">Avg. Conversion</p>
                <p className="text-2xl font-bold text-text">52%</p>
              </div>
            </div>
          </div>
        );
      case 'users':
        return <UserManager />;
      case 'branches':
        return <BranchManager />;
      case 'tasks':
        return <TaskManager />;
      case 'videos':
        return <VideoManager />;
      case 'ai':
        return <AiSettings />;
      case 'theme':
        return <ThemeEditor />;
      case 'logo':
        return <LogoManager />;
      case 'favicon':
        return <FaviconManager />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen pb-20">
      <header className="p-4 flex items-center gap-4 border-b border-border">
        <button onClick={() => navigate('/')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="text-xl font-bold text-text">Admin Dashboard</h1>
        <button
          onClick={() => navigate('/create-account')}
          className="ml-auto flex items-center gap-2 bg-primary text-white px-4 py-2 rounded-lg"
        >
          <UserPlus size={18} />
          Add User
        </button>
      </header>

      <div className="p-4 flex gap-2 overflow-x-auto">
        {tabs.map(tab => (
          <Button
            key={tab.id}
            variant={view === tab.id ? 'accent' : 'secondary'}
            onClick={() => tab.id === 'email' ? handleEmailClick() : setView(tab.id as typeof view)}
            className="whitespace-nowrap"
          >
            <tab.icon size={18} />
            {tab.label}
          </Button>
        ))}
      </div>

      <div className="p-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default AdminDashboard;