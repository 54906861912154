import React, { useState } from 'react';
import { Plus, Save, Trash2 } from 'lucide-react';
import { useApp } from '../context/AppContext';
import Button from './Button';
import type { Task, UserRole } from '../types/base';

interface TaskEditorProps {
  task: Task;
  onUpdate: (updatedTask: Task) => void;
  onDelete: (taskId: number) => void;
}

const TaskEditor: React.FC<TaskEditorProps> = ({ task, onUpdate, onDelete }) => {
  const [editing, setEditing] = useState(false);
  const [editedTask, setEditedTask] = useState(task);

  const handleSave = () => {
    onUpdate(editedTask);
    setEditing(false);
  };

  const roleOptions: UserRole[] = ['mlo', 'realtor', 'dual'];

  if (!editing) {
    return (
      <div className="card p-4 flex items-center justify-between">
        <div>
          <h3 className="font-medium text-text">{task.name}</h3>
          <p className="text-sm text-text-muted">
            {task.points} points | Max: {task.max === Infinity ? '∞' : task.max}
          </p>
        </div>
        <div className="flex gap-2">
          <Button variant="secondary" onClick={() => setEditing(true)}>
            Edit
          </Button>
          <Button variant="secondary" onClick={() => onDelete(task.id)}>
            <Trash2 size={18} className="text-red-500" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="card p-4 space-y-4">
      <div>
        <label className="form-label">Task Name</label>
        <input
          type="text"
          value={editedTask.name}
          onChange={(e) => setEditedTask(prev => ({ ...prev, name: e.target.value }))}
          className="input-field"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="form-label">Points</label>
          <input
            type="number"
            value={editedTask.points}
            onChange={(e) => setEditedTask(prev => ({ ...prev, points: Number(e.target.value) }))}
            className="input-field"
            min="0"
          />
        </div>
        <div>
          <label className="form-label">Max Per Day</label>
          <input
            type="number"
            value={editedTask.max === Infinity ? '' : editedTask.max}
            onChange={(e) => setEditedTask(prev => ({ 
              ...prev, 
              max: e.target.value === '' ? Infinity : Number(e.target.value)
            }))}
            className="input-field"
            min="0"
            placeholder="∞"
          />
        </div>
      </div>

      <div>
        <label className="form-label">Available Roles</label>
        <div className="flex gap-2">
          {roleOptions.map(role => (
            <label key={role} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={editedTask.role.includes(role)}
                onChange={(e) => {
                  setEditedTask(prev => ({
                    ...prev,
                    role: e.target.checked
                      ? [...prev.role, role]
                      : prev.role.filter(r => r !== role)
                  }));
                }}
                className="form-checkbox h-4 w-4 text-primary rounded border-border"
              />
              <span className="text-sm text-text capitalize">{role}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-end gap-2">
        <Button variant="secondary" onClick={() => setEditing(false)}>
          Cancel
        </Button>
        <Button variant="accent" onClick={handleSave}>
          <Save size={18} />
          Save Changes
        </Button>
      </div>
    </div>
  );
};

const TaskManager: React.FC = () => {
  const { state, dispatch } = useApp();
  const [newCategory, setNewCategory] = useState('');

  const handleUpdateTask = (updatedTask: Task) => {
    dispatch({
      type: 'UPDATE_TASK',
      payload: updatedTask
    });
  };

  const handleDeleteTask = (taskId: number) => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      dispatch({
        type: 'DELETE_TASK',
        payload: taskId
      });
    }
  };

  const handleAddTask = (category: string) => {
    const newTask: Task = {
      id: Date.now(),
      name: 'New Task',
      points: 1,
      max: Infinity,
      role: ['mlo', 'realtor', 'dual'],
      category,
      type: 'business'
    };

    dispatch({
      type: 'ADD_TASK',
      payload: newTask
    });
  };

  const handleAddCategory = () => {
    if (newCategory.trim()) {
      handleAddTask(newCategory.trim());
      setNewCategory('');
    }
  };

  const categories = Array.from(new Set(state.tasks.map(task => task.category)));

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text">Task Management</h2>
        <div className="flex gap-2">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="New category name"
            className="input-field"
          />
          <Button variant="accent" onClick={handleAddCategory}>
            <Plus size={18} />
            Add Category
          </Button>
        </div>
      </div>

      <div className="space-y-6">
        {categories.map(category => (
          <div key={`category-${category}`} className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-md font-medium text-text">{category}</h3>
              <Button variant="secondary" onClick={() => handleAddTask(category)}>
                <Plus size={18} />
                Add Task
              </Button>
            </div>

            <div className="space-y-2">
              {state.tasks
                .filter(task => task.category === category)
                .map(task => (
                  <TaskEditor
                    key={`task-${task.id}`}
                    task={task}
                    onUpdate={handleUpdateTask}
                    onDelete={handleDeleteTask}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskManager;