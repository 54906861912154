import { secureStorage } from '../../utils/secureStorage';
import type { EmailConfig } from '../../types/email';

const EMAIL_CONFIG_KEY = 'email_config';

export class EmailConfigManager {
  validateConfig(config: EmailConfig): void {
    // API Key validation
    if (!config.apiKey) {
      throw new Error('API key is required');
    }
    if (!config.apiKey.startsWith('key-')) {
      throw new Error('Invalid API key format - must start with "key-"');
    }
    if (config.apiKey.length < 50) {
      throw new Error('Invalid API key length - key appears too short');
    }

    // Domain validation
    if (!config.domain) {
      throw new Error('Domain is required');
    }
    const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    if (!domainRegex.test(config.domain)) {
      throw new Error('Invalid domain format - must be a valid domain name');
    }

    // Email validation
    if (!config.fromEmail) {
      throw new Error('From email is required');
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(config.fromEmail)) {
      throw new Error('Invalid from email format');
    }

    // From name validation
    if (!config.fromName) {
      throw new Error('From name is required');
    }
    if (config.fromName.length < 2) {
      throw new Error('From name must be at least 2 characters long');
    }

    // Verify from email domain matches configured domain
    const emailDomain = config.fromEmail.split('@')[1];
    if (emailDomain !== config.domain) {
      throw new Error('From email must use the configured domain');
    }
  }

  async saveConfig(config: EmailConfig): Promise<void> {
    try {
      // Validate configuration
      this.validateConfig(config);

      // Save to secure storage
      secureStorage.setItem(EMAIL_CONFIG_KEY, JSON.stringify({
        ...config,
        updatedAt: new Date().toISOString()
      }));
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to save config: ${error.message}`);
      }
      throw new Error('Failed to save config');
    }
  }

  getConfig(): EmailConfig | null {
    try {
      const config = secureStorage.getItem(EMAIL_CONFIG_KEY);
      return config ? JSON.parse(config) : null;
    } catch (error) {
      console.error('Failed to get config:', error);
      return null;
    }
  }

  clearConfig(): void {
    secureStorage.removeItem(EMAIL_CONFIG_KEY);
  }
}