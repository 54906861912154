import React, { useState } from 'react';
import { X, Eye, EyeOff, AlertCircle } from 'lucide-react';
import Button from './Button';
import { auth } from '../services/auth';
import type { User } from '../types/base';

interface UserCredentialsModalProps {
  user: User;
  onClose: () => void;
  onUpdate: () => void;
}

const UserCredentialsModal: React.FC<UserCredentialsModalProps> = ({
  user,
  onClose,
  onUpdate
}) => {
  const [credentials, setCredentials] = useState({
    email: user.email,
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = (): boolean => {
    if (!credentials.email || !credentials.password) {
      setError('Email and password are required');
      return false;
    }

    if (credentials.password !== credentials.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }

    if (credentials.password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setIsLoading(true);
    setError('');

    try {
      await auth.updateUserCredentials(user.id, {
        email: credentials.email,
        password: credentials.password
      });
      onUpdate();
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update credentials');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-secondary rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b border-border">
          <h2 className="text-lg font-semibold text-text">
            Update User Credentials
          </h2>
          <button
            onClick={onClose}
            className="text-text-muted hover:text-text"
          >
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className="form-label">Email</label>
            <input
              type="email"
              value={credentials.email}
              onChange={(e) => setCredentials(prev => ({
                ...prev,
                email: e.target.value
              }))}
              className="input-field"
              placeholder="Enter email address"
            />
          </div>

          <div>
            <label className="form-label">New Password</label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                value={credentials.password}
                onChange={(e) => setCredentials(prev => ({
                  ...prev,
                  password: e.target.value
                }))}
                className="input-field pr-10"
                placeholder="Enter new password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-text-muted hover:text-text"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>

          <div>
            <label className="form-label">Confirm Password</label>
            <input
              type="password"
              value={credentials.confirmPassword}
              onChange={(e) => setCredentials(prev => ({
                ...prev,
                confirmPassword: e.target.value
              }))}
              className="input-field"
              placeholder="Confirm new password"
            />
          </div>

          {error && (
            <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
              <AlertCircle size={16} />
              <p className="text-sm">{error}</p>
            </div>
          )}

          <div className="flex justify-end gap-2 pt-4">
            <Button
              variant="secondary"
              onClick={onClose}
              type="button"
            >
              Cancel
            </Button>
            <Button
              variant="accent"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Update Credentials'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserCredentialsModal;