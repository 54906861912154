import { secureStorage } from '../../utils/secureStorage';
import type { EmailLog } from '../../types/email';

const EMAIL_LOGS_KEY = 'email_logs';
const MAX_LOGS = 100;

export class EmailLogger {
  private getLogs(): EmailLog[] {
    try {
      const logs = secureStorage.getItem(EMAIL_LOGS_KEY);
      return logs ? JSON.parse(logs) : [];
    } catch (error) {
      console.error('Failed to get logs:', error);
      return [];
    }
  }

  private saveLogs(logs: EmailLog[]): void {
    try {
      secureStorage.setItem(EMAIL_LOGS_KEY, JSON.stringify(logs.slice(0, MAX_LOGS)));
    } catch (error) {
      console.error('Failed to save logs:', error);
    }
  }

  logEmail(log: EmailLog): void {
    const logs = this.getLogs();
    logs.unshift(log);
    this.saveLogs(logs);
  }

  updateStatus(messageId: string, status: string, deliveredAt?: string): void {
    const logs = this.getLogs();
    const updatedLogs = logs.map(log => {
      if (log.id === messageId) {
        return {
          ...log,
          status,
          deliveredAt
        };
      }
      return log;
    });
    this.saveLogs(updatedLogs);
  }

  getAllLogs(): EmailLog[] {
    return this.getLogs();
  }

  clearLogs(): void {
    secureStorage.removeItem(EMAIL_LOGS_KEY);
  }
}