import React, { useState, useRef } from 'react';
import { Mic, Square, Loader2 } from 'lucide-react';
import { openAIService } from '../../services/openai';
import Button from '../Button';

interface VoiceRecorderProps {
  onTranscription: (text: string) => void;
  onError: (error: string) => void;
}

const VoiceRecorder: React.FC<VoiceRecorderProps> = ({ onTranscription, onError }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
        await transcribeAudio(audioBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      onError('Failed to access microphone');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      setIsProcessing(true);
    }
  };

  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      const text = await openAIService.transcribeAudio(audioBlob);
      onTranscription(text);
    } catch (error) {
      onError('Failed to transcribe audio');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-secondary rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold text-text mb-4">Voice Recording</h3>
        
        <div className="flex flex-col items-center gap-4">
          {isProcessing ? (
            <div className="flex items-center gap-2 text-text">
              <Loader2 size={24} className="animate-spin" />
              <span>Transcribing audio...</span>
            </div>
          ) : (
            <Button
              variant={isRecording ? 'secondary' : 'accent'}
              onClick={isRecording ? stopRecording : startRecording}
              size="lg"
            >
              {isRecording ? (
                <>
                  <Square size={24} />
                  Stop Recording
                </>
              ) : (
                <>
                  <Mic size={24} />
                  Start Recording
                </>
              )}
            </Button>
          )}
          
          {isRecording && (
            <div className="text-text-muted text-sm">
              Recording in progress...
            </div>
          )}
          
          <Button
            variant="text"
            onClick={() => {
              if (mediaRecorder.current && isRecording) {
                mediaRecorder.current.stop();
              }
              setIsRecording(false);
              setIsProcessing(false);
              onError('Recording cancelled');
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VoiceRecorder;