import { useEffect, useRef, useCallback } from 'react';

interface ResizeHandler {
  (entry: ResizeObserverEntry): void;
}

export function useResizeObserver<T extends HTMLElement>(onResize?: ResizeHandler) {
  const elementRef = useRef<T | null>(null);
  const observerRef = useRef<ResizeObserver | null>(null);
  const rafRef = useRef<number | null>(null);
  const resizeHandlerRef = useRef<ResizeHandler | undefined>(onResize);
  const skipNextRef = useRef(false);

  useEffect(() => {
    resizeHandlerRef.current = onResize;
  }, [onResize]);

  const cleanup = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = null;
    }
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (!elementRef.current) return;

    const resizeObserverCallback: ResizeObserverCallback = (entries) => {
      if (skipNextRef.current) {
        skipNextRef.current = false;
        return;
      }

      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }

      try {
        rafRef.current = requestAnimationFrame(() => {
          entries.forEach((entry) => {
            const element = entry.target as HTMLElement;
            if (!element) return;

            if (element.tagName.toLowerCase() === 'textarea') {
              const prevHeight = element.style.height;
              element.style.height = 'auto';
              const newHeight = `${element.scrollHeight}px`;
              
              if (prevHeight !== newHeight) {
                element.style.height = newHeight;
              }
            }

            if (resizeHandlerRef.current) {
              resizeHandlerRef.current(entry);
            }
          });
        });
      } catch (error) {
        console.error('ResizeObserver error:', error);
        skipNextRef.current = true;
      }
    };

    try {
      observerRef.current = new ResizeObserver(resizeObserverCallback);
      observerRef.current.observe(elementRef.current, { box: 'border-box' });
    } catch (error) {
      console.error('Failed to create ResizeObserver:', error);
    }

    return cleanup;
  }, [cleanup]);

  return elementRef;
}