import React, { useState } from 'react';
import { Key, Database } from 'lucide-react';
import { checkApiKey, saveApiKey, removeApiKey } from '../services/openai';
import Button from './Button';
import KnowledgeBaseManager from './KnowledgeBase/KnowledgeBaseManager';

const AiSettings: React.FC = () => {
  const [apiKey, setApiKey] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState<'api' | 'knowledge'>('api');

  const currentApiKey = checkApiKey();

  const handleSaveApiKey = () => {
    try {
      if (!apiKey.startsWith('sk-')) {
        setError('Invalid API key format. OpenAI API keys should start with "sk-"');
        return;
      }

      saveApiKey(apiKey);
      setSaveSuccess(true);
      setApiKey('');
      setError('');
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (err) {
      setError('Failed to save API key');
    }
  };

  const handleRemoveApiKey = () => {
    try {
      removeApiKey();
      setApiKey('');
      setError('');
    } catch (err) {
      setError('Failed to remove API key');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex gap-2">
        <Button
          variant={activeTab === 'api' ? 'accent' : 'secondary'}
          onClick={() => setActiveTab('api')}
        >
          <Key size={18} />
          API Key
        </Button>
        <Button
          variant={activeTab === 'knowledge' ? 'accent' : 'secondary'}
          onClick={() => setActiveTab('knowledge')}
        >
          <Database size={18} />
          Knowledge Base
        </Button>
      </div>

      {activeTab === 'api' && (
        <div className="card space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Key size={20} className="text-text" />
              <h2 className="text-lg font-semibold text-text">OpenAI API Key</h2>
            </div>
          </div>

          {currentApiKey ? (
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <div className="font-mono text-text-muted">
                  {`${currentApiKey.slice(0, 3)}...${currentApiKey.slice(-4)}`}
                </div>
                <Button
                  variant="text"
                  onClick={handleRemoveApiKey}
                >
                  Remove
                </Button>
              </div>
              <p className="text-sm text-text-muted">
                Your API key is securely stored. You can remove it at any time.
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              <div>
                <label className="form-label">OpenAI API Key</label>
                <div className="relative">
                  <input
                    type={showApiKey ? 'text' : 'password'}
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    className="input-field pr-10"
                    placeholder="sk-..."
                  />
                  <button
                    type="button"
                    onClick={() => setShowApiKey(!showApiKey)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-text-muted hover:text-text"
                  >
                    {showApiKey ? 'Hide' : 'Show'}
                  </button>
                </div>
              </div>

              {error && (
                <div className="text-red-500 text-sm bg-red-50 p-3 rounded-lg">
                  {error}
                </div>
              )}

              <Button
                variant="accent"
                onClick={handleSaveApiKey}
                disabled={!apiKey}
                fullWidth
              >
                Save API Key
              </Button>

              {saveSuccess && (
                <p className="text-green-500 text-sm text-center">
                  API key saved successfully!
                </p>
              )}

              <div className="text-sm text-text-muted">
                <p className="font-medium mb-2">How to get your API key:</p>
                <ol className="list-decimal list-inside space-y-1">
                  <li>Go to OpenAI's website</li>
                  <li>Sign in or create an account</li>
                  <li>Navigate to API settings</li>
                  <li>Create a new API key</li>
                  <li>Copy and paste it here</li>
                </ol>
              </div>
            </div>
          )}
        </div>
      )}

      {activeTab === 'knowledge' && <KnowledgeBaseManager />}
    </div>
  );
};

export default AiSettings;