import React, { useState, useEffect } from 'react';
import { Calculator, Save, RefreshCw } from 'lucide-react';
import { useApp } from '../context/AppContext';
import Button from './Button';
import type { WealthCalculations } from '../types/base';

const WealthCalculator: React.FC<{ ytdAmount: number }> = ({ ytdAmount }) => {
  const { state, dispatch } = useApp();
  const [showCalculator, setShowCalculator] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [wealthInputs, setWealthInputs] = useState({
    gci: state.profile?.mloGciGoal?.toString() || '',
    workWeeks: '50',
    avgCommission: state.profile?.commissionBps ? (state.profile.commissionBps / 100).toString() : '',
    avgLoanSize: state.profile?.averageLoanAmount?.toString() || '',
    leadToApp: state.profile?.conversionRate?.toString() || '',
    appToClose: state.profile?.pullThroughRate?.toString() || '',
    monthlyRefGoal: state.profile?.preQualGoalPerPartner?.toString() || ''
  });
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    setIsEditing(!state.profile?.wealthCalculations);
  }, [state.profile?.wealthCalculations]);

  const calculateWealth = (inputs: typeof wealthInputs): WealthCalculations => {
    const annualLoans = Number(inputs.gci) / 
      (Number(inputs.avgLoanSize) * (Number(inputs.avgCommission) / 100));
    
    const monthlyLoans = annualLoans / 12;
    const weeklyLoans = annualLoans / Number(inputs.workWeeks);
    const dailyLoans = weeklyLoans / 5;
    const dailyApplications = dailyLoans / (Number(inputs.appToClose) / 100);
    const dailyLeads = dailyApplications / (Number(inputs.leadToApp) / 100);
    
    const monthlyLeads = dailyLeads * 20;
    const totalRefPartners = monthlyLeads / Number(inputs.monthlyRefGoal);

    return {
      annualLoans,
      monthlyLoans,
      weeklyLoans,
      dailyLoans,
      dailyApplications,
      dailyLeads,
      totalRefPartners
    };
  };

  const handleSave = () => {
    const calculations = calculateWealth(wealthInputs);
    setIsEditing(false);

    dispatch({
      type: 'UPDATE_STATS',
      payload: {
        mloGciGoal: Number(wealthInputs.gci)
      }
    });

    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {
        mloGciGoal: Number(wealthInputs.gci),
        averageLoanAmount: Number(wealthInputs.avgLoanSize),
        commissionBps: Number(wealthInputs.avgCommission) * 100,
        conversionRate: Number(wealthInputs.leadToApp),
        pullThroughRate: Number(wealthInputs.appToClose),
        preQualGoalPerPartner: Number(wealthInputs.monthlyRefGoal),
        wealthCalculations: calculations
      }
    });

    setSaveSuccess(true);
    setTimeout(() => setSaveSuccess(false), 3000);
  };

  const handleStartOver = () => {
    setIsEditing(true);
    setWealthInputs({
      gci: '',
      workWeeks: '50',
      avgCommission: '',
      avgLoanSize: '',
      leadToApp: '',
      appToClose: '',
      monthlyRefGoal: ''
    });
  };

  const showResults = Number(wealthInputs.gci) > 0;

  return (
    <div className="space-y-6">
      <Button
        variant="accent"
        size="lg"
        fullWidth
        onClick={() => setShowCalculator(!showCalculator)}
      >
        <Calculator size={20} />
        MLO GCI Calculator
      </Button>

      {showCalculator && (
        <div className="card space-y-6">
          {!isEditing && state.profile?.wealthCalculations ? (
            <>
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-text">
                  Your MLO Goals
                </h2>
                <Button
                  variant="text"
                  onClick={handleStartOver}
                >
                  <RefreshCw size={18} />
                  Start Over
                </Button>
              </div>
              <div className="space-y-4">
                <div className="card">
                  <p className="text-text-muted">Annual GCI Goal</p>
                  <p className="text-2xl font-bold text-text">
                    ${Number(wealthInputs.gci).toLocaleString()}
                  </p>
                  <p className="text-text-muted">
                    YTD: ${ytdAmount.toLocaleString()}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="card">
                    <p className="text-text-muted">Daily Applications</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.wealthCalculations.dailyApplications.toFixed(1)}
                    </p>
                  </div>
                  <div className="card">
                    <p className="text-text-muted">Daily Leads</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.wealthCalculations.dailyLeads.toFixed(1)}
                    </p>
                  </div>
                  <div className="card">
                    <p className="text-text-muted">Monthly Loans</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.wealthCalculations.monthlyLoans.toFixed(1)}
                    </p>
                  </div>
                  <div className="card">
                    <p className="text-text-muted">Annual Loans</p>
                    <p className="text-xl font-bold text-text">
                      {state.profile.wealthCalculations.annualLoans.toFixed(0)}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="space-y-4">
                <div>
                  <label className="form-label">
                    Annual GCI Goal ($)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.gci}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, gci: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 250000"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Average Commission (%)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.avgCommission}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, avgCommission: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 1.5"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Average Loan Size ($)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.avgLoanSize}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, avgLoanSize: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 250000"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Lead to Application Rate (%)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.leadToApp}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, leadToApp: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 20"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Application to Closing Rate (%)
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.appToClose}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, appToClose: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 75"
                  />
                </div>

                <div>
                  <label className="form-label">
                    Monthly Referral Goal per Partner
                  </label>
                  <input
                    type="number"
                    value={wealthInputs.monthlyRefGoal}
                    onChange={(e) => setWealthInputs(prev => ({ ...prev, monthlyRefGoal: e.target.value }))}
                    className="input-field"
                    placeholder="e.g., 3"
                  />
                </div>
              </div>

              {showResults && (
                <div className="pt-4 border-t border-border">
                  <Button
                    variant="accent"
                    onClick={handleSave}
                  >
                    <Save size={18} />
                    Save as Annual Goals
                  </Button>

                  {saveSuccess && (
                    <span className="ml-2 text-green-600 text-sm">
                      Goals saved successfully!
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default WealthCalculator;