import React from 'react';
import { Bot, User } from 'lucide-react';
import { useApp } from '../context/AppContext';

interface Message {
  id: string;
  text: string;
  sender: 'user' | 'ai';
  timestamp: Date;
}

interface ChatMessageProps {
  message: Message;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  const { state } = useApp();
  const isUser = message.sender === 'user';
  const profilePicture = state.profile?.profilePicture;

  const renderAvatar = () => {
    if (isUser && profilePicture) {
      return (
        <div className="w-12 h-12 rounded-2xl bg-accent overflow-hidden shrink-0 shadow-lg">
          <img 
            src={profilePicture} 
            alt="Profile" 
            className="w-full h-full object-cover"
          />
        </div>
      );
    }

    return (
      <div className={`w-12 h-12 rounded-2xl ${
        isUser 
          ? 'bg-accent text-text' 
          : 'bg-gradient-to-br from-primary via-primary to-primary/90 text-white'
      } flex items-center justify-center shrink-0 relative overflow-hidden shadow-lg`}>
        {isUser ? (
          <User size={24} className="relative z-10" />
        ) : (
          <>
            {/* Decorative Background Elements */}
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,_rgba(255,255,255,0.2),_transparent)]" />
            <div className="absolute inset-0 bg-[linear-gradient(45deg,_rgba(255,255,255,0.1)_25%,_transparent_25%,_transparent_50%,_rgba(255,255,255,0.1)_50%,_rgba(255,255,255,0.1)_75%,_transparent_75%)]" />
            
            {/* Glowing Effect */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-6 h-6 rounded-full bg-white/10 animate-pulse" />
            </div>
            
            {/* Robot Icon */}
            <Bot size={24} className="relative z-10" strokeWidth={1.5} />
            
            {/* Bottom Accent */}
            <div className="absolute bottom-0 left-0 right-0 h-1 bg-white/10" />
            
            {/* Animated Elements */}
            <div className="absolute top-2 right-2 w-1.5 h-1.5 rounded-full bg-white/40 animate-ping" />
            <div className="absolute bottom-2 left-2 w-1 h-1 rounded-full bg-white/30 animate-pulse" />
          </>
        )}
      </div>
    );
  };

  const formatMessageText = (text: string) => {
    return text.split('\n').map((line, index) => {
      if (!line.trim()) return <div key={index} className="h-2" />;
      
      const isBullet = line.trim().startsWith('•') || line.trim().startsWith('-');
      const isRecommendation = line.toLowerCase().includes('recommendation');
      
      if (isRecommendation) {
        return (
          <h3 key={index} className={`font-medium ${index > 0 ? 'mt-4 mb-2' : ''}`}>
            {line}
          </h3>
        );
      }
      
      return (
        <p 
          key={index} 
          className={`${isBullet ? 'pl-4' : ''} ${index > 0 ? 'mt-2' : ''} leading-relaxed`}
        >
          {line}
        </p>
      );
    });
  };

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} gap-4 items-start`}>
      {!isUser && renderAvatar()}
      
      <div className={`max-w-[80%] rounded-2xl p-4 ${
        isUser
          ? 'bg-accent text-text shadow-lg'
          : 'bg-white dark:bg-secondary text-text dark:text-text shadow-lg'
      }`}>
        <div className="text-base space-y-1">
          {formatMessageText(message.text)}
        </div>
        <div className="mt-2 flex justify-end">
          <time className="text-xs opacity-70">
            {new Date(message.timestamp).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
            })}
          </time>
        </div>
      </div>

      {isUser && renderAvatar()}
    </div>
  );
};

export default ChatMessage;