import React, { useState } from 'react';
import { Camera, User } from 'lucide-react';
import Button from './Button';
import ImageCropper from './ImageCropper';

interface ProfilePictureProps {
  value: string;
  onChange: (value: string) => void;
  editable?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  value,
  onChange,
  editable = false,
  size = 'md'
}) => {
  const [tempImage, setTempImage] = useState<string>('');
  const [showCropper, setShowCropper] = useState(false);
  const [imageError, setImageError] = useState(false);

  const sizeClasses = {
    sm: 'w-16 h-16',
    md: 'w-24 h-24',
    lg: 'w-32 h-32'
  }[size];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        alert('Please select an image file');
        return;
      }

      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('Image size should be less than 5MB');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          setTempImage(result);
          setShowCropper(true);
          setImageError(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (croppedImage: string) => {
    onChange(croppedImage);
    setShowCropper(false);
    setTempImage('');
    setImageError(false);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  // Check if the value is a valid data URL
  const isValidDataUrl = (str: string) => {
    return str?.startsWith('data:image/');
  };

  return (
    <div className="relative">
      <div className={`${sizeClasses} rounded-full overflow-hidden bg-secondary dark:bg-background border-2 border-border flex items-center justify-center`}>
        {value && isValidDataUrl(value) && !imageError ? (
          <img
            src={value}
            alt="Profile"
            className="w-full h-full object-cover"
            onError={handleImageError}
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-secondary dark:bg-background">
            <User className="w-1/2 h-1/2 text-text-muted" />
          </div>
        )}
      </div>

      {editable && (
        <label className="absolute bottom-0 right-0 w-8 h-8 bg-accent hover:bg-accent/90 rounded-full flex items-center justify-center cursor-pointer shadow-lg transition-colors">
          <Camera size={16} className="text-text" />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
      )}

      {showCropper && tempImage && (
        <ImageCropper
          imageUrl={tempImage}
          onCropComplete={handleCropComplete}
          onClose={() => {
            setShowCropper(false);
            setTempImage('');
          }}
        />
      )}
    </div>
  );
};

export default ProfilePicture;