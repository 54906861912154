import React, { useState } from 'react';
import { useApp } from '../context/AppContext';
import { ArrowRight, TrendingUp } from 'lucide-react';
import Button from './Button';

const ConversionRateDashboard = () => {
  const { state } = useApp();
  const [period, setPeriod] = useState<'daily' | 'monthly' | 'yearly'>('daily');

  const getTaskCount = (taskId: number) => {
    const history = state.stats.taskHistory[taskId] || {
      daily: 0,
      weekly: 0,
      monthly: 0,
      yearly: 0
    };
    return history[period];
  };

  const calculateRate = (numerator: number, denominator: number): string => {
    if (denominator === 0) return '0%';
    return `${((numerator / denominator) * 100).toFixed(1)}%`;
  };

  // Standalone Leads KPI
  const totalLeads = getTaskCount(26); // ID 26 for Leads

  // Conversion flow metrics
  const metrics = [
    {
      from: 'Dials',
      to: 'Conversations',
      fromCount: getTaskCount(16), // Dials
      toCount: getTaskCount(25), // Conversations
    },
    {
      from: 'Conversations',
      to: 'Appointments Set',
      fromCount: getTaskCount(25), // Conversations
      toCount: getTaskCount(8), // Appointments Set
    },
    {
      from: 'Appointments Set',
      to: 'Appointments Held',
      fromCount: getTaskCount(8), // Appointments Set
      toCount: getTaskCount(7), // Appointments Held
    },
    {
      from: 'Appointments Held',
      to: 'Agreements/Contracts',
      fromCount: getTaskCount(7), // Appointments Held
      toCount: getTaskCount(6), // Agreements
    },
    {
      from: 'Agreements/Contracts',
      to: 'Closings',
      fromCount: getTaskCount(6), // Agreements
      toCount: getTaskCount(11), // Closings
    }
  ];

  return (
    <div className="card space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <TrendingUp size={20} className="text-text" />
          <h2 className="text-lg font-semibold text-text">Conversion Rates</h2>
        </div>
        <div className="flex gap-2">
          <Button
            variant={period === 'daily' ? 'accent' : 'secondary'}
            onClick={() => setPeriod('daily')}
          >
            Daily
          </Button>
          <Button
            variant={period === 'monthly' ? 'accent' : 'secondary'}
            onClick={() => setPeriod('monthly')}
          >
            Monthly
          </Button>
          <Button
            variant={period === 'yearly' ? 'accent' : 'secondary'}
            onClick={() => setPeriod('yearly')}
          >
            Yearly
          </Button>
        </div>
      </div>

      {/* Standalone Leads KPI */}
      <div className="bg-secondary dark:bg-background rounded-lg p-4">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="font-medium text-text">Total Leads</h3>
            <p className="text-sm text-text-muted">Current {period} period</p>
          </div>
          <span className="text-2xl font-bold text-primary">{totalLeads}</span>
        </div>
      </div>

      {/* Conversion Flow */}
      <div className="space-y-4">
        {metrics.map((metric, index) => (
          <div key={index} className="bg-secondary dark:bg-background rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <span className="text-text">{metric.from}</span>
                <ArrowRight size={16} className="text-text-muted" />
                <span className="text-text">{metric.to}</span>
              </div>
              <span className="text-lg font-semibold text-primary">
                {calculateRate(metric.toCount, metric.fromCount)}
              </span>
            </div>
            <div className="flex justify-between text-sm text-text-muted">
              <span>{metric.fromCount} {metric.from}</span>
              <span>{metric.toCount} {metric.to}</span>
            </div>
            <div className="mt-2 h-2 bg-background dark:bg-secondary rounded-full overflow-hidden">
              <div 
                className="h-full bg-primary rounded-full transition-all"
                style={{ 
                  width: `${metric.fromCount ? (metric.toCount / metric.fromCount) * 100 : 0}%` 
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="text-sm text-text-muted">
        <p>Showing conversion rates for {period} activities.</p>
        {period === 'daily' && (
          <p>Daily rates reset at midnight.</p>
        )}
      </div>
    </div>
  );
};

export default ConversionRateDashboard;