import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { saveState, loadState } from '../utils/statePersistence';
import type { Task, Goal, Event, Profile, JournalEntry, WealthCalculations, ActivityLogEntry, MotivationalVideo } from '../types/base';

interface AppState {
  tasks: Task[];
  goals: {
    gratitude: Goal[];
    wins: Goal[];
    focus: Goal[];
    affirmations: Goal[];
  };
  events: Event[];
  stats: {
    totalPoints: number;
    goalAmount: number;
    mloYtdAmount: number;
    realtorYtdAmount: number;
    pendingAmount: number;
    dailyPoints: number;
    taskHistory: {
      [taskId: string]: {
        daily: number;
        weekly: number;
        monthly: number;
        yearly: number;
      };
    };
    totalTeams: number;
    totalUsers: number;
  };
  profile: Profile | null;
  auth: {
    id: string;
    role: string;
  } | null;
  activityLogs: ActivityLogEntry[];
  videos: MotivationalVideo[];
  todayVideo?: MotivationalVideo;
}

type AppAction = 
  | { type: 'LOAD_STATE'; payload: Partial<AppState> }
  | { type: 'ADD_TASK'; payload: Task }
  | { type: 'UPDATE_TASKS'; payload: Record<string | number, number> }
  | { type: 'SUBMIT_TASKS'; payload: { counts: Record<string | number, number>; totalPoints: number; activityLogs: ActivityLogEntry[] } }
  | { type: 'ADD_GOAL'; payload: Goal }
  | { type: 'UPDATE_STATS'; payload: Partial<AppState['stats']> }
  | { type: 'UPDATE_PROFILE'; payload: Partial<Profile> }
  | { type: 'ADD_JOURNAL_ENTRY'; payload: JournalEntry }
  | { type: 'ADD_ACTIVITY_LOG'; payload: ActivityLogEntry }
  | { type: 'CLEAR_ACTIVITY_LOGS' }
  | { type: 'ADD_MOTIVATIONAL_VIDEO'; payload: MotivationalVideo }
  | { type: 'DELETE_MOTIVATIONAL_VIDEO'; payload: string }
  | { type: 'UPDATE_MOTIVATIONAL_VIDEO'; payload: MotivationalVideo }
  | { type: 'SET_TODAY_VIDEO'; payload: MotivationalVideo }
  | { type: 'RESET_STATE' };

const getInitialState = (): AppState => ({
  tasks: [],
  goals: {
    gratitude: [],
    wins: [],
    focus: [],
    affirmations: []
  },
  events: [],
  stats: {
    totalPoints: 0,
    goalAmount: 0,
    mloYtdAmount: 0,
    realtorYtdAmount: 0,
    pendingAmount: 0,
    dailyPoints: 0,
    taskHistory: {},
    totalTeams: 0,
    totalUsers: 0
  },
  profile: null,
  auth: null,
  activityLogs: [],
  videos: [],
  todayVideo: undefined
});

const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
} | null>(null);

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'LOAD_STATE':
      return {
        ...state,
        ...action.payload
      };

    case 'UPDATE_PROFILE':
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload
        }
      };

    case 'UPDATE_STATS':
      return {
        ...state,
        stats: {
          ...state.stats,
          ...action.payload
        }
      };

    case 'ADD_JOURNAL_ENTRY':
      return {
        ...state,
        profile: {
          ...state.profile,
          journalEntries: [
            action.payload,
            ...(state.profile?.journalEntries || [])
          ]
        }
      };

    case 'ADD_ACTIVITY_LOG':
      return {
        ...state,
        activityLogs: [action.payload, ...state.activityLogs]
      };

    case 'CLEAR_ACTIVITY_LOGS':
      return {
        ...state,
        activityLogs: []
      };

    case 'ADD_MOTIVATIONAL_VIDEO':
      return {
        ...state,
        videos: [...state.videos, action.payload]
      };
    
    case 'DELETE_MOTIVATIONAL_VIDEO':
      return {
        ...state,
        videos: state.videos.filter(v => v.id !== action.payload),
        todayVideo: state.todayVideo?.id === action.payload ? undefined : state.todayVideo
      };
    
    case 'UPDATE_MOTIVATIONAL_VIDEO':
      return {
        ...state,
        videos: state.videos.map(v => 
          v.id === action.payload.id ? action.payload : v
        ),
        todayVideo: state.todayVideo?.id === action.payload.id ? action.payload : state.todayVideo
      };
    
    case 'SET_TODAY_VIDEO':
      return {
        ...state,
        todayVideo: action.payload
      };
    
    case 'RESET_STATE':
      return getInitialState();
    
    default:
      return state;
  }
};

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [state, dispatch] = useReducer(appReducer, getInitialState());

  useEffect(() => {
    const savedState = loadState();
    if (savedState) {
      dispatch({ type: 'LOAD_STATE', payload: savedState });
    }
  }, []);

  useEffect(() => {
    saveState(state);
  }, [state]);

  useEffect(() => {
    if (user?.id) {
      dispatch({
        type: 'UPDATE_PROFILE',
        payload: { auth: { id: user.id, role: user.role } }
      });
    }
  }, [user]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};