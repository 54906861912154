import React from 'react';
import type { MotivationalVideo } from '../../types/base';

interface VideoEmbedProps {
  video: MotivationalVideo;
  onComplete?: () => void;
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ video, onComplete }) => {
  const handleVideoEnd = () => {
    if (onComplete) {
      onComplete();
    }
  };

  return (
    <div className="aspect-video rounded-lg overflow-hidden bg-black">
      {video.embedCode ? (
        <div dangerouslySetInnerHTML={{ __html: video.embedCode }} />
      ) : (
        <iframe
          src={`https://www.youtube-nocookie.com/embed/${getVideoId(video.url)}?autoplay=1&cc_load_policy=1&rel=0&modestbranding=1&playsinline=1&enablejsapi=1`}
          className="w-full h-full"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onEnded={handleVideoEnd}
        />
      )}
    </div>
  );
};

const getVideoId = (url: string): string => {
  try {
    const urlObj = new URL(url);
    if (urlObj.pathname.startsWith('/shorts/')) {
      return urlObj.pathname.split('/shorts/')[1]?.split('?')[0] || '';
    }
    if (urlObj.hostname === 'youtu.be') {
      return urlObj.pathname.slice(1);
    }
    return urlObj.searchParams.get('v') || '';
  } catch {
    return '';
  }
};

export default VideoEmbed;