export const OPENAI_API_KEY = 'openai_api_key';

export const DEFAULT_CONFIG = {
  model: 'gpt-4',
  temperature: 0.7,
  maxTokens: 500
};

export const WHISPER_CONFIG = {
  model: 'whisper-1',
  language: 'en'
};

export const API_ENDPOINTS = {
  transcription: 'https://api.openai.com/v1/audio/transcriptions'
};