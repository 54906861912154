import { openAIService } from './openai';
import { getDocuments } from './knowledgeBase';
import { AppState } from '../context/AppContext';
import { secureStorage } from '../utils/secureStorage';
import { BUSINESS_TASKS, PRIMARY_KPI_IDS } from '../data/tasks';
import { formatNumber, formatPercentage, formatCurrency } from '../utils/formatters';

interface ChatHistoryEntry {
  text: string;
  sender: 'user' | 'ai';
  timestamp: string;
}

class AiCoachService {
  private readonly CHAT_HISTORY_KEY = 'ai_chat_history';
  private readonly MAX_HISTORY = 100;

  async generateResponse(input: string, state: AppState): Promise<string> {
    try {
      if (!openAIService.checkApiKey()) {
        throw new Error('OpenAI API key not configured. Please add your API key in settings.');
      }

      // Get relevant knowledge base content
      const knowledgeResults = this.searchKnowledgeBase(input);
      
      // Get performance metrics
      const metrics = this.getPerformanceMetrics(state);
      
      // Prepare context for OpenAI
      const context = {
        userProfile: {
          selectedRole: state.profile?.selectedRole,
          name: state.profile?.name,
          title: state.profile?.title,
          company: state.profile?.company,
          goals: {
            mloGciGoal: state.profile?.mloGciGoal,
            realtorGciGoal: state.profile?.realtorGciGoal
          }
        },
        dailyStats: {
          taskHistory: state.stats.taskHistory || {},
          totalPoints: state.stats.totalPoints,
          ytdAmount: state.stats.ytdAmount,
          dailyPoints: state.stats.dailyPoints
        },
        journalEntries: state.profile?.journalEntries?.slice(0, 5),
        activityLogs: state.activityLogs?.slice(0, 10),
        messageHistory: this.getChatHistory(),
        knowledgeBase: knowledgeResults,
        performanceMetrics: metrics
      };

      // Generate response using OpenAI
      const response = await openAIService.generateResponse(input, context);
      
      // Save the interaction to history
      this.saveChatHistory({
        text: input,
        sender: 'user'
      });
      this.saveChatHistory({
        text: response,
        sender: 'ai'
      });

      return response;
    } catch (error) {
      console.error('Error generating response:', error);
      throw new Error(error instanceof Error ? error.message : 'Failed to generate response');
    }
  }

  private getPerformanceMetrics(state: AppState) {
    const metrics = {
      kpis: {} as Record<string, {
        daily: number;
        weekly: number;
        monthly: number;
        yearly: number;
        name: string;
      }>,
      goals: {
        mloGciGoal: state.profile?.mloGciGoal || 0,
        realtorGciGoal: state.profile?.realtorGciGoal || 0,
        mloYtdAmount: state.stats.mloYtdAmount || 0,
        realtorYtdAmount: state.stats.realtorYtdAmount || 0
      },
      conversionRates: {} as Record<string, number>
    };

    // Get KPI data
    PRIMARY_KPI_IDS.forEach(id => {
      const task = BUSINESS_TASKS.find(t => t.id === id);
      if (task) {
        const history = state.stats.taskHistory[id] || {
          daily: 0,
          weekly: 0,
          monthly: 0,
          yearly: 0
        };

        metrics.kpis[id] = {
          ...history,
          name: task.name
        };
      }
    });

    // Calculate conversion rates
    const leads = metrics.kpis[26]?.monthly || 0; // ID 26 for Leads
    const appointments = metrics.kpis[8]?.monthly || 0; // ID 8 for Appointments Set
    const closings = metrics.kpis[11]?.monthly || 0; // ID 11 for Closings

    metrics.conversionRates = {
      leadsToAppointments: leads > 0 ? (appointments / leads) * 100 : 0,
      appointmentsToClosings: appointments > 0 ? (closings / appointments) * 100 : 0,
      leadsToClosings: leads > 0 ? (closings / leads) * 100 : 0
    };

    return metrics;
  }

  private searchKnowledgeBase(query: string): string[] {
    try {
      const searchTerms = query.toLowerCase().split(' ');
      const results: string[] = [];
      const documents = getDocuments();

      documents.forEach(doc => {
        const searchableContent = doc.searchableContent || doc.content;
        if (!searchableContent || typeof searchableContent !== 'string') return;

        const content = searchableContent.toLowerCase();
        const relevanceScore = searchTerms.reduce((score, term) => {
          return score + (content.includes(term) ? 1 : 0);
        }, 0);

        if (relevanceScore > 0) {
          const paragraphs = searchableContent.split(/\n\s*\n/);
          const relevantParagraphs = paragraphs.filter(p => 
            searchTerms.some(term => p.toLowerCase().includes(term))
          );

          const processedParagraphs = relevantParagraphs.map(p => 
            p.trim()
              .replace(/\s+/g, ' ')
              .replace(/[^\x20-\x7E\n]/g, '')
          ).filter(p => p.length > 0);

          results.push(...processedParagraphs);
        }
      });

      return results
        .sort((a, b) => {
          const scoreA = searchTerms.reduce((score, term) => 
            score + (a.toLowerCase().includes(term) ? 1 : 0), 0);
          const scoreB = searchTerms.reduce((score, term) => 
            score + (b.toLowerCase().includes(term) ? 1 : 0), 0);
          return scoreB - scoreA;
        })
        .slice(0, 3);
    } catch (error) {
      console.error('Knowledge base search error:', error);
      return [];
    }
  }

  getChatHistory(): ChatHistoryEntry[] {
    try {
      const history = secureStorage.getItem(this.CHAT_HISTORY_KEY);
      return history ? JSON.parse(history) : [];
    } catch (error) {
      console.error('Error loading chat history:', error);
      return [];
    }
  }

  saveChatHistory(entry: Omit<ChatHistoryEntry, 'timestamp'>): void {
    try {
      const history = this.getChatHistory();
      const newEntry = {
        ...entry,
        timestamp: new Date().toISOString()
      };
      
      history.push(newEntry);
      const trimmedHistory = history.slice(-this.MAX_HISTORY);
      
      secureStorage.setItem(this.CHAT_HISTORY_KEY, JSON.stringify(trimmedHistory));
    } catch (error) {
      console.error('Error saving chat history:', error);
    }
  }

  clearChatHistory(): void {
    try {
      secureStorage.removeItem(this.CHAT_HISTORY_KEY);
    } catch (error) {
      console.error('Error clearing chat history:', error);
    }
  }
}

export const aiCoach = new AiCoachService();