import { secureStorage } from '../utils/secureStorage';
import { KnowledgeBaseItem } from '../types/knowledge';
import { v4 as uuidv4 } from 'uuid';

const STORAGE_KEY = 'knowledge_base_items';

export const getDocuments = (): KnowledgeBaseItem[] => {
  try {
    const data = secureStorage.getItem(STORAGE_KEY);
    return data ? JSON.parse(data) : [];
  } catch (error) {
    console.error('Failed to get documents:', error);
    return [];
  }
};

export const saveDocument = async (file: File): Promise<{ success: boolean; item?: KnowledgeBaseItem; error?: string }> => {
  try {
    if (file.size > 10 * 1024 * 1024) { // 10MB limit
      return { success: false, error: 'File size must be less than 10MB' };
    }

    const content = await readFileContent(file);
    const item: KnowledgeBaseItem = {
      id: uuidv4(),
      title: file.name,
      content,
      type: getFileType(file),
      uploadDate: new Date().toISOString(),
      size: file.size,
      searchableContent: await extractTextContent(file)
    };

    const documents = getDocuments();
    documents.push(item);
    secureStorage.setItem(STORAGE_KEY, JSON.stringify(documents));

    return { success: true, item };
  } catch (error) {
    console.error('Failed to save document:', error);
    return { success: false, error: 'Failed to save document' };
  }
};

export const deleteDocument = (id: string): boolean => {
  try {
    const documents = getDocuments();
    const updatedDocs = documents.filter(doc => doc.id !== id);
    secureStorage.setItem(STORAGE_KEY, JSON.stringify(updatedDocs));
    return true;
  } catch (error) {
    console.error('Failed to delete document:', error);
    return false;
  }
};

const readFileContent = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result as string);
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
};

const extractTextContent = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const content = e.target?.result as string;
        // Basic text extraction - in a real app, use a PDF parsing library
        const text = content.replace(/[^\x20-\x7E\n]/g, ' ')
          .replace(/\s+/g, ' ')
          .trim();
        resolve(text);
      } catch (error) {
        console.error('Text extraction error:', error);
        resolve(''); // Return empty string on error
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsText(file);
  });
};

const getFileType = (file: File): KnowledgeBaseItem['type'] => {
  const ext = file.name.split('.').pop()?.toLowerCase();
  switch (ext) {
    case 'pdf': return 'pdf';
    case 'doc':
    case 'docx': return 'doc';
    default: return 'txt';
  }
};