import React from 'react';

interface MoodTrackerProps {
  value: string;
  onChange: (mood: string) => void;
}

const MoodTracker: React.FC<MoodTrackerProps> = ({ value, onChange }) => {
  const moods = [
    { emoji: '😊', label: 'Happy' },
    { emoji: '😌', label: 'Calm' },
    { emoji: '🤔', label: 'Thoughtful' },
    { emoji: '😤', label: 'Frustrated' },
    { emoji: '😢', label: 'Sad' }
  ];

  return (
    <div className="bg-white dark:bg-secondary rounded-lg p-4">
      <h3 className="text-lg font-semibold text-text mb-4">How are you feeling?</h3>
      <div className="grid grid-cols-5 gap-2">
        {moods.map(({ emoji, label }) => (
          <button
            key={emoji}
            onClick={() => onChange(emoji)}
            className={`flex flex-col items-center p-3 rounded-lg transition-colors ${
              value === emoji 
                ? 'bg-primary/10' 
                : 'hover:bg-secondary/50 dark:hover:bg-background/50'
            }`}
          >
            <span className="text-2xl mb-1">{emoji}</span>
            <span className="text-xs text-text-muted">{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default MoodTracker;