import React from 'react';
import { Calendar } from 'lucide-react';
import { format } from 'date-fns';

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (range: [Date, Date]) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onChange
}) => {
  return (
    <div className="flex items-center gap-4">
      <div className="flex-1">
        <label className="block text-sm text-text-muted mb-1">Start Date</label>
        <div className="relative">
          <input
            type="date"
            value={format(startDate, 'yyyy-MM-dd')}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              onChange([newDate, endDate]);
            }}
            className="input-field pl-10"
          />
          <Calendar size={16} className="absolute left-3 top-1/2 -translate-y-1/2 text-text-muted" />
        </div>
      </div>

      <div className="flex-1">
        <label className="block text-sm text-text-muted mb-1">End Date</label>
        <div className="relative">
          <input
            type="date"
            value={format(endDate, 'yyyy-MM-dd')}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              onChange([startDate, newDate]);
            }}
            className="input-field pl-10"
          />
          <Calendar size={16} className="absolute left-3 top-1/2 -translate-y-1/2 text-text-muted" />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;