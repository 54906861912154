import React, { createContext, useContext, useState, useEffect } from 'react';
import { shouldUseWhiteText } from '../utils/colorUtils';
import { secureStorage } from '../utils/secureStorage';

interface ThemeColors {
  primary: string;
  secondary: string;
  background: string;
  text: string;
  muted: string;
  border: string;
  accent: string;
}

interface Theme {
  colors: ThemeColors;
  isDark: boolean;
}

interface ThemeContextType {
  theme: Theme;
  updateTheme: (newTheme: Theme) => void;
  toggleDarkMode: () => void;
  getTextColor: (bgColor: string) => string;
}

const defaultColors: ThemeColors = {
  primary: '#230d91',
  secondary: '#e0ddf4',
  background: '#f1f0fa',
  text: '#0e0a1f',
  muted: '#4b38a8',
  border: '#ccc6ec',
  accent: '#F2C70D'
};

const defaultTheme: Theme = {
  colors: defaultColors,
  isDark: false
};

const THEME_STORAGE_KEY = 'theme_palette';

const ThemeContext = createContext<ThemeContextType>({
  theme: defaultTheme,
  updateTheme: () => {},
  toggleDarkMode: () => {},
  getTextColor: () => '#000000'
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(() => {
    try {
      const savedTheme = secureStorage.getItem(THEME_STORAGE_KEY);
      if (savedTheme) {
        const parsed = JSON.parse(savedTheme) as Theme;
        // Ensure all required color properties exist
        const colors = {
          ...defaultColors,
          ...parsed.colors
        };
        return {
          colors,
          isDark: parsed.isDark ?? false
        };
      }
    } catch (error) {
      console.error('Failed to load theme:', error);
    }
    return defaultTheme;
  });

  useEffect(() => {
    if (!theme || !theme.colors) {
      console.error('Invalid theme state:', theme);
      setTheme(defaultTheme);
      return;
    }

    try {
      // Set theme attribute
      document.documentElement.setAttribute('data-theme', theme.isDark ? 'dark' : 'light');

      // Apply CSS custom properties
      const colors = theme.colors;
      Object.entries(colors).forEach(([key, value]) => {
        if (value) {
          document.documentElement.style.setProperty(`--color-${key}`, value);
          
          // Handle RGB conversion for primary color
          if (key === 'primary') {
            const rgb = hexToRgb(value);
            if (rgb) {
              document.documentElement.style.setProperty(
                '--color-primary-rgb',
                `${rgb.r}, ${rgb.g}, ${rgb.b}`
              );
            }
          }
        }
      });

      // Save theme to storage
      secureStorage.setItem(THEME_STORAGE_KEY, JSON.stringify(theme));
    } catch (error) {
      console.error('Failed to apply theme:', error);
    }
  }, [theme]);

  const updateTheme = (newTheme: Theme) => {
    if (!newTheme || !newTheme.colors) {
      console.error('Invalid theme update:', newTheme);
      return;
    }

    setTheme({
      colors: {
        ...defaultColors,
        ...newTheme.colors
      },
      isDark: newTheme.isDark ?? theme.isDark
    });
  };

  const toggleDarkMode = () => {
    setTheme(prev => ({
      ...prev,
      isDark: !prev.isDark
    }));
  };

  const getTextColor = (bgColor: string): string => {
    if (!bgColor) return '#0e0a1f';
    return shouldUseWhiteText(bgColor) ? '#ffffff' : '#0e0a1f';
  };

  // Ensure we always have a valid theme object
  const safeTheme: Theme = theme || defaultTheme;

  return (
    <ThemeContext.Provider value={{
      theme: safeTheme,
      updateTheme,
      toggleDarkMode,
      getTextColor
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Helper function to convert hex to RGB
const hexToRgb = (hex: string) => {
  if (!hex) return null;
  
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};