import React, { useState, useEffect } from 'react';
import { Upload, Image as ImageIcon, Trash2, CheckCircle, AlertCircle } from 'lucide-react';
import Button from '../Button';

const FaviconManager: React.FC = () => {
  const [currentFavicon, setCurrentFavicon] = useState<string | null>(null);
  const [previewFavicon, setPreviewFavicon] = useState<string | null>(null);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  useEffect(() => {
    // Load current favicon
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (link) {
      setCurrentFavicon(link.href);
    }
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Validate file
    if (!file.type.startsWith('image/')) {
      setError('Please select an image file');
      return;
    }

    if (file.size > 1024 * 1024) { // 1MB limit
      setError('File size must be less than 1MB');
      return;
    }

    // Create preview
    const reader = new FileReader();
    reader.onload = (e) => {
      const result = e.target?.result as string;
      setPreviewFavicon(result);
      setError('');
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async () => {
    if (!previewFavicon) return;

    try {
      // Update favicon links
      const links = document.querySelectorAll("link[rel*='icon']");
      links.forEach(link => {
        (link as HTMLLinkElement).href = previewFavicon;
      });

      // Store in localStorage for persistence
      localStorage.setItem('custom_favicon', previewFavicon);

      setCurrentFavicon(previewFavicon);
      setSuccess('Favicon updated successfully');
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Failed to update favicon');
    }
  };

  const handleReset = () => {
    const defaultFavicon = '/favicon.svg';
    const links = document.querySelectorAll("link[rel*='icon']");
    links.forEach(link => {
      (link as HTMLLinkElement).href = defaultFavicon;
    });

    localStorage.removeItem('custom_favicon');
    setCurrentFavicon(defaultFavicon);
    setPreviewFavicon(null);
    setSuccess('Favicon reset to default');
    setTimeout(() => setSuccess(''), 3000);
  };

  return (
    <div className="card space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <ImageIcon size={20} className="text-text" />
          <h2 className="text-lg font-semibold text-text">Favicon Management</h2>
        </div>
        <Button
          variant="secondary"
          onClick={handleReset}
        >
          Reset to Default
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-sm font-medium text-text mb-2">Current Favicon</h3>
          <div className="flex items-center gap-4 p-4 bg-secondary dark:bg-background rounded-lg">
            {currentFavicon && (
              <img
                src={currentFavicon}
                alt="Current Favicon"
                className="w-16 h-16 object-contain bg-white rounded-lg p-2"
              />
            )}
            <div className="text-sm text-text-muted">
              Currently active favicon
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-sm font-medium text-text mb-2">Upload New Favicon</h3>
          <div className="flex items-center gap-4 p-4 bg-secondary dark:bg-background rounded-lg">
            <div className="relative">
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/x-icon,image/png,image/svg+xml"
                className="hidden"
                id="favicon-upload"
              />
              <Button
                variant="secondary"
                onClick={() => document.getElementById('favicon-upload')?.click()}
              >
                <Upload size={18} />
                Choose File
              </Button>
            </div>
            {previewFavicon && (
              <img
                src={previewFavicon}
                alt="New Favicon"
                className="w-16 h-16 object-contain bg-white rounded-lg p-2"
              />
            )}
          </div>
        </div>
      </div>

      {error && (
        <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
          <AlertCircle size={16} />
          <p className="text-sm">{error}</p>
        </div>
      )}

      {success && (
        <div className="flex items-center gap-2 text-green-500 bg-green-50 p-3 rounded-lg">
          <CheckCircle size={16} />
          <p className="text-sm">{success}</p>
        </div>
      )}

      {previewFavicon && (
        <div className="flex justify-end gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              setPreviewFavicon(null);
              setError('');
            }}
          >
            <Trash2 size={18} />
            Cancel
          </Button>
          <Button
            variant="accent"
            onClick={handleSave}
          >
            Update Favicon
          </Button>
        </div>
      )}

      <div className="text-sm text-text-muted">
        <p className="font-medium mb-2">Requirements:</p>
        <ul className="list-disc list-inside space-y-1">
          <li>Supported formats: ICO, PNG, SVG</li>
          <li>Maximum file size: 1MB</li>
          <li>Recommended size: 32x32px or 64x64px</li>
          <li>SVG format is preferred for best quality</li>
        </ul>
      </div>
    </div>
  );
};

export default FaviconManager;