import React, { useState } from 'react';
import { Mail, Eye, EyeOff, AlertCircle, CheckCircle } from 'lucide-react';
import { emailService } from '../../services/email/service';
import Button from '../Button';
import SectionHeader from './components/SectionHeader';
import ImportantNotes from './components/ImportantNotes';

const EmailSetup: React.FC = () => {
  const [settings, setSettings] = useState({
    apiKey: '',
    domain: '',
    fromEmail: '',
    fromName: ''
  });
  const [showApiKey, setShowApiKey] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isConfiguring, setIsConfiguring] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setIsConfiguring(true);

    try {
      await emailService.saveConfig(settings);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save email settings');
    } finally {
      setIsConfiguring(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="card">
        <SectionHeader
          icon={Mail}
          title="Email Configuration"
        />

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="form-label">API Key</label>
            <div className="relative">
              <input
                type={showApiKey ? 'text' : 'password'}
                value={settings.apiKey}
                onChange={(e) => setSettings(prev => ({ ...prev, apiKey: e.target.value }))}
                className="input-field pr-10"
                placeholder="key-xxxxxxxxxxxxxxxxxxxxxxx"
              />
              <button
                type="button"
                onClick={() => setShowApiKey(!showApiKey)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-text-muted hover:text-text"
              >
                {showApiKey ? <EyeOff size={16} /> : <Eye size={16} />}
              </button>
            </div>
            <p className="text-xs text-text-muted mt-1">
              Must start with "key-" followed by your Mailgun API key
            </p>
          </div>

          <div>
            <label className="form-label">Domain</label>
            <input
              type="text"
              value={settings.domain}
              onChange={(e) => setSettings(prev => ({ ...prev, domain: e.target.value }))}
              className="input-field"
              placeholder="mail.yourdomain.com"
            />
            <p className="text-xs text-text-muted mt-1">
              The domain you've configured in Mailgun
            </p>
          </div>

          <div>
            <label className="form-label">From Name</label>
            <input
              type="text"
              value={settings.fromName}
              onChange={(e) => setSettings(prev => ({ ...prev, fromName: e.target.value }))}
              className="input-field"
              placeholder="Your Application Name"
            />
          </div>

          <div>
            <label className="form-label">From Email</label>
            <input
              type="email"
              value={settings.fromEmail}
              onChange={(e) => setSettings(prev => ({ ...prev, fromEmail: e.target.value }))}
              className="input-field"
              placeholder="noreply@yourdomain.com"
            />
            <p className="text-xs text-text-muted mt-1">
              Must be a verified email address on your Mailgun domain
            </p>
          </div>

          {error && (
            <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
              <AlertCircle size={16} />
              <p className="text-sm">{error}</p>
            </div>
          )}

          {success && (
            <div className="flex items-center gap-2 text-green-500 bg-green-50 p-3 rounded-lg">
              <CheckCircle size={16} />
              <p className="text-sm">Email configuration saved successfully!</p>
            </div>
          )}

          <Button
            type="submit"
            variant="accent"
            disabled={isConfiguring}
            fullWidth
          >
            {isConfiguring ? 'Saving...' : 'Save Configuration'}
          </Button>
        </form>
      </div>

      <ImportantNotes
        notes={[
          'API key must start with "key-"',
          'Domain must be configured in Mailgun',
          'From email must be verified',
          'Configuration is required for sending emails'
        ]}
      />
    </div>
  );
};

export default EmailSetup;