import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../services/auth';
import type { User } from '../types/base';

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  isImpersonating: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  impersonateUser: (userId: string) => Promise<void>;
  endImpersonation: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [originalUser, setOriginalUser] = useState<User | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      auth.verifyToken(token)
        .then(user => {
          setUser(user);
          setIsAuthenticated(true);
          // Check if user was impersonating
          const wasImpersonating = localStorage.getItem('impersonating') === 'true';
          if (wasImpersonating) {
            setIsImpersonating(true);
          }
        })
        .catch(() => {
          localStorage.removeItem('authToken');
          localStorage.removeItem('impersonating');
          setIsAuthenticated(false);
          setUser(null);
          if (location.pathname !== '/login' && location.pathname !== '/create-account') {
            navigate('/login');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      if (location.pathname !== '/login' && location.pathname !== '/create-account') {
        navigate('/login');
      }
    }
  }, [navigate, location.pathname]);

  const login = async (email: string, password: string) => {
    try {
      const { user, token } = await auth.login(email, password);
      setUser(user);
      setIsAuthenticated(true);
      localStorage.setItem('authToken', token);
      navigate('/role-selection');
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      auth.logout(token);
    }
    setUser(null);
    setIsAuthenticated(false);
    setIsImpersonating(false);
    setOriginalUser(null);
    localStorage.removeItem('authToken');
    localStorage.removeItem('impersonating');
    navigate('/login');
  };

  const impersonateUser = async (userId: string) => {
    try {
      const impersonatedUser = await auth.verifyToken(userId);
      setOriginalUser(user);
      setUser(impersonatedUser);
      setIsImpersonating(true);
      localStorage.setItem('impersonating', 'true');
      navigate('/');
    } catch (error) {
      console.error('Impersonation failed:', error);
      throw error;
    }
  };

  const endImpersonation = () => {
    if (originalUser) {
      setUser(originalUser);
      setOriginalUser(null);
      setIsImpersonating(false);
      localStorage.removeItem('impersonating');
      navigate('/admin');
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isLoading, 
      user, 
      isImpersonating,
      login, 
      logout,
      impersonateUser,
      endImpersonation
    }}>
      {isImpersonating && (
        <div className="bg-accent text-text py-2 px-4 text-center">
          <p className="text-sm">
            You are currently viewing as {user?.name}
            <button
              onClick={endImpersonation}
              className="ml-4 underline hover:no-underline"
            >
              End Impersonation
            </button>
          </p>
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};