import React, { useState } from 'react';
import { User as UserIcon, Trash2 } from 'lucide-react';
import Button from './Button';
import TeamMemberDetails from './TeamMemberDetails';
import type { User } from '../types/base';

interface TeamMemberListProps {
  members: User[];
  onDelete: (userId: string) => void;
  onUpdate: (userId: string, updates: Partial<User>) => void;
  onResetPassword: (userId: string, newPassword: string) => void;
}

const TeamMemberList: React.FC<TeamMemberListProps> = ({
  members,
  onDelete,
  onUpdate,
  onResetPassword
}) => {
  const [selectedMember, setSelectedMember] = useState<User | null>(null);

  const getRoleDisplay = (role: User['role']) => {
    switch (role) {
      case 'mlo':
        return 'MLO';
      case 'realtor':
        return 'Realtor';
      case 'dual_agent':
        return 'Dual Licensed';
      default:
        return role.replace('_', ' ');
    }
  };

  return (
    <div className="space-y-4">
      {members.map(member => (
        <div key={member.id} className="card">
          <div className="flex items-center justify-between">
            <button
              onClick={() => setSelectedMember(member)}
              className="flex items-center gap-3 flex-1 text-left"
            >
              <div className="w-10 h-10 rounded-full bg-secondary dark:bg-background flex items-center justify-center">
                {member.profilePicture ? (
                  <img
                    src={member.profilePicture}
                    alt={member.name}
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <UserIcon size={20} className="text-text" />
                )}
              </div>
              <div>
                <h4 className="font-medium text-text">{member.name}</h4>
                <p className="text-sm text-text-muted">{member.email}</p>
              </div>
            </button>
            <div className="flex items-center gap-3">
              <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                member.status === 'active' 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                {member.status}
              </span>
              <span className="px-2 py-1 text-xs font-medium rounded-full bg-secondary dark:bg-background text-text-muted">
                {getRoleDisplay(member.role)}
              </span>
              <Button
                variant="secondary"
                onClick={() => onDelete(member.id)}
              >
                <Trash2 size={18} className="text-red-500" />
              </Button>
            </div>
          </div>
        </div>
      ))}

      {selectedMember && (
        <TeamMemberDetails
          member={selectedMember}
          onClose={() => setSelectedMember(null)}
          onUpdate={onUpdate}
          onResetPassword={onResetPassword}
        />
      )}
    </div>
  );
};

export default TeamMemberList;