import React, { useState } from 'react';
import { User, Mail, Phone, Building2, Globe } from 'lucide-react';
import { useApp } from '../context/AppContext';
import Button from './Button';
import ProfilePicture from './ProfilePicture';

const ProfileSection = () => {
  const { state, dispatch } = useApp();
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState({
    name: state.profile?.name || '',
    email: state.profile?.email || '',
    phone: state.profile?.phone || '',
    title: state.profile?.title || '',
    company: {
      name: state.profile?.company?.name || '',
      address: state.profile?.company?.address || '',
      website: state.profile?.company?.website || ''
    },
    license: {
      nmlsId: state.profile?.license?.nmlsId || '',
      realtorLicense: state.profile?.license?.realtorLicense || ''
    }
  });

  const handleSave = () => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: profile
    });
    setIsEditing(false);
  };

  return (
    <div className="card space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <User size={20} className="text-text" />
          <h2 className="text-lg font-semibold text-text">Personal Information</h2>
        </div>
        <Button
          variant="text"
          onClick={() => setIsEditing(!isEditing)}
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
      </div>

      <div className="flex flex-col items-center gap-4">
        <ProfilePicture
          value={state.profile?.profilePicture || ''}
          onChange={(value) => dispatch({
            type: 'UPDATE_PROFILE',
            payload: { profilePicture: value }
          })}
          editable={true}
          size="lg"
        />
      </div>

      {isEditing ? (
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="form-label">Full Name</label>
              <input
                type="text"
                value={profile.name}
                onChange={(e) => setProfile(prev => ({ ...prev, name: e.target.value }))}
                className="input-field"
                placeholder="Enter your full name"
              />
            </div>
            <div>
              <label className="form-label">Title</label>
              <input
                type="text"
                value={profile.title}
                onChange={(e) => setProfile(prev => ({ ...prev, title: e.target.value }))}
                className="input-field"
                placeholder="e.g., Team Leader"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="form-label">Email</label>
              <input
                type="email"
                value={profile.email}
                onChange={(e) => setProfile(prev => ({ ...prev, email: e.target.value }))}
                className="input-field"
                placeholder="Enter your email"
              />
            </div>
            <div>
              <label className="form-label">Phone</label>
              <input
                type="tel"
                value={profile.phone}
                onChange={(e) => setProfile(prev => ({ ...prev, phone: e.target.value }))}
                className="input-field"
                placeholder="Enter your phone number"
              />
            </div>
          </div>

          <div>
            <label className="form-label">Company Name</label>
            <input
              type="text"
              value={profile.company.name}
              onChange={(e) => setProfile(prev => ({
                ...prev,
                company: { ...prev.company, name: e.target.value }
              }))}
              className="input-field"
              placeholder="Enter company name"
            />
          </div>

          <div>
            <label className="form-label">Company Address</label>
            <input
              type="text"
              value={profile.company.address}
              onChange={(e) => setProfile(prev => ({
                ...prev,
                company: { ...prev.company, address: e.target.value }
              }))}
              className="input-field"
              placeholder="Enter company address"
            />
          </div>

          <div>
            <label className="form-label">Company Website</label>
            <input
              type="url"
              value={profile.company.website}
              onChange={(e) => setProfile(prev => ({
                ...prev,
                company: { ...prev.company, website: e.target.value }
              }))}
              className="input-field"
              placeholder="Enter company website"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="form-label">NMLS ID</label>
              <input
                type="text"
                value={profile.license.nmlsId}
                onChange={(e) => setProfile(prev => ({
                  ...prev,
                  license: { ...prev.license, nmlsId: e.target.value }
                }))}
                className="input-field"
                placeholder="Enter NMLS ID"
              />
            </div>
            <div>
              <label className="form-label">Realtor License</label>
              <input
                type="text"
                value={profile.license.realtorLicense}
                onChange={(e) => setProfile(prev => ({
                  ...prev,
                  license: { ...prev.license, realtorLicense: e.target.value }
                }))}
                className="input-field"
                placeholder="Enter Realtor license"
              />
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <Button
              variant="secondary"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
            <Button
              variant="accent"
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-text-muted">Name</p>
              <p className="text-text">{profile.name || 'Not specified'}</p>
            </div>
            <div>
              <p className="text-sm text-text-muted">Title</p>
              <p className="text-text">{profile.title || 'Not specified'}</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center gap-2">
              <Mail size={16} className="text-text-muted" />
              <div>
                <p className="text-sm text-text-muted">Email</p>
                <p className="text-text">{profile.email || 'Not specified'}</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Phone size={16} className="text-text-muted" />
              <div>
                <p className="text-sm text-text-muted">Phone</p>
                <p className="text-text">{profile.phone || 'Not specified'}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-2">
              <Building2 size={16} className="text-text-muted" />
              <div>
                <p className="text-sm text-text-muted">Company</p>
                <p className="text-text">{profile.company.name || 'Not specified'}</p>
                <p className="text-text-muted text-sm">
                  {profile.company.address || 'No address specified'}
                </p>
              </div>
            </div>
            {profile.company.website && (
              <div className="flex items-center gap-2 mt-2">
                <Globe size={16} className="text-text-muted" />
                <a
                  href={profile.company.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline"
                >
                  {profile.company.website}
                </a>
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-text-muted">NMLS ID</p>
              <p className="text-text">{profile.license.nmlsId || 'Not specified'}</p>
            </div>
            <div>
              <p className="text-sm text-text-muted">Realtor License</p>
              <p className="text-text">{profile.license.realtorLicense || 'Not specified'}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSection;