import React from 'react';
import { BUSINESS_TASKS, PRIMARY_KPI_IDS } from '../data/tasks';
import { useApp } from '../context/AppContext';

const ActivitySummary = () => {
  const { state } = useApp();
  const primaryTasks = BUSINESS_TASKS.filter(task => PRIMARY_KPI_IDS.includes(task.id));

  const getTaskStats = (taskId: number | string) => {
    const history = state.stats.taskHistory[taskId] || {
      daily: 0,
      weekly: 0,
      monthly: 0,
      yearly: 0
    };
    
    return {
      today: history.daily,
      mtd: history.monthly,
      ytd: history.yearly,
      total: history.yearly // Use yearly total as cumulative total
    };
  };

  return (
    <div className="bg-white dark:bg-secondary rounded-lg p-4 shadow-sm space-y-6">
      <div>
        <h3 className="text-lg font-semibold text-text dark:text-text mb-4">Primary KPIs</h3>
        <div className="space-y-4">
          {primaryTasks.map(task => {
            const stats = getTaskStats(task.id);
            return (
              <div key={task.id} className="flex justify-between items-center">
                <span className="text-text dark:text-text">{task.name}</span>
                <div className="grid grid-cols-4 gap-4 text-sm">
                  <div className="text-center">
                    <div className="text-text-muted dark:text-text-muted">Today</div>
                    <div className="font-medium text-text dark:text-text">{stats.today}</div>
                  </div>
                  <div className="text-center">
                    <div className="text-text-muted dark:text-text-muted">MTD</div>
                    <div className="font-medium text-text dark:text-text">{stats.mtd}</div>
                  </div>
                  <div className="text-center">
                    <div className="text-text-muted dark:text-text-muted">YTD</div>
                    <div className="font-medium text-text dark:text-text">{stats.ytd}</div>
                  </div>
                  <div className="text-center">
                    <div className="text-text-muted dark:text-text-muted">Total</div>
                    <div className="font-medium text-text dark:text-text">{stats.total}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ActivitySummary;