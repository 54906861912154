import React, { useState } from 'react';
import { Send, Loader2, AlertCircle, CheckCircle, FileText } from 'lucide-react';
import Button from '../Button';
import { emailService } from '../../services/email/service';
import type { EmailTemplate } from '../../types/email';
import SectionHeader from './components/SectionHeader';
import ImportantNotes from './components/ImportantNotes';

const EmailTemplates: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('welcomeEmail');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [status, setStatus] = useState<{
    success?: boolean;
    message?: string;
    messageId?: string;
  }>({});

  const handleSendEmail = async () => {
    if (!recipientEmail.trim()) {
      setStatus({
        success: false,
        message: 'Please enter a recipient email address'
      });
      return;
    }

    setIsSending(true);
    setStatus({});

    try {
      const result = await emailService.sendTestEmail(recipientEmail, selectedTemplate as EmailTemplate);
      
      if (result.success) {
        setStatus({
          success: true,
          message: 'Test email sent successfully!',
          messageId: result.messageId
        });
        setRecipientEmail('');

        if (result.messageId) {
          pollEmailStatus(result.messageId);
        }
      } else {
        setStatus({
          success: false,
          message: result.error?.message || 'Failed to send email'
        });
      }
    } catch (error) {
      setStatus({
        success: false,
        message: error instanceof Error ? error.message : 'Failed to send email'
      });
    } finally {
      setIsSending(false);
    }
  };

  const pollEmailStatus = async (messageId: string) => {
    let attempts = 0;
    const maxAttempts = 10;
    const interval = setInterval(async () => {
      try {
        const status = await emailService.getEmailStatus(messageId);
        if (status.status === 'delivered' || attempts >= maxAttempts) {
          clearInterval(interval);
          setStatus(prev => ({
            ...prev,
            message: status.status === 'delivered' 
              ? 'Email delivered successfully!' 
              : 'Email sent, but delivery status unknown'
          }));
        }
        attempts++;
      } catch (error) {
        clearInterval(interval);
        console.error('Status check error:', error);
      }
    }, 2000);
  };

  const templates = [
    { id: 'welcomeEmail', name: 'Welcome Email', description: 'Sent to new users' },
    { id: 'morningMotivation', name: 'Morning Motivation', description: 'Daily motivation and goals' },
    { id: 'afternoonProgress', name: 'Afternoon Progress', description: 'Activity updates' },
    { id: 'eveningRecap', name: 'Evening Recap', description: 'Daily summary' },
    { id: 'loginReminder', name: 'Login Reminder', description: 'Activity logging reminder' }
  ];

  return (
    <div className="space-y-6">
      <div className="card">
        <SectionHeader
          icon={FileText}
          title="Email Templates"
        />

        <div className="space-y-4">
          <div>
            <label className="form-label">Select Template</label>
            <select
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
              className="input-field"
            >
              {templates.map(template => (
                <option key={template.id} value={template.id}>
                  {template.name} - {template.description}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="form-label">Recipient Email</label>
            <input
              type="email"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              className="input-field"
              placeholder="Enter recipient email address"
            />
          </div>

          <Button
            variant="accent"
            onClick={handleSendEmail}
            disabled={isSending || !recipientEmail}
            fullWidth
          >
            {isSending ? (
              <>
                <Loader2 size={20} className="animate-spin" />
                Sending...
              </>
            ) : (
              <>
                <Send size={20} />
                Send Test Email
              </>
            )}
          </Button>

          {status.message && (
            <div className={`flex items-center gap-2 p-3 rounded-lg ${
              status.success 
                ? 'bg-green-50 text-green-700' 
                : 'bg-red-50 text-red-700'
            }`}>
              {status.success ? (
                <CheckCircle size={16} />
              ) : (
                <AlertCircle size={16} />
              )}
              <p className="text-sm">{status.message}</p>
            </div>
          )}
        </div>
      </div>

      <ImportantNotes
        notes={[
          'Make sure email service is properly configured',
          'Use a verified email address for testing',
          'Check spam folder if email not received',
          'Delivery status updates automatically'
        ]}
      />
    </div>
  );
};

export default EmailTemplates;