import React, { useState } from 'react';
import { Plus, Calendar, Play, Trash2, CheckCircle, AlertCircle } from 'lucide-react';
import { useApp } from '../../context/AppContext';
import Button from '../Button';
import VideoForm from './VideoForm';
import VideoScheduler from './VideoScheduler';
import VideoPlayer from './VideoPlayer';
import type { MotivationalVideo } from '../../types/base';

const VideoManager: React.FC = () => {
  const { state, dispatch } = useApp();
  const [showForm, setShowForm] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<MotivationalVideo | null>(null);
  const [showScheduler, setShowScheduler] = useState(false);
  const [previewVideo, setPreviewVideo] = useState<MotivationalVideo | null>(null);
  const [error, setError] = useState<string>('');

  const handleAddVideo = async (video: MotivationalVideo) => {
    try {
      dispatch({
        type: 'ADD_MOTIVATIONAL_VIDEO',
        payload: video
      });
      setShowForm(false);
      setError('');
    } catch (err) {
      setError('Failed to add video. Please try again.');
    }
  };

  const handleDeleteVideo = (videoId: string) => {
    if (window.confirm('Are you sure you want to delete this video?')) {
      try {
        dispatch({
          type: 'DELETE_MOTIVATIONAL_VIDEO',
          payload: videoId
        });
        setError('');
      } catch (err) {
        setError('Failed to delete video. Please try again.');
      }
    }
  };

  const handleSetTodayVideo = (video: MotivationalVideo) => {
    try {
      dispatch({
        type: 'SET_TODAY_VIDEO',
        payload: video
      });
      setError('');
    } catch (err) {
      setError('Failed to set today\'s video. Please try again.');
    }
  };

  const handleSchedule = (video: MotivationalVideo) => {
    setSelectedVideo(video);
    setShowScheduler(true);
  };

  const handlePreview = (video: MotivationalVideo) => {
    setPreviewVideo(video);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text">Motivational Videos</h2>
        <Button
          variant="accent"
          onClick={() => setShowForm(true)}
        >
          <Plus size={18} />
          Add Video
        </Button>
      </div>

      {error && (
        <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
          <AlertCircle size={16} />
          <p className="text-sm">{error}</p>
        </div>
      )}

      <div className="grid gap-4">
        {state.videos?.map(video => (
          <div key={video.id} className="card p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div 
                  className="w-32 aspect-video rounded-lg overflow-hidden bg-secondary cursor-pointer"
                  onClick={() => handlePreview(video)}
                >
                  {video.thumbnailUrl ? (
                    <img
                      src={video.thumbnailUrl}
                      alt={video.title}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <Play size={24} className="text-text-muted" />
                    </div>
                  )}
                </div>
                <div>
                  <h3 className="font-medium text-text">{video.title}</h3>
                  <p className="text-sm text-text-muted">{video.description}</p>
                  {video.schedule && (
                    <div className="flex items-center gap-1 mt-1 text-sm text-primary">
                      <Calendar size={14} />
                      <span>{video.schedule}</span>
                    </div>
                  )}
                  {state.todayVideo?.id === video.id && (
                    <div className="flex items-center gap-1 mt-1 text-sm text-green-500">
                      <CheckCircle size={14} />
                      <span>Today's Video</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="secondary"
                  onClick={() => handleSetTodayVideo(video)}
                  disabled={state.todayVideo?.id === video.id}
                >
                  {state.todayVideo?.id === video.id ? (
                    <>
                      <CheckCircle size={18} />
                      Current Video
                    </>
                  ) : (
                    'Set as Today\'s Video'
                  )}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleSchedule(video)}
                >
                  <Calendar size={18} />
                  Schedule
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleDeleteVideo(video.id)}
                >
                  <Trash2 size={18} className="text-red-500" />
                </Button>
              </div>
            </div>
          </div>
        ))}

        {state.videos?.length === 0 && (
          <div className="text-center py-8 text-text-muted">
            No videos added yet. Add your first motivational video to get started.
          </div>
        )}
      </div>

      {showForm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-secondary rounded-lg w-full max-w-2xl">
            <VideoForm
              onSubmit={handleAddVideo}
              onClose={() => setShowForm(false)}
            />
          </div>
        </div>
      )}

      {showScheduler && selectedVideo && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-secondary rounded-lg w-full max-w-2xl">
            <VideoScheduler
              video={selectedVideo}
              onClose={() => {
                setShowScheduler(false);
                setSelectedVideo(null);
              }}
            />
          </div>
        </div>
      )}

      {previewVideo && (
        <div className="fixed inset-0 bg-black/90 flex items-center justify-center p-4 z-50">
          <div className="w-full max-w-4xl">
            <VideoPlayer
              video={previewVideo}
              onClose={() => setPreviewVideo(null)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoManager;