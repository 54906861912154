import OpenAI from 'openai';
import { DEFAULT_CONFIG } from './config';
import type { ChatContext, AIResponse } from './types';

export class ChatService {
  constructor(private openai: OpenAI) {}

  async generateResponse(message: string, context: ChatContext): Promise<AIResponse> {
    try {
      const response = await this.openai.chat.completions.create({
        model: DEFAULT_CONFIG.model,
        messages: [
          {
            role: 'system',
            content: this.buildSystemPrompt(context)
          },
          { role: 'user', content: message }
        ],
        temperature: DEFAULT_CONFIG.temperature,
        max_tokens: DEFAULT_CONFIG.maxTokens
      });

      return {
        text: response.choices[0].message?.content || 'No response generated'
      };
    } catch (error) {
      console.error('Chat completion error:', error);
      if (error.response?.status === 401) {
        throw new Error('Invalid API key. Please check your OpenAI API key in settings.');
      }
      throw new Error('Failed to generate response. Please try again.');
    }
  }

  private buildSystemPrompt(context: ChatContext): string {
    const { userProfile, dailyStats, journalEntries, activityLogs, performanceMetrics } = context;
    
    let prompt = `You are an AI coach for ${userProfile?.name || 'a'} ${userProfile?.selectedRole || ''} professional.

Role: ${userProfile?.title || 'Real Estate Professional'}
Company: ${userProfile?.company?.name || 'Unknown'}

Current Performance Metrics:
- Daily Points: ${dailyStats?.dailyPoints || 0}
- Total Points: ${dailyStats?.totalPoints || 0}
- YTD Amount: $${dailyStats?.ytdAmount?.toLocaleString() || 0}

Goals:
- MLO GCI Goal: $${userProfile?.goals?.mloGciGoal?.toLocaleString() || 0}
- Realtor GCI Goal: $${userProfile?.goals?.realtorGciGoal?.toLocaleString() || 0}

Recent Activities:`;

    if (activityLogs?.length) {
      prompt += '\n' + activityLogs.map(log => 
        `- ${log.activity}: ${log.count} (${new Date(log.timestamp).toLocaleDateString()})`
      ).join('\n');
    }

    if (performanceMetrics?.conversionRates) {
      prompt += `\n\nConversion Rates:
- Leads to Appointments: ${performanceMetrics.conversionRates.leadsToAppointments.toFixed(1)}%
- Appointments to Closings: ${performanceMetrics.conversionRates.appointmentsToClosings.toFixed(1)}%
- Overall Lead to Closing: ${performanceMetrics.conversionRates.leadsToClosings.toFixed(1)}%`;
    }

    if (journalEntries?.length) {
      prompt += '\n\nRecent Journal Entries:\n' + journalEntries.map(entry => 
        `- ${new Date(entry.date).toLocaleDateString()}: Mood: ${entry.mood}`
      ).join('\n');
    }

    prompt += `\n\nProvide personalized, actionable advice based on this context. Focus on:
1. Performance improvement opportunities
2. Goal achievement strategies
3. Motivation and accountability
4. Best practices and industry insights

Keep responses concise, professional, and data-driven while maintaining an encouraging tone.`;

    return prompt;
  }
}