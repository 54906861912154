import React from 'react';
import { useApp } from '../context/AppContext';
import { Layout, Sidebar, MenuSquare, Grid } from 'lucide-react';
import Button from './Button';

interface LayoutOption {
  id: string;
  name: string;
  icon: typeof Layout;
  description: string;
}

const layouts: LayoutOption[] = [
  {
    id: 'default',
    name: 'Default',
    icon: Layout,
    description: 'Standard layout with bottom navigation'
  },
  {
    id: 'sidebar',
    name: 'Sidebar',
    icon: Sidebar,
    description: 'Left sidebar with expanded navigation'
  },
  {
    id: 'compact',
    name: 'Compact',
    icon: MenuSquare,
    description: 'Minimalist layout with collapsible menu'
  },
  {
    id: 'grid',
    name: 'Grid',
    icon: Grid,
    description: 'Dashboard-style grid layout'
  }
];

const LayoutSelector: React.FC = () => {
  const { state, dispatch } = useApp();
  const currentLayout = state.profile?.layout || 'default';

  const handleLayoutChange = (layoutId: string) => {
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: { layout: layoutId }
    });
  };

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold text-text">Layout Options</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {layouts.map(layout => {
          const Icon = layout.icon;
          const isActive = currentLayout === layout.id;
          
          return (
            <button
              key={layout.id}
              onClick={() => handleLayoutChange(layout.id)}
              className={`p-4 rounded-lg border ${
                isActive 
                  ? 'border-primary bg-primary/5' 
                  : 'border-border hover:border-primary/50'
              } transition-colors`}
            >
              <div className="flex items-center gap-3">
                <div className={`p-2 rounded-lg ${
                  isActive ? 'bg-primary text-white' : 'bg-secondary text-text'
                }`}>
                  <Icon size={24} />
                </div>
                <div className="text-left">
                  <h4 className="font-medium text-text">{layout.name}</h4>
                  <p className="text-sm text-text-muted">{layout.description}</p>
                </div>
              </div>
            </button>
          );
        })}
      </div>

      <div className="bg-secondary/50 rounded-lg p-4">
        <p className="text-sm text-text-muted">
          Note: Changing the layout will affect how navigation and content are displayed throughout the app.
          Some layouts may work better depending on your screen size and usage patterns.
        </p>
      </div>
    </div>
  );
};

export default LayoutSelector;