import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useApp } from '../context/AppContext';
import PrivateRoute from '../components/PrivateRoute';
import AdminRoute from '../components/AdminRoute';
import Home from '../pages/Home';
import TaskList from '../components/TaskList';
import DailyGoals from '../pages/DailyGoals';
import Journal from '../pages/Journal';
import JournalEntries from '../pages/JournalEntries';
import JournalVideo from '../pages/JournalVideo';
import MotivationalVideos from '../pages/MotivationalVideos';
import ProfileSetup from '../pages/ProfileSetup';
import TeamDashboard from '../pages/TeamDashboard';
import AdminDashboard from '../pages/AdminDashboard';
import Login from '../pages/Login';
import CreateAccount from '../pages/CreateAccount';
import Coach from '../pages/Coach';
import ActivityDashboard from '../pages/ActivityDashboard';
import RoleSelection from '../pages/RoleSelection';
import EmailDashboard from '../pages/EmailDashboard';

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const { state } = useApp();

  if (!isAuthenticated && window.location.pathname !== '/login' && window.location.pathname !== '/create-account') {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && !state.profile?.selectedRole && window.location.pathname !== '/role-selection') {
    return <Navigate to="/role-selection" replace />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/role-selection" element={<RoleSelection />} />
      
      <Route path="/" element={
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      } />
      <Route path="/tasks" element={
        <PrivateRoute>
          <TaskList />
        </PrivateRoute>
      } />
      <Route path="/goals" element={
        <PrivateRoute>
          <DailyGoals />
        </PrivateRoute>
      } />
      <Route path="/journal" element={
        <PrivateRoute>
          <Journal />
        </PrivateRoute>
      } />
      <Route path="/journal/entries" element={
        <PrivateRoute>
          <JournalEntries />
        </PrivateRoute>
      } />
      <Route path="/journal/video" element={
        <PrivateRoute>
          <JournalVideo />
        </PrivateRoute>
      } />
      <Route path="/videos" element={
        <PrivateRoute>
          <MotivationalVideos />
        </PrivateRoute>
      } />
      <Route path="/profile" element={
        <PrivateRoute>
          <ProfileSetup />
        </PrivateRoute>
      } />
      <Route path="/team" element={
        <PrivateRoute>
          <TeamDashboard />
        </PrivateRoute>
      } />
      <Route path="/admin/*" element={
        <AdminRoute>
          <AdminDashboard />
        </AdminRoute>
      } />
      <Route path="/coach" element={
        <PrivateRoute>
          <Coach />
        </PrivateRoute>
      } />
      <Route path="/activities" element={
        <PrivateRoute>
          <ActivityDashboard />
        </PrivateRoute>
      } />
      <Route path="/email" element={
        <PrivateRoute>
          <EmailDashboard />
        </PrivateRoute>
      } />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;