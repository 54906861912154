import React, { useState, useEffect } from 'react';
import { User as UserIcon, MoreVertical, Key, Edit, LogIn, Trash2 } from 'lucide-react';
import { useApp } from '../context/AppContext';
import { useAuth } from '../context/AuthContext';
import Button from './Button';
import UserCredentialsModal from './UserCredentialsModal';
import UserProfileModal from './UserProfileModal';
import type { User } from '../types/base';

interface UserManagerProps {
  branchId?: string;
  teamId?: string;
}

const UserManager: React.FC<UserManagerProps> = ({ branchId, teamId }) => {
  const { state } = useApp();
  const { impersonateUser } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  useEffect(() => {
    // Load users from localStorage
    const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
    const filteredUsers = savedUsers.filter((user: User) => {
      if (teamId) return user.teamId === teamId;
      if (branchId) return user.branchId === branchId;
      return true;
    });
    setUsers(filteredUsers);
  }, [branchId, teamId]);

  const handleDeleteUser = (userId: string) => {
    const updatedUsers = users.filter(u => u.id !== userId);
    setUsers(updatedUsers);
    localStorage.setItem('users', JSON.stringify(updatedUsers));
  };

  const handleImpersonateUser = async (user: User) => {
    try {
      await impersonateUser(user.id);
    } catch (error) {
      console.error('Failed to impersonate user:', error);
    }
  };

  const handleUpdateUser = (updatedUser: User) => {
    const updatedUsers = users.map(u => 
      u.id === updatedUser.id ? updatedUser : u
    );
    setUsers(updatedUsers);
    localStorage.setItem('users', JSON.stringify(updatedUsers));
    setEditingUser(null);
  };

  const UserDropdown = ({ user }: { user: User }) => {
    const isOpen = openDropdownId === user.id;
    const canImpersonate = state.user?.role === 'admin' && user.id !== state.user.id;

    return (
      <div className="relative">
        <Button
          variant="secondary"
          onClick={() => setOpenDropdownId(isOpen ? null : user.id)}
        >
          <MoreVertical size={18} />
        </Button>

        {isOpen && (
          <>
            <div 
              className="fixed inset-0 z-10"
              onClick={() => setOpenDropdownId(null)}
            />
            <div className="absolute right-0 top-full mt-1 w-48 bg-white dark:bg-secondary rounded-lg shadow-lg border border-border z-20">
              <div className="py-1">
                <button
                  onClick={() => {
                    setSelectedUser(user);
                    setOpenDropdownId(null);
                  }}
                  className="w-full px-4 py-2 text-left text-sm hover:bg-secondary dark:hover:bg-background flex items-center gap-2"
                >
                  <Key size={16} />
                  Change Password
                </button>
                <button
                  onClick={() => {
                    setEditingUser(user);
                    setOpenDropdownId(null);
                  }}
                  className="w-full px-4 py-2 text-left text-sm hover:bg-secondary dark:hover:bg-background flex items-center gap-2"
                >
                  <Edit size={16} />
                  Edit Profile
                </button>
                {canImpersonate && (
                  <button
                    onClick={() => {
                      handleImpersonateUser(user);
                      setOpenDropdownId(null);
                    }}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-secondary dark:hover:bg-background flex items-center gap-2 text-primary"
                  >
                    <LogIn size={16} />
                    Login as {user.name}
                  </button>
                )}
                <button
                  onClick={() => {
                    handleDeleteUser(user.id);
                    setOpenDropdownId(null);
                  }}
                  className="w-full px-4 py-2 text-left text-sm hover:bg-secondary dark:hover:bg-background flex items-center gap-2 text-red-500"
                >
                  <Trash2 size={16} />
                  Delete User
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        {users.map(user => (
          <div key={user.id} className="card">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-full bg-secondary dark:bg-background flex items-center justify-center">
                  {user.profilePicture ? (
                    <img
                      src={user.profilePicture}
                      alt={user.name}
                      className="w-full h-full object-cover rounded-full"
                    />
                  ) : (
                    <UserIcon size={20} className="text-text" />
                  )}
                </div>
                <div>
                  <h4 className="font-medium text-text">{user.name}</h4>
                  <p className="text-sm text-text-muted">{user.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <span className="px-2 py-1 text-xs font-medium rounded-full bg-secondary dark:bg-background text-text-muted">
                  {user.role.replace('_', ' ')}
                </span>
                <UserDropdown user={user} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedUser && (
        <UserCredentialsModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
          onUpdate={() => {
            const savedUsers = JSON.parse(localStorage.getItem('users') || '[]');
            setUsers(savedUsers);
          }}
        />
      )}

      {editingUser && (
        <UserProfileModal
          user={editingUser}
          onClose={() => setEditingUser(null)}
          onSave={handleUpdateUser}
        />
      )}
    </div>
  );
};

export default UserManager;