import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, Filter, Clock, BarChart2, Trash2 } from 'lucide-react';
import { format, startOfDay, endOfDay, isWithinInterval } from 'date-fns';
import { useApp } from '../context/AppContext';
import Button from '../components/Button';
import ActivityTable from '../components/ActivityTable';
import DailySummary from '../components/DailySummary';
import DateRangePicker from '../components/DateRangePicker';

const ActivityDashboard = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useApp();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfDay(new Date()),
    endOfDay(new Date())
  ]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<number[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  }>({ key: 'timestamp', direction: 'desc' });

  // Get unique activities from logs
  const uniqueActivities = useMemo(() => {
    const activities = new Set(state.activityLogs.map(log => log.taskId));
    return Array.from(activities);
  }, [state.activityLogs]);

  // Filter and sort logs
  const filteredLogs = useMemo(() => {
    return state.activityLogs.filter(log => {
      const logDate = new Date(log.timestamp);
      const withinDateRange = isWithinInterval(logDate, {
        start: dateRange[0],
        end: dateRange[1]
      });
      const matchesActivity = selectedActivities.length === 0 || 
        selectedActivities.includes(log.taskId);
      
      return withinDateRange && matchesActivity;
    }).sort((a, b) => {
      const aValue = a[sortConfig.key as keyof typeof a];
      const bValue = b[sortConfig.key as keyof typeof b];
      
      if (sortConfig.direction === 'asc') {
        return aValue < bValue ? -1 : 1;
      } else {
        return aValue > bValue ? -1 : 1;
      }
    });
  }, [state.activityLogs, dateRange, selectedActivities, sortConfig]);

  const handleSort = (key: string) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleClearLogs = () => {
    if (window.confirm('Are you sure you want to clear all activity logs?')) {
      dispatch({ type: 'CLEAR_ACTIVITY_LOGS' });
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <header className="page-header">
        <button onClick={() => navigate('/')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <h1 className="text-xl font-bold text-text">Activity Dashboard</h1>
      </header>

      <div className="container-content py-4 space-y-6">
        {/* Filters Section */}
        <div className="card">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Filter size={20} className="text-text" />
              <h2 className="font-semibold text-text">Filters</h2>
            </div>
            <Button
              variant="text"
              onClick={() => setShowFilters(!showFilters)}
            >
              {showFilters ? 'Hide' : 'Show'} Filters
            </Button>
          </div>

          {showFilters && (
            <div className="space-y-4">
              <div>
                <label className="form-label">Date Range</label>
                <DateRangePicker
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  onChange={setDateRange}
                />
              </div>

              <div>
                <label className="form-label">Activities</label>
                <div className="flex flex-wrap gap-2">
                  {uniqueActivities.map(taskId => {
                    const task = state.tasks.find(t => t.id === taskId);
                    return (
                      <button
                        key={taskId}
                        onClick={() => setSelectedActivities(prev => 
                          prev.includes(taskId)
                            ? prev.filter(id => id !== taskId)
                            : [...prev, taskId]
                        )}
                        className={`px-3 py-1.5 rounded-full text-sm ${
                          selectedActivities.includes(taskId)
                            ? 'bg-primary text-white'
                            : 'bg-secondary text-text'
                        }`}
                      >
                        {task?.name || `Task ${taskId}`}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Daily Summary */}
        <DailySummary logs={filteredLogs} />

        {/* Activity Table */}
        <div className="card">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Clock size={20} className="text-text" />
              <h2 className="font-semibold text-text">Activity Log</h2>
            </div>
            <Button
              variant="secondary"
              onClick={handleClearLogs}
            >
              <Trash2 size={18} className="text-red-500" />
              Clear Logs
            </Button>
          </div>

          <ActivityTable
            logs={filteredLogs}
            sortConfig={sortConfig}
            onSort={handleSort}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityDashboard;