import React, { useState, useEffect } from 'react';
import { Upload, Database, Search } from 'lucide-react';
import { getDocuments, saveDocument, deleteDocument } from '../../services/knowledgeBase';
import { KnowledgeBaseItem } from '../../types/knowledge';
import FileUploader from './FileUploader';
import DocumentList from './DocumentList';
import SearchBar from './SearchBar';
import Button from '../Button';

const KnowledgeBaseManager: React.FC = () => {
  const [documents, setDocuments] = useState<KnowledgeBaseItem[]>([]);
  const [showUploader, setShowUploader] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = () => {
    const docs = getDocuments();
    setDocuments(docs);
  };

  const handleUpload = async (file: File) => {
    try {
      const result = await saveDocument(file);
      if (result.success && result.item) {
        setDocuments(prev => [...prev, result.item]);
        setShowUploader(false);
        setSuccess('Document uploaded successfully');
        setTimeout(() => setSuccess(''), 3000);
      } else {
        setError(result.error || 'Failed to upload document');
        setTimeout(() => setError(''), 3000);
      }
    } catch (err) {
      setError('Failed to upload document');
      setTimeout(() => setError(''), 3000);
    }
  };

  const handleDelete = (id: string) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      if (deleteDocument(id)) {
        setDocuments(prev => prev.filter(doc => doc.id !== id));
        setSuccess('Document deleted successfully');
        setTimeout(() => setSuccess(''), 3000);
      } else {
        setError('Failed to delete document');
        setTimeout(() => setError(''), 3000);
      }
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const filteredDocuments = documents.filter(doc => 
    doc.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (doc.searchableContent || '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Database size={20} className="text-text" />
          <h2 className="text-lg font-semibold text-text">Knowledge Base</h2>
        </div>
        <Button
          variant="accent"
          onClick={() => setShowUploader(!showUploader)}
        >
          <Upload size={18} />
          {showUploader ? 'Cancel Upload' : 'Upload Document'}
        </Button>
      </div>

      <SearchBar onSearch={handleSearch} />

      {error && (
        <div className="bg-red-50 text-red-500 p-3 rounded-lg">
          {error}
        </div>
      )}

      {success && (
        <div className="bg-green-50 text-green-500 p-3 rounded-lg">
          {success}
        </div>
      )}

      {showUploader && (
        <FileUploader onUpload={handleUpload} />
      )}

      <DocumentList
        documents={filteredDocuments}
        onDelete={handleDelete}
      />

      {documents.length === 0 && !showUploader && (
        <div className="text-center py-8 text-text-muted">
          No documents in the knowledge base. Upload documents to get started.
        </div>
      )}
    </div>
  );
};

export default KnowledgeBaseManager;