import React, { useState } from 'react';
import { X } from 'lucide-react';
import Button from '../Button';
import type { MotivationalVideo } from '../../types/base';

interface VideoFormProps {
  video?: MotivationalVideo;
  onSubmit: (video: MotivationalVideo) => void;
  onClose: () => void;
}

const VideoForm: React.FC<VideoFormProps> = ({ video, onSubmit, onClose }) => {
  const [form, setForm] = useState({
    title: video?.title || '',
    url: video?.url || '',
    embedCode: video?.embedCode || '',
    description: video?.description || '',
    thumbnailUrl: video?.thumbnailUrl || '',
    tags: video?.tags?.join(', ') || ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const newVideo: MotivationalVideo = {
      id: video?.id || Date.now().toString(),
      ...form,
      tags: form.tags.split(',').map(tag => tag.trim()).filter(Boolean),
      addedBy: 'admin',
      addedAt: new Date().toISOString(),
      status: 'active'
    };

    onSubmit(newVideo);
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text">
          {video ? 'Edit Video' : 'Add New Video'}
        </h2>
        <button onClick={onClose} className="text-text-muted hover:text-text">
          <X size={24} />
        </button>
      </div>

      <div className="space-y-4">
        <div>
          <label className="form-label">Title</label>
          <input
            type="text"
            value={form.title}
            onChange={(e) => setForm(prev => ({ ...prev, title: e.target.value }))}
            className="input-field"
            placeholder="Enter video title"
            required
          />
        </div>

        <div>
          <label className="form-label">YouTube URL</label>
          <input
            type="url"
            value={form.url}
            onChange={(e) => setForm(prev => ({ ...prev, url: e.target.value }))}
            className="input-field"
            placeholder="Enter YouTube video URL"
            required
          />
        </div>

        <div>
          <label className="form-label">Embed Code</label>
          <textarea
            value={form.embedCode}
            onChange={(e) => setForm(prev => ({ ...prev, embedCode: e.target.value }))}
            className="input-field min-h-[120px]"
            placeholder="Paste YouTube embed code here"
            required
          />
          <p className="text-sm text-text-muted mt-1">
            Get this from YouTube's share button → Embed
          </p>
        </div>

        <div>
          <label className="form-label">Description</label>
          <textarea
            value={form.description}
            onChange={(e) => setForm(prev => ({ ...prev, description: e.target.value }))}
            className="input-field min-h-[100px]"
            placeholder="Enter video description"
          />
        </div>

        <div>
          <label className="form-label">Thumbnail URL</label>
          <input
            type="url"
            value={form.thumbnailUrl}
            onChange={(e) => setForm(prev => ({ ...prev, thumbnailUrl: e.target.value }))}
            className="input-field"
            placeholder="Enter thumbnail URL"
          />
        </div>

        <div>
          <label className="form-label">Tags</label>
          <input
            type="text"
            value={form.tags}
            onChange={(e) => setForm(prev => ({ ...prev, tags: e.target.value }))}
            className="input-field"
            placeholder="Enter tags separated by commas"
          />
        </div>
      </div>

      <div className="flex justify-end gap-2 pt-4">
        <Button
          variant="secondary"
          onClick={onClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          variant="accent"
          type="submit"
        >
          {video ? 'Update Video' : 'Add Video'}
        </Button>
      </div>
    </form>
  );
};

export default VideoForm;