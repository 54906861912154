// Function to convert hex to RGB
const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

// Function to calculate relative luminance according to WCAG standards
export const getLuminance = (hexColor: string): number => {
  const rgb = hexToRgb(hexColor);
  if (!rgb) return 0;

  const { r, g, b } = rgb;
  const [rs, gs, bs] = [r, g, b].map(c => {
    const s = c / 255;
    return s <= 0.03928 ? s / 12.92 : Math.pow((s + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
};

// Function to determine if text should be white or dark
export const shouldUseWhiteText = (hexColor: string): boolean => {
  const luminance = getLuminance(hexColor);
  return luminance < 0.5;
};

// Function to get appropriate text color class based on background color
export const getTextColorClass = (bgColor: string): string => {
  return shouldUseWhiteText(bgColor) ? 'text-white' : 'text-text dark:text-text';
};