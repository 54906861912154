import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Bot, Loader2 } from 'lucide-react';
import { useApp } from '../context/AppContext';
import { aiCoach } from '../services/aiCoach';
import ChatMessage from '../components/ChatMessage';
import ChatInput from '../components/ChatInput';
import Button from '../components/Button';
import { secureStorage } from '../utils/secureStorage';

interface Message {
  id: string;
  text: string;
  sender: 'user' | 'ai';
  timestamp: Date;
}

const MAX_MESSAGES = 50;
const MAX_MESSAGE_LENGTH = 2000;

const Coach: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useApp();
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [hasApiKey, setHasApiKey] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const apiKey = secureStorage.getItem('openai_api_key');
    setHasApiKey(!!apiKey);
    
    if (!apiKey) {
      setMessages([{
        id: 'welcome',
        text: 'Please add your OpenAI API key in the profile settings to use the AI coach feature.',
        sender: 'ai',
        timestamp: new Date()
      }]);
    } else {
      // Load welcome screen configuration
      const welcomeConfig = JSON.parse(secureStorage.getItem('ai_welcome_config') || '{}');
      const defaultMessage = welcomeConfig.greeting || `Hello ${state.profile?.name || 'there'}! I'm your AI coach. How can I help you today?`;
      const defaultDescription = welcomeConfig.description || 'I can assist you with:';
      const defaultLinks = welcomeConfig.links || [
        { text: 'Sphere of Influence scripts', action: 'sphere' },
        { text: 'Realtor Partner scripts', action: 'realtor' },
        { text: 'Social Media messaging', action: 'social' },
        { text: 'Lead generation strategies', action: 'leads' },
        { text: 'Follow-up templates', action: 'follow' }
      ];

      const welcomeMessage = `${defaultMessage}\n\n${defaultDescription}\n${defaultLinks.map(link => `• ${link.text}`).join('\n')}`;

      setMessages([{
        id: 'welcome',
        text: welcomeMessage,
        sender: 'ai',
        timestamp: new Date()
      }]);
    }
  }, [state.profile?.name]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleQuickAction = async (action: string) => {
    if (!hasApiKey || isLoading) return;

    const prompts: Record<string, string> = {
      sphere: "Please provide a script for reaching out to my sphere of influence",
      realtor: "I need a script for partnering with realtors",
      social: "Help me write a social media message for connecting with new friends",
      leads: "What are some effective lead generation strategies?",
      follow: "Can you provide some follow-up email templates?"
    };

    const prompt = prompts[action];
    if (!prompt) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      text: prompt,
      sender: 'user',
      timestamp: new Date()
    };

    const recentMessages = [...messages.slice(-MAX_MESSAGES), userMessage];
    setMessages(recentMessages);
    setInput('');
    setIsLoading(true);

    try {
      const response = await aiCoach.generateResponse(prompt, state);

      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        text: response,
        sender: 'ai',
        timestamp: new Date()
      };

      setMessages([...recentMessages, aiMessage]);
    } catch (error) {
      console.error('Error generating response:', error);
      const errorMessage: Message = {
        id: (Date.now() + 1).toString(),
        text: error instanceof Error 
          ? `Error: ${error.message}` 
          : 'Failed to generate response. Please try again.',
        sender: 'ai',
        timestamp: new Date()
      };
      setMessages([...recentMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!input.trim() || !hasApiKey || isLoading) return;

    const truncatedInput = input.slice(0, MAX_MESSAGE_LENGTH);

    const userMessage: Message = {
      id: Date.now().toString(),
      text: truncatedInput,
      sender: 'user',
      timestamp: new Date()
    };

    const recentMessages = [...messages.slice(-MAX_MESSAGES), userMessage];
    setMessages(recentMessages);
    setInput('');
    setIsLoading(true);

    try {
      const response = await aiCoach.generateResponse(truncatedInput, state);

      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        text: response,
        sender: 'ai',
        timestamp: new Date()
      };

      setMessages([...recentMessages, aiMessage]);
    } catch (error) {
      console.error('Error generating response:', error);
      const errorMessage: Message = {
        id: (Date.now() + 1).toString(),
        text: error instanceof Error 
          ? `Error: ${error.message}` 
          : 'Failed to generate response. Please try again.',
        sender: 'ai',
        timestamp: new Date()
      };
      setMessages([...recentMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <header className="page-header">
        <button onClick={() => navigate('/')} className="p-2">
          <ArrowLeft size={24} className="text-text" />
        </button>
        <div className="flex items-center gap-2">
          <Bot size={24} className="text-text" />
          <h1 className="text-xl font-bold text-text">AI Coach</h1>
        </div>
      </header>

      <div 
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto py-4 px-4 pb-32 space-y-4"
      >
        {messages.map((message) => (
          <ChatMessage key={message.id} message={message} />
        ))}

        {messages.length === 1 && messages[0].sender === 'ai' && hasApiKey && (
          <div className="flex flex-wrap gap-2 mt-4">
            <Button variant="secondary" onClick={() => handleQuickAction('sphere')}>
              Sphere of Influence Script
            </Button>
            <Button variant="secondary" onClick={() => handleQuickAction('realtor')}>
              Realtor Partner Script
            </Button>
            <Button variant="secondary" onClick={() => handleQuickAction('social')}>
              Social Media Message
            </Button>
            <Button variant="secondary" onClick={() => handleQuickAction('leads')}>
              Lead Generation Tips
            </Button>
            <Button variant="secondary" onClick={() => handleQuickAction('follow')}>
              Follow-up Templates
            </Button>
          </div>
        )}

        {isLoading && (
          <div className="flex justify-start">
            <div className="bg-secondary dark:bg-background text-text max-w-[80%] rounded-lg p-3">
              <div className="flex items-center gap-2">
                <Loader2 size={16} className="animate-spin" />
                <p>Thinking...</p>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <ChatInput
        input={input}
        setInput={setInput}
        handleSend={handleSubmit}
        isLoading={isLoading}
        disabled={!hasApiKey}
      />
    </div>
  );
};

export default Coach;