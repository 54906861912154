import React, { useState } from 'react';
import { Building2, Users, Plus, Trash2 } from 'lucide-react';
import { useApp } from '../context/AppContext';
import type { Branch, Team, User } from '../types/base';
import Button from './Button';

const BranchManager: React.FC = () => {
  const { state } = useApp();
  const [newBranch, setNewBranch] = useState({
    name: '',
    address: '',
    phone: ''
  });
  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
  const [showNewBranchForm, setShowNewBranchForm] = useState(false);

  const branches: Branch[] = JSON.parse(localStorage.getItem('branches') || '[]');
  const users: User[] = JSON.parse(localStorage.getItem('users') || '[]');

  const handleCreateBranch = () => {
    if (!newBranch.name) return;

    const branch: Branch = {
      id: `branch_${Date.now()}`,
      name: newBranch.name,
      managerId: '', // Will be assigned when a manager is selected
      address: newBranch.address,
      phone: newBranch.phone,
      createdAt: new Date().toISOString(),
      teams: []
    };

    const updatedBranches = [...branches, branch];
    localStorage.setItem('branches', JSON.stringify(updatedBranches));

    setNewBranch({ name: '', address: '', phone: '' });
    setShowNewBranchForm(false);
  };

  const handleDeleteBranch = (branchId: string) => {
    const updatedBranches = branches.filter(b => b.id !== branchId);
    localStorage.setItem('branches', JSON.stringify(updatedBranches));

    // Update users' branchId
    const updatedUsers = users.map(user => {
      if (user.branchId === branchId) {
        return { ...user, branchId: undefined };
      }
      return user;
    });
    localStorage.setItem('users', JSON.stringify(updatedUsers));
  };

  const getBranchManager = (managerId: string) => {
    return users.find(u => u.id === managerId);
  };

  const getTeamMembers = (teamId: string) => {
    return users.filter(u => u.teamId === teamId);
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text">Branches</h2>
        <Button
          variant="accent"
          onClick={() => setShowNewBranchForm(!showNewBranchForm)}
        >
          <Plus size={18} />
          Add Branch
        </Button>
      </div>

      {showNewBranchForm && (
        <div className="card space-y-4">
          <div>
            <label className="form-label">Branch Name</label>
            <input
              type="text"
              value={newBranch.name}
              onChange={(e) => setNewBranch(prev => ({ ...prev, name: e.target.value }))}
              className="input-field"
              placeholder="Enter branch name"
            />
          </div>

          <div>
            <label className="form-label">Address</label>
            <input
              type="text"
              value={newBranch.address}
              onChange={(e) => setNewBranch(prev => ({ ...prev, address: e.target.value }))}
              className="input-field"
              placeholder="Enter branch address"
            />
          </div>

          <div>
            <label className="form-label">Phone</label>
            <input
              type="tel"
              value={newBranch.phone}
              onChange={(e) => setNewBranch(prev => ({ ...prev, phone: e.target.value }))}
              className="input-field"
              placeholder="Enter branch phone"
            />
          </div>

          <div className="flex justify-end gap-2">
            <Button
              variant="secondary"
              onClick={() => setShowNewBranchForm(false)}
            >
              Cancel
            </Button>
            <Button
              variant="accent"
              onClick={handleCreateBranch}
              disabled={!newBranch.name}
            >
              Create Branch
            </Button>
          </div>
        </div>
      )}

      <div className="space-y-4">
        {branches.map(branch => (
          <div key={branch.id} className="card">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Building2 size={20} className="text-text" />
                <div>
                  <h3 className="font-medium text-text">{branch.name}</h3>
                  <p className="text-sm text-text-muted">
                    Manager: {getBranchManager(branch.managerId)?.name || 'Not assigned'}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="secondary"
                  onClick={() => setSelectedBranch(selectedBranch?.id === branch.id ? null : branch)}
                >
                  <Users size={18} />
                  Teams
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleDeleteBranch(branch.id)}
                >
                  <Trash2 size={18} className="text-red-500" />
                </Button>
              </div>
            </div>

            {selectedBranch?.id === branch.id && (
              <div className="mt-4 pt-4 border-t border-border">
                <div className="space-y-2">
                  {branch.teams.map(team => (
                    <div key={team.id} className="bg-secondary dark:bg-background p-3 rounded-lg">
                      <div className="flex justify-between items-center">
                        <div>
                          <h4 className="font-medium text-text">{team.name}</h4>
                          <p className="text-sm text-text-muted">
                            {getTeamMembers(team.id).length} members
                          </p>
                        </div>
                        <Button variant="text" size="sm">
                          Manage Team
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BranchManager;