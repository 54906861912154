import type { User } from '../../types/base';

interface EmailContent {
  subject: string;
  body: string;
}

type TemplateFunction = (user: User) => EmailContent;

export const templates: Record<string, TemplateFunction> = {
  welcomeEmail: (user) => ({
    subject: 'Welcome to Top Producer App',
    body: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #230d91;">Welcome to Top Producer App!</h1>
        <p>Hi ${user.name},</p>
        <p>We're excited to have you on board. Here are some quick links to get started:</p>
        <ul>
          <li><a href="${window.location.origin}/profile">Complete Your Profile</a></li>
          <li><a href="${window.location.origin}/goals">Set Your Goals</a></li>
          <li><a href="${window.location.origin}/coach">Meet Your AI Coach</a></li>
        </ul>
        <p>If you have any questions, don't hesitate to reach out to our support team.</p>
        <p>Best regards,<br>The Top Producer Team</p>
      </div>
    `
  }),

  morningMotivation: (user) => ({
    subject: 'Your Morning Motivation',
    body: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #230d91;">Good Morning ${user.name}!</h1>
        <p>Let's start the day strong. Here are your goals for today:</p>
        <ul>
          <li>Review your daily targets</li>
          <li>Plan your calls and appointments</li>
          <li>Check market updates</li>
        </ul>
        <p>Remember: Every call is an opportunity, every conversation counts!</p>
      </div>
    `
  }),

  afternoonProgress: (user) => ({
    subject: 'Afternoon Progress Check',
    body: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #230d91;">Afternoon Check-in</h1>
        <p>Hi ${user.name},</p>
        <p>Here's your progress so far today:</p>
        <ul>
          <li>Tasks completed: 5</li>
          <li>Points earned: 25</li>
          <li>Remaining goals: 3</li>
        </ul>
        <p>Keep up the great work!</p>
      </div>
    `
  }),

  eveningRecap: (user) => ({
    subject: 'Your Daily Recap',
    body: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #230d91;">Daily Achievement Summary</h1>
        <p>Great work today, ${user.name}!</p>
        <p>Here's what you accomplished:</p>
        <ul>
          <li>Total tasks completed: 12</li>
          <li>Total points earned: 45</li>
          <li>Goals achieved: 8/10</li>
        </ul>
        <p>Tomorrow is another opportunity to excel!</p>
      </div>
    `
  }),

  loginReminder: (user) => ({
    subject: 'Don\'t Forget to Log Your Activities',
    body: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h1 style="color: #230d91;">Activity Logging Reminder</h1>
        <p>Hi ${user.name},</p>
        <p>Don't forget to log your activities for today to keep track of your progress!</p>
        <p><a href="${window.location.origin}/tasks" style="color: #230d91;">Log Activities Now</a></p>
        <p>Staying on top of your activity tracking helps you reach your goals faster.</p>
      </div>
    `
  })
};